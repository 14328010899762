import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CmxDivider } from './utils.js';
import APICaller from '../../../../misc/APICaller.js';

const Management = (props) => {
    const [employees, setEmployees] = useState({});
    const apiCaller = new APICaller();
    const loadEmployees = async () => {
        const result = await apiCaller.callApi('employeesByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
        setEmployees(result.data);
    };

    useEffect(() => {
        async function fetchData() {
            await loadEmployees();
        }

        fetchData();
    }, []);
    return (
        <>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox color='success' />}
                    label='Mandant'
                    name={'isMandant'}
                    checked={props.formData?.isMandant === 1}
                    color='success'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 35 }, fontWeight: 'bold' }}
                    onChange={(event) => {
                        const value = event.target.checked ? 1 : 0;
                        props.handleInputChange({
                            target: {
                                name: 'isMandant',
                                value: value,
                            },
                        });
                    }}
                />
            </Grid>
            {props.formData.isMandant == 1 && (
                <>
                    <Grid item xs={12}>
                        <CmxDivider title='Mandantenmanagement' />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='filled' color='success' fullWidth
                                     style={{ marginTop: '15px' }}>
                            <InputLabel>Mandatsmanager</InputLabel>
                            <Select
                                defaultValue=''
                                value={props.formData?.clientData?.mandant_manager !== undefined ? (props.formData?.clientData?.mandant_manager !== null ? props.formData?.clientData?.mandant_manager : '') :
                                    (props.formData?.entity?.mandant_manager !== null ? props.formData?.entity?.mandant_manager : '')}
                                label={props.formData?.clientData?.mandant_manager !== undefined ? 'clientData.mandant_manager' : 'entity.mandant_manager'}
                                name={props.formData?.clientData?.mandant_manager !== undefined ? 'clientData.mandant_manager' : 'entity.mandant_manager'}
                                onChange={props.handleInputChange}
                            >
                                {employees.length > 0 ? (
                                    employees.map((employee) => (
                                        <MenuItem key={employee.id} value={employee.id}>
                                            {employee.title} {employee.first_name} {employee.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <>
                                        <MenuItem value={0}>Keine</MenuItem>
                                        <MenuItem value='undefined'></MenuItem>
                                    </>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='filled' color='success' fullWidth
                                     style={{ marginTop: '15px' }}>
                            <InputLabel
                            >Mandatsverantwortlicher</InputLabel>
                            <Select
                                defaultValue=''
                                value={props.formData?.clientData?.mandant_lead !== undefined ? (props.formData?.clientData?.mandant_lead !== null ? props.formData?.clientData?.mandant_lead : '') :
                                    (props.formData?.entity?.mandant_lead !== null ? props.formData?.entity?.mandant_lead : '')}
                                name={props.formData?.clientData?.mandant_lead !== undefined ? 'clientData.mandant_lead' : 'entity.mandant_lead'}
                                label={props.formData?.clientData?.mandant_lead !== undefined ? 'clientData.mandant_lead' : 'entity.mandant_lead'}
                                onChange={props.handleInputChange}
                            >
                                {employees.length > 0 ? (
                                    employees.map((employee) => (
                                        <MenuItem key={employee.id} value={employee.id}>
                                            {employee.title} {employee.first_name} {employee.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <>
                                        <MenuItem value={0}>Keine</MenuItem>
                                        <MenuItem value='null'></MenuItem>
                                    </>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )}
        </>
    );
};


export default Management;
