import { Button, Collapse, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SelectExistingContact from './components/SelectExistingContact.js';
import FreeAddress from './components/FreeAddress.js';
import ApiCaller from '../../../misc/APICaller.js';
import TaxAuthoritySearch from './components/TaxAuthoritySearch.js';
import dayjs from 'dayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';

const ButtonSelect = (props) => {
    const [textFieldValue, setTextFieldValue] = useState(props.draftData[props.question.label] || '');
    const [isRenderedArray, setIsRenderedArray] = useState([]);
    const [taxAuthority, setTaxAuthority] = useState('');
    const [helperTaxContact, setHelperTaxContact] = useState('');
    const [textTaxContact, setTextContact] = useState('');
    const apiCaller = new ApiCaller();
    const localTimeZone = 'Europe/Berlin';
    const handleFocus = (newFocus, index) => {
        const currentFocusArray = { ...props.selectedButtonFocus, [props.question.label]: newFocus }; // Create a copy of the existing array
        /*if (!currentFocusArray.includes(newFocus)) {
            currentFocusArray.push(newFocus); // Add the newFocus if it doesn't exist
        }*/

        props.setSelectedButtonFocus(currentFocusArray);
        props.setDraftData((prevData) => ({
            ...prevData,
            [props.attributeName]: newFocus,
        }));

        setTextFieldValue('');
    };


    const handleTextFieldChange = (newValue) => {
        setTextFieldValue(newValue);
        props.setDraftData((prevData) => ({
            ...prevData,
            [props.attributeName]: props.selectedButtonFocus[props.question.label] + ' ' + newValue,
        }));
    };

    const checkRendering = async (question, index) => {
        if (question.children[index]?.optionalRendering) {
            if (question.children[index]?.dependentVariable) {
                if (question.children[index]?.requestedInformation) {
                    if (props.draftData[question.children[index]?.dependentVariable]) {
                        const taxAuthorityContactPerson = await apiCaller.callApi('getTaxAuthorityContactPersonByEntity', 'GET', {}, { entity_id: props.draftData[question.children[index]?.dependentVariable] });
                        const taxAuth = await apiCaller.callApi('getTaxAuthorityByEntity', 'GET', {}, { entity_id: props.draftData[question.children[index]?.dependentVariable] });
                        if (taxAuthorityContactPerson.data.length > 0) {
                            if (taxAuth.data) {
                                setTaxAuthority(taxAuth.data);
                                setHelperTaxContact(taxAuthority.name_full ? `Kontakt bei ${taxAuthority.name_full}` : '');
                                setTextContact(taxAuthorityContactPerson.data[0].name ? `Sehr geehrter Herr ${taxAuthorityContactPerson.data[0].name}` : '');
                            }
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return false;
                }
            }
            return true;
        } else {
            return true;
        }

    };

    const handleTaxTextFieldChangeReplace = (newValue) => {
        setTextContact(newValue);
        props.setDraftData((prevData) => ({
            ...prevData,
            [props.attributeName]: newValue,
        }));
    };

    const handleTextFieldChangeReplace = (newValue) => {
        setTextFieldValue(newValue);
        props.setDraftData((prevData) => ({
            ...prevData,
            [props.attributeName]: newValue,
        }));
    };

    useEffect(() => {
        props.setDraftData({ ...props.draftData, ['selectedButtonFocus']: props.selectedButtonFocus });
        if (props.selectedButtonFocus?.salutation === 'Kontakt Finanzamt') {
            if (textTaxContact !== '') {
                props.setDraftData({ ...props.draftData, ['salutation']: textTaxContact });
            }
        }
    }, [props.selectedButtonFocus]);


    useEffect(() => {
        const renderButtons = async () => {
            const renderedElements = await Promise.all(
                props.question.autocomplete_strings.map(async (string, index) => {
                    const isRendered = await checkRendering(props.question, index);
                    const isPreSelected = props.question['preSelect'] === string;
                    isPreSelected && !props.selectedButtonFocus[props.question.label] && handleFocus(string, index);

                    return isRendered ? (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: '10px',
                                marginBottom: '10px',
                            }}
                        >
                            <Button
                                variant={props?.selectedButtonFocus?.[props.question.label] === string ? 'contained' : 'outlined'}
                                color='success'
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    maxWidth: props.question.buttonWidth ? props.question.buttonWidth : 'auto',
                                }}
                                onClick={() => handleFocus(string, index)}
                            >
                                <div
                                    style={{
                                        width: '80px',
                                        marginBottom: '0.5em',
                                    }}
                                >
                                    {props.question.icons[index].icon}
                                </div>
                                <div>
                                    {string.length > 20 ?
                                        <h3 style={{ margin: 0, fontSize: '10px' }}>{string}</h3> :
                                        <h3 style={{ margin: 0 }}>{string}</h3>
                                    }
                                </div>
                            </Button>

                            {/*{props?.selectedButtonFocus?.[props.question.label] === string && props.question.children[index] && (
                                <>
                                    {props.question.children[index].type === 'textfield' ? (
                                        <TextField
                                            label={props.question.children[index].label}
                                            value={textFieldValue}
                                            variant='filled'
                                            color='success'
                                            onChange={(e) => handleTextFieldChange(e.target.value)}
                                            style={{
                                                width: '300px',
                                                marginLeft: '10px',
                                            }}
                                        />
                                    ) : null}
                                </>
                            )}*/}
                        </div>) : null;
                }),
            );
            setIsRenderedArray(renderedElements);
        };
        renderButtons();
    }, [props.selectedButtonFocus]);

    return (
        <div>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                {isRenderedArray}
            </div>
            {/* Show 'taxAuthority' types in a new line */}
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                {props.question.autocomplete_strings.map((string, index) => (
                    <Grid container spacing={2} key={index}>
                        {props?.selectedButtonFocus?.[props.question.label] === string && props.question.children[index] && (
                            <>
                                {props.question.children[index].type === 'taxAuthority' ? (
                                    <Collapse in={true}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {/*<FinanceAuthorities
                                                    question={props.question}
                                                    draftData={props.draftData}
                                                    index={index}
                                                    setDraftData={props.setDraftData}
                                                />*/}
                                                <TaxAuthoritySearch
                                                    question={props.question}
                                                    draftData={props.draftData}
                                                    index={index}
                                                    setDraftData={props.setDraftData} />
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                ) : props.question.children[index].type === 'contact' ? (
                                    <SelectExistingContact
                                        question={props.question}
                                        draftData={props.draftData}
                                        setDraftData={props.setDraftData} />
                                ) : props.question.children[index].type === 'freeAddress' ? (
                                    <Collapse in={true}>
                                        <FreeAddress
                                            question={props.question}
                                            draftData={props.draftData}
                                            setDraftData={props.setDraftData} />
                                    </Collapse>
                                ) : props.question.children[index].type === 'textfield' ? (
                                    <Grid item xs={12} sm={11.5}>
                                        <TextField
                                            fullWidth
                                            label={props.question.children[index].label}
                                            value={textFieldValue}
                                            variant='filled'
                                            color='success'
                                            onChange={(e) => handleTextFieldChange(e.target.value)}

                                        />
                                    </Grid>
                                ) : props.question.children[index].type === 'valueSetter' ? (
                                    <Typography style={{ display: 'none' }}>
                                        {props.draftData[props.question.label] = props.question.children[index].value ?? ''}

                                    </Typography>

                                ) : props.question.children[index].type === 'textfieldNewLine' ? (
                                    <Collapse in={true}>
                                        <Grid container spacing={12} xs={12}>
                                            <Grid item xs={12} sm={12}
                                                  style={{ marginTop: '10px', marginBottom: '-65px' }}>
                                                <TextField
                                                    label={props.question.children[index].label}
                                                    value={!props.question.children[index].singleText ? (textFieldValue.replace(props.question.autocomplete_strings[index], '')).trim() : textFieldValue}
                                                    fullWidth
                                                    variant='filled'
                                                    color='success'
                                                    onChange={(e) => {
                                                        if (props.question.children[index].singleText) {
                                                            handleTextFieldChangeReplace(e.target.value);
                                                        } else {
                                                            handleTextFieldChange(e.target.value);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                ) : props.question.children[index].type === 'datePicker' ? (
                                    <Grid item xs={12} sm={12}>
                                        <DesktopDateTimePicker
                                            color='success'
                                            variant={'filled'}
                                            value={
                                                props.draftData[props.question.label] && dayjs(props.draftData[props.question.label], 'DD.MM.YYYY', localTimeZone).isValid()
                                                    ? dayjs.tz((props.question.children[index].prefix ? props.draftData[props.question.label].replace(props.question.children[index].prefix, '') : props.draftData[props.question.label]), 'DD.MM.YYYY', localTimeZone)
                                                    : null
                                            }
                                            onChange={(newValue) => {
                                                const formattedDate = newValue.format(props.question.children[index].stringFormating ?? 'YYYY-MM-DDTHH:mm:ss.SSSZ');
                                                props.draftData[props.question.label] = (props.question.children[index].prefix ?? '') + formattedDate;
                                                //setFieldValue(question, formattedDate);
                                            }}
                                            format={props.question.format || 'DD.MM.YYYY'}
                                            ampm={false}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    label: props.question.children[index].label,
                                                    variant: props.question.variant ?? 'filled',
                                                    helperText: props.question.helperText ?? '',
                                                    color: 'success',
                                                },
                                            }}
                                        />
                                    </Grid>
                                ) : (props.question.children[index].type === 'taxContactPerson' ? (
                                        <Collapse in={true}>
                                            <Grid container spacing={12} xs={12}>
                                                <Grid item xs={12} sm={12}
                                                      style={{ marginTop: '10px', marginBottom: '-65px' }}>
                                                    < TextField
                                                        label='Vorschau für Anrede'
                                                        value={textTaxContact} // Use the taxContactPersonValue here
                                                        helperText={helperTaxContact}
                                                        fullWidth
                                                        variant='filled'
                                                        color='success'
                                                        onChange={(e) => {
                                                            if (props.question.children[index].singleText) {
                                                                handleTaxTextFieldChangeReplace(e.target.value);
                                                                setHelperTaxContact('');
                                                            } else {
                                                                handleTextFieldChange(e.target.value);
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Collapse>) : null
                                )}
                            </>
                        )}
                    </Grid>
                ))
                }
            </div>
        </div>
    );
};
export default ButtonSelect;
