const roleTypes = [
    {
        name: 'Geschäftsführer (ohne Anteile)',
        label: 'managing_director',
        type: 'cooperation',
        user: ['Person', 'Einzelunternehmen', 'Gesellschaft', 'Stiftung'],
    },
    {
        name: 'Gesellschafter',
        label: 'shareholder',
        type: 'cooperation',
        user: ['Person', 'Einzelunternehmen', 'Gesellschaft', 'Stiftung'],
    },
    {
        name: 'Geschäftsführergesellschafter',
        label: 'executive_partner',
        type: 'cooperation',
        user: ['Person', 'Einzelunternehmen', 'Gesellschaft', 'Stiftung'],
    }];

export default roleTypes;