import React, { useEffect, useState } from 'react';
import Login from '../frontend/login.js';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import Footer from '../Footer.js';

const Dashboard = (e) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        let loggedIn = localStorage.getItem('auth');
        if (loggedIn === 'true') {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, [localStorage.getItem('auth')]);


    if (isLoggedIn === true) {
        return (
            <Grid item xs={12}>
                <Outlet />
            </Grid>
        );
    } else {
        return (
            <>
                <Grid item xs={12}>
                    <Login setIsLoggedIn={setIsLoggedIn} />
                    <p className='login_fail'>{isLoggedIn}</p>
                </Grid>
                {!isLoggedIn &&
                    <div>
                        <Footer />
                    </div>
                }
            </>
        );
    }
};

export default Dashboard;