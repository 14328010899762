const assetTypes = [
    {
        label: 'Immobilie',
        name: 'real_estate',
    },
    {
        label: 'Gesellschaft',
        name: 'corporation',
    },
    {
        label: 'Einzelunternehmen',
        name: 'one_man_business',
    }, {
        label: 'Wertpapiere',
        name: 'stocks',
    }, {
        label: 'Liquidität',
        name: 'liquidity',
    },
    {
        label: 'Kryptoassets',
        name: 'crypto',
    }, {
        label: 'Edelmetalle',
        name: 'noble_metals',
    }, {
        label: 'Kunstobjekte',
        name: 'art_objects',
    }];
export default assetTypes;