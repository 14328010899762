import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, Grid, IconButton, TextField } from '@mui/material';
import { AddOutlined, DeleteOutline } from '@mui/icons-material';

const ImmoStepper = (props) => {
    const [lines, setLines] = useState([{ id: 1, sender: null }]);

    useEffect(() => {
        console.log('Lines');
        console.log(lines);
    }, [lines]);
    const handleAddLine = () => {
        const newLineId = lines.length + 1;
        setLines([...lines, { id: newLineId, sender: null }]);
    };

    const handleDeleteLine = (lineId) => {
        if (lines.length > 1) {
            const updatedLines = lines.filter((line) => line.id !== lineId);
            setLines(updatedLines);
        }
    };

    const handleAttributeChange = (lineId, attributeName, value) => {
        setLines((prevLines) =>
            prevLines.map((line) =>
                line.id === lineId ? { ...line, [attributeName]: value } : line,
            ),
        );
    };

    return (
        <>
            {lines.map((line, index) => (
                <Grid key={line.id} container>
                    <Grid item xs={11} md={11}>
                        <Line
                            lineId={line.id}
                            sender={line.sender}
                            onAttributeChange={handleAttributeChange}
                            onDeleteLine={handleDeleteLine}
                            deletable={index > 0} // The first line is not deletable
                        />
                    </Grid>
                    {index === lines.length - 1 && (
                        <IconButton onClick={handleAddLine}>
                            <AddOutlined />
                        </IconButton>
                    )}
                </Grid>
            ))}
        </>
    );
};

export default ImmoStepper;

const Line = ({ lineId, sender, onAttributeChange, onDeleteLine, deletable }) => {
    const immobileOptions = [
        { id: 1, name: 'Property 1', senderId: 5 },
        { id: 2, name: 'Property 2', senderId: 5 },
        { id: 3, name: 'Property 3', senderId: 6 },
        // Add more options as needed
    ].filter((option) => option.senderId === sender?.id);

    const typeOptions = [
        { id: 1, name: 'endowment_beneficial_use', label: 'Schenkung mit Nießbrauch' },
        { id: 2, name: 'endowment_not_beneficial_use', label: 'Schenkung ohne Nießbrauch' },
        { id: 3, name: 'sale_to_GbR', label: 'Verkauf an GbR' },
        { id: 3, name: 'sale_to_GmbH', label: 'Verkauf an GmbH' },
        { id: 3, name: 'sale_to_KG', label: 'Verkauf an KG' },
        { id: 3, name: 'sale_to_GmbH_CO_KG', label: 'Verkauf an GmbH & Co. KG' },
        { id: 3, name: 'sale_to_foundation', label: 'Verkauf an Stiftung' },
    ];

    const handleDeleteReceiver = (receiverId) => {
        if (receivers.length > 1) {
            const updatedReceivers = receivers.filter((receiver) => receiver.id !== receiverId);
            setReceivers(updatedReceivers);
        }
    };

    const handleSenderChange = (newValue) => {
        onAttributeChange(lineId, 'selectedImmobile', null);
        onAttributeChange(lineId, 'sender', newValue);
    };

    const [receivers, setReceivers] = useState([{ id: 1, receiver: null, percentage: '' }]);

    const handleReceiverChange = (receiverIndex, newValue) => {
        setReceivers((prevReceivers) =>
            prevReceivers.map((receiver, index) =>
                index === receiverIndex ? { ...receiver, receiver: newValue } : receiver,
            ),
        );
    };


    const handlePercentageChange = (receiverIndex, newValue) => {
        setReceivers((prevReceivers) =>
            prevReceivers.map((receiver, index) =>
                index === receiverIndex ? { ...receiver, percentage: newValue } : receiver,
            ),
        );
    };

    const handleAddReceiver = () => {
        const newReceiverId = receivers.length + 1;
        setReceivers([...receivers, { id: newReceiverId, receiver: null, percentage: '' }]);
    };


    return (
        <Grid container spacing={2}>
            <Grid item xs={3} md={3}>
                <FormControl fullWidth>
                    <Autocomplete
                        options={[
                            { id: 5, name: 'Peter', title: 'Prof. Dr.', first_name: 'Detlev' },
                            { id: 6, name: 'Huber', title: 'Dr.', first_name: 'Mark' },
                        ]}
                        getOptionLabel={(option) => `${option.title} ${option.first_name} ${option.name}`}
                        value={sender || null}
                        onChange={(_, newValue) => handleSenderChange(newValue)}
                        renderInput={(params) => <TextField {...params} label='Verfüger' />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={2} md={2}>
                <FormControl fullWidth>
                    <Autocomplete
                        options={immobileOptions}
                        getOptionLabel={(option) => option.name}
                        onChange={(_, newValue) => onAttributeChange(lineId, 'selectedImmobile', newValue)}
                        renderInput={(params) => <TextField {...params} label='Immobilie' />}
                        disabled={!sender}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={2} md={2}>
                <FormControl fullWidth>
                    <Autocomplete
                        options={typeOptions}
                        getOptionLabel={(option) => option.label}
                        onChange={(_, newValue) => onAttributeChange(lineId, 'selectedType', newValue)}
                        renderInput={(params) => <TextField {...params} label='Art' />}
                        disabled={!sender}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
                {receivers.map((receiver, receiverIndex) => (
                    <Grid container key={receiver.id} spacing={1}>
                        <Grid item xs={8} md={8} style={{ marginTop: '5px' }}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={[
                                        { id: 7, name: 'Receiver 1' },
                                        { id: 8, name: 'Receiver 2' },
                                        // Add more receiver options as needed
                                    ]}
                                    getOptionLabel={(option) => option.name}
                                    value={receiver.receiver || null}
                                    onChange={(_, newValue) => handleReceiverChange(receiverIndex, newValue)}
                                    renderInput={(params) => <TextField {...params} label='Empfänger' />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} md={3} style={{ marginTop: '5px' }}>
                            <FormControl fullWidth>
                                <TextField
                                    label='%'
                                    value={receiver.percentage}
                                    onChange={(e) => handlePercentageChange(receiverIndex, e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        {receiverIndex === receivers.length - 1 && (
                            <Grid item xs={1} md={1}>
                                <IconButton onClick={handleAddReceiver}>
                                    <AddOutlined />
                                </IconButton>
                            </Grid>
                        )}
                        {deletable && (
                            <Grid item xs={1} md={1}>
                                <IconButton onClick={() => handleDeleteReceiver(receiverIndex)}>
                                    <DeleteOutline />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                ))}
            </Grid>
            {deletable && (
                <Grid item xs={1} md={1}>
                    <IconButton onClick={() => onDeleteLine(lineId)}>
                        <DeleteOutline />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};
