import React, { useEffect, useState } from 'react';
import relationTypes from '../../../../misc/auto_data/relationTypes.js';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Button,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { AddCircleRounded, Close, ExpandCircleDownSharp } from '@mui/icons-material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import ApiCaller from '../../../../misc/APICaller.js';


const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

function Relation(props) {
    const [relations, setRelations] = useState(props.formData.relations ? props.formData.relations : []);
    const [types, setTypes] = useState(relationTypes);
    const [selectedType, setSelectedType] = useState({});
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [endowmentDate, setEndowmentDate] = useState();
    const [endowmentValue, setEndowmentValue] = useState();
    const [gender, setGender] = useState('');
    const [endowments, setEndowments] = useState([]);
    const [tempID, setTempID] = useState(1);
    const [selectValue, setSelectValue] = useState('');
    const [clientList, setClientList] = useState([]);
    const [knownClient, setKnownClient] = useState(true);
    const apiCaller = new ApiCaller();
    const [selectedOption, setSelectedOption] = useState(null);

    const handleRemoveEndowment = (index) => {
        console.log('called handle  remove');
        setEndowments(prevEndowments => [
            ...prevEndowments.slice(0, index),
            ...prevEndowments.slice(index + 1),
        ]);
    };

    const handleRemoveLoggedEndowment = (relationIndex, endowmentIndex) => {
        setRelations((prevRelations) => {
            const updatedRelations = [...prevRelations];
            const relation = updatedRelations[relationIndex];
            relation.endowments.splice(endowmentIndex, 1); // Remove the endowment at the specified index
            return updatedRelations;
        });
    };

    const handleEndowmentsChange = (index, field, value) => {
        setEndowments(prevEndowments => {
            const updatedEndowments = [...prevEndowments];
            updatedEndowments[index][field] = value;
            return updatedEndowments;
        });
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const clients = await apiCaller.callApi('getClientsByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
                const sortedClients = [...clients.data.persons].sort((a, b) => {
                    const nameComparison = a.name.localeCompare(b.name);
                    if (nameComparison !== 0) {
                        return nameComparison;
                    }
                    return a.first_name.localeCompare(b.first_name);
                });
                setClientList(sortedClients);
                console.log(sortedClients);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        console.log('selected Option');
        console.log(selectedOption);
    }, [selectedOption]);


    useEffect(() => {
        console.log('Relations');
        console.log(relations);
        console.log(props.formData.relations);
        props.handleInputChange({
            target: {
                name: 'relations',
                value: relations,
            },
        });
    }, [relations]);

    useEffect(() => {
        if (selectedType.name) {
            setSelectValue(selectedType.name);
        } else {
            setSelectValue('');
        }
    }, [selectedType]);

    const handleTypeChange = (type) => {
        setSelectedType({ name: type.name, type: type.type, label: type.label });
    };

    const handleAddRelation = () => {
        let updatedEndowments = endowments;
        if (endowmentDate || endowmentValue) {
            updatedEndowments = [
                ...endowments,
                {
                    date: endowmentDate || '',
                    value: endowmentValue || '',
                },
            ];
            setEndowmentDate('');
            setEndowmentValue('');
        }
        setRelations((prevRelations) => [
            ...prevRelations,
            {
                person: knownClient ? selectedOption : {
                    first_name: firstName,
                    name: lastName,
                    gender: gender,
                    title: title,
                    birth_date: birthdate,
                },
                endowments: updatedEndowments,
                relation_label: selectedType.label,
                relation: selectedType.name,
                tempID: tempID,
            },
        ]);
        clearNewContactForm();
        setEndowments([]);
        setSelectedType('');
        setTempID((prevKey) => prevKey + 1);
        setSelectedOption(null);
    };

    const handleRemoveRelation = (index) => {
        setRelations((prevRelations) => [...prevRelations.slice(0, index), ...prevRelations.slice(index + 1)]);
    };

    const clearNewContactForm = () => {
        setFirstName('');
        setLastName('');
        setGender('');
        setTitle('');
        setBirthdate('');
    };

    function handleAddEndowmentExistingRelation(index) {
        setRelations((prevRelations) => {
            const updatedRelations = [...prevRelations];
            const relation = updatedRelations[index];
            const allEndowments = [...relation.endowments, { date: '', value: '' }];
            relation.endowments = allEndowments;
            return updatedRelations;
        });
    }


    const handleFieldChange = (index, fieldName, value) => {
        setRelations((prevRelations) => {
            const updatedRelations = [...prevRelations];
            const person = { ...updatedRelations[index].person }; // Get the current person object
            person[fieldName] = value; // Update the field value in the person object
            updatedRelations[index].person = person; // Update the person object in the relation
            return updatedRelations;
        });
    };

    const handleEndowmentsChangeRelation = (relationIndex, endowmentIndex, field, value) => {
        const updatedRelations = [...relations];
        const relation = updatedRelations[relationIndex];
        const endowment = relation.endowments[endowmentIndex];
        endowment[field] = value;
        setRelations(updatedRelations);
    };

    function handleAddEndowment() {
        const newEndowment = {
            date: endowmentDate || '',
            value: endowmentValue || '',
        };
        setEndowments([...endowments, newEndowment]);
        setEndowmentDate('');
        setEndowmentValue('');
    }

    return (
        <>
            <Grid item xs={12} sm={12}>
                {relations && relations.map((relation, index) => (
                    <Accordion
                        style={{ backgroundColor: 'whitesmoke' }}
                        key={index}
                        color='success'
                        variant='filled'
                    >
                        <StyledAccordionSummary
                            expandIcon={<ExpandCircleDownSharp />}
                            aria-label='Add'
                        >
                            <Typography
                                variant='body1'><b>{relation.relation}:</b> {' '}

                                {relation.person?.first_name} {' '}
                                {relation.person?.name}{' '}
                                {relation.endowments && relation.endowments.length > 0 && `, Schenkungen: ${relation.endowments.length}`}

                            < /Typography>
                            <IconButton
                                color='success'
                                aria-label='Remove'
                                onClick={() => handleRemoveRelation(index)}
                            >
                                <Close />
                            </IconButton>
                        </StyledAccordionSummary>
                        <AccordionDetails>
                            <Grid item xs={12} sm={12}>
                                <FormControl variant='filled' color='success' fullWidth
                                             style={{ marginTop: '15px' }}>
                                    <InputLabel id='asset-id'>Bezug auswählen</InputLabel>
                                    <Select
                                        defaultValue=''
                                        value={relation.relation}
                                        label='bezug'
                                        name='bezug'
                                    >
                                        {types.map(type => (
                                            <MenuItem key={type.name} value={type.name} onClick={() => {
                                                handleFieldChange(index, 'relation', type.name);
                                                handleFieldChange(index, 'relation_label', type.label);
                                            }}>{type.name} </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant='filled' color='success' fullWidth
                                                 style={{ marginTop: '15px' }}>
                                        <InputLabel>Anrede</InputLabel>
                                        <Select
                                            defaultValue=''
                                            value={relation.person?.gender}
                                            label='gender'
                                            name='gender'
                                            onChange={(event) => handleFieldChange(index, 'gender', event.target.value)}
                                        >
                                            <MenuItem value={'male'}>Herr</MenuItem>
                                            <MenuItem value={'female'}>Frau</MenuItem>
                                            <MenuItem value={'diverse'}>-</MenuItem>
                                            <MenuItem value=''>Keine</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl variant='filled' color='success' fullWidth
                                                 style={{ marginTop: '15px' }}>
                                        <InputLabel>Titel</InputLabel>
                                        <Select
                                            defaultValue=''
                                            value={relation.person?.title || ''}
                                            label='title'
                                            name='title'
                                            onChange={(event) => handleFieldChange(index, 'title', event.target.value)}
                                        >
                                            <MenuItem value={'dr'}>Dr.</MenuItem>
                                            <MenuItem value={'prof'}>Prof. Dr.</MenuItem>
                                            <MenuItem value=''>Keinen</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label='Vorname'
                                        name='first_name'
                                        value={relation.person?.first_name}
                                        onChange={(event) => handleFieldChange(index, 'first_name', event.target.value)}
                                        fullWidth
                                        margin='normal'
                                        color='success'
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label='Nachname'
                                        name='name'
                                        value={relation.person?.name}
                                        onChange={(event) => handleFieldChange(index, 'name', event.target.value)}
                                        fullWidth
                                        margin='normal'
                                        color='success'
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label='Geburtsdatum'
                                        name='birth_date'
                                        value={relation.person?.birth_date}
                                        onChange={(event) => handleFieldChange(index, 'birth_date', event.target.value)}
                                        type='date'
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        margin='normal'
                                        color='success'
                                        variant='filled'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
                                    {relation.endowments && relation.endowments.map((endowment, endowmentIndex) => (
                                        <Accordion
                                            style={{ backgroundColor: 'whitesmoke', border: '1px solid lightgray' }}
                                            key={endowmentIndex}
                                            color='success'
                                            variant='filled'
                                        >
                                            <StyledAccordionSummary
                                                expandIcon={<ExpandCircleDownSharp />}
                                                aria-label='Expand'
                                            >
                                                <Typography
                                                    variant='body1'> <b>Schenkung {endowmentIndex + 1}: </b> {' '}
                                                    {' EUR '}
                                                    {parseFloat(endowment.value).toLocaleString('de-DE', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                    {endowment.date && ' am '}
                                                    {endowment.date && new Date(endowment.date).toLocaleDateString('de-DE')}
                                                </Typography>
                                                <IconButton
                                                    color='success'
                                                    aria-label='Remove'
                                                    onClick={() => handleRemoveLoggedEndowment(index, endowmentIndex)}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </StyledAccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label='Schenkungsdatum'
                                                            name='endowmentDate'
                                                            value={endowment.date}
                                                            onChange={(event) => handleEndowmentsChangeRelation(index, endowmentIndex, 'date', event.target.value)}
                                                            fullWidth
                                                            type='date'
                                                            margin='normal'
                                                            color='success'
                                                            variant='filled'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            label='Schenkungswert in Euro'
                                                            name='endowmentValue'
                                                            value={endowment.value}
                                                            onChange={(event) => handleEndowmentsChangeRelation(index, endowmentIndex, 'value', event.target.value)}
                                                            fullWidth
                                                            type='number'
                                                            margin='normal'
                                                            color='success'
                                                            variant='filled'
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12} sm={12} justifyContent='center' alignItems='center'
                                          style={{ marginTop: '15px' }}>
                                        <IconButton color='success'
                                                    onClick={() => handleAddEndowmentExistingRelation(index)}>
                                            <AddCircleRounded fontSize='large' />
                                            <Typography variant='body'>Schenkung hinzufügen</Typography>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                ))}
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '20px' }}>
                {relations.length > 0 &&
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                    </Grid>}
                <Grid container className='box' spacing={2} xs={12}>
                    <Grid item xs={9} sm={9}>
                        <FormControl variant='filled' color='success' fullWidth>
                            <InputLabel id='asset-id'>Bezugsperson auswählen</InputLabel>
                            <Select
                                defaultValue=''
                                value={selectValue}
                                //value={selectedType.name}
                                label='bezug'
                                name='bezug'
                            >
                                {types.map(type => (
                                    <MenuItem key={type.name} value={type.name}
                                              onClick={() => handleTypeChange(type)}>{type.name}</MenuItem>
                                ))}
                                <MenuItem key='no' value='' onClick={() => handleTypeChange('')}> Keine</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {selectedType.name && (
                        <>
                            <Container maxWidth style={{ marginTop: '30px' }}>
                                {knownClient && clientList.length > 0 ? (

                                    <div style={{ width: '100%', marginTop: '20px' }}>
                                        <Grid item xs={12} style={{ marginLeft: '10px', marginBottom: '-20px' }}>
                                            <Typography variant='h6'>Bestandssuche</Typography>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Autocomplete
                                                    options={clientList}
                                                    getOptionLabel={(option) => ` ${option.title ? option.title : ''} ${option.first_name} ${option.name} ${option.birth_date ? ', ' + format(new Date(option.birth_date), 'dd.MM.yyyy') : ''}`}
                                                    value={selectedOption}
                                                    onChange={(event, newValue) => setSelectedOption(newValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label='Bestandssuche'
                                                            name='mandantSearch'
                                                            fullWidth
                                                            margin='normal'
                                                            color='success'
                                                            variant='filled'
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Typography>
                                            <Button variant='text' color='success'
                                                    onClick={() => {
                                                        setKnownClient(false);
                                                        setSelectedOption(null);
                                                    }}>Neue Person anlegen</Button>
                                        </Typography>
                                    </div>
                                ) : (
                                    <div style={{ width: '100%', marginTop: '20px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} style={{ marginLeft: '10px', marginBottom: '-20px' }}>
                                                <Typography variant='h6'>Neuer Kontakt</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl variant='filled' color='success' fullWidth
                                                             style={{ marginTop: '15px' }}>
                                                    <InputLabel>Anrede</InputLabel>
                                                    <Select
                                                        defaultValue=''
                                                        value={gender}
                                                        label='gender'
                                                        name='gender'
                                                        onChange={(event) => setGender(event.target.value)}
                                                    >
                                                        <MenuItem value={'male'}>Herr</MenuItem>
                                                        <MenuItem value={'female'}>Frau</MenuItem>
                                                        <MenuItem value={'diverse'}>-</MenuItem>
                                                        <MenuItem value=''>Keine</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl variant='filled' color='success' fullWidth
                                                             style={{ marginTop: '15px' }}>
                                                    <InputLabel>Titel</InputLabel>
                                                    <Select
                                                        defaultValue=''
                                                        value={title || ''}
                                                        label='title'
                                                        name='title'
                                                        onChange={(event) => setTitle(event.target.value)}
                                                    >
                                                        <MenuItem value={'dr'}>Dr.</MenuItem>
                                                        <MenuItem value={'prof'}>Prof. Dr.</MenuItem>
                                                        <MenuItem value=''>Keinen</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label='Vorname'
                                                    name='first_name'
                                                    value={firstName}
                                                    onChange={(event) => setFirstName(event.target.value)}
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label='Nachname'
                                                    name='last_name'
                                                    value={lastName}
                                                    onChange={(event) => setLastName(event.target.value)}
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label='Geburtsdatum'
                                                    name='birth_date'
                                                    value={birthdate}
                                                    onChange={(event) => setBirthdate(event.target.value)}
                                                    type='date'
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    <Button variant='text' color='success'
                                                            onClick={() => {
                                                                setKnownClient(true);
                                                                clearNewContactForm();
                                                            }}>Aus Bestand wählen
                                                    </Button>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                            </Container>

                            <Grid item xs={12} sm={12} style={{ marginTop: '15px' }}>
                                {endowments.map((endowment, index) => (
                                    <Accordion
                                        style={{ backgroundColor: 'whitesmoke' }}
                                        key={index}
                                        color='success'
                                        variant='filled'
                                    >
                                        <StyledAccordionSummary
                                            expandIcon={<ExpandCircleDownSharp />}
                                            aria-label='Add'
                                        >
                                            <Typography
                                                variant='body1'>Schenkung {index + 1}: {' '}
                                                {endowment.value}
                                                {endowment.value && ' Euro '}
                                                {endowment.date && ' am '}
                                                {endowment.date && new Date(endowment.date).toLocaleDateString('de-DE')}
                                            </Typography>
                                            <IconButton
                                                color='success'
                                                aria-label='Remove'
                                                onClick={() => handleRemoveEndowment(index)}
                                            >
                                                <Close />
                                            </IconButton>
                                        </StyledAccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Schenkungsdatum'
                                                        name='endowmentDate'
                                                        value={endowment.date}
                                                        onChange={(event) => handleEndowmentsChange(index, 'date', event.target.value)}
                                                        fullWidth
                                                        type='date'
                                                        margin='normal'
                                                        color='success'
                                                        variant='filled'
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label='Schenkungswert in Euro'
                                                        name='endowmentValue'
                                                        value={endowment.value}
                                                        onChange={(event) => handleEndowmentsChange(index, 'value', event.target.value)}
                                                        fullWidth
                                                        type='number'
                                                        margin='normal'
                                                        color='success'
                                                        variant='filled'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                                }
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ marginTop: '15px' }}>
                                <TextField
                                    label='Schenkungsdatum'
                                    name='endowmentDate'
                                    value={endowmentDate}
                                    onChange={(event) => setEndowmentDate(event.target.value)}
                                    fullWidth
                                    type='date'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={5.5} style={{ marginTop: '15px' }}>
                                <TextField
                                    label='Schenkungswert'
                                    name='endowmentValue'
                                    value={endowmentValue}
                                    onChange={(event) => setEndowmentValue(event.target.value)}
                                    fullWidth
                                    type='number'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>
                            <Grid item xs={12} sm={0.5} container justifyContent='center' alignItems='center'
                                  style={{ marginTop: '15px' }}>
                                <IconButton color='success'>
                                    <AddCircleRounded fontSize='large' onClick={handleAddEndowment} />
                                </IconButton>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} sm={3}>
                        <Button variant='contained' color='success' onClick={handleAddRelation} size='large'>
                            Hinzufügen
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Relation;