import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Bank = (props) => {
    const [bankAccountDetails, setBankAccountDetails] = useState(props.formData.bankAccountDetails);

    useEffect(() => {
        let updatedBankAccountDetails = {};
        updatedBankAccountDetails.agreementDirectDebit = true;
        updatedBankAccountDetails.accountNumber = props?.formData?.bankAccountDetails?.accountNumber ? props?.formData?.bankAccountDetails?.accountNumber : '';
        props.handleInputChange({
            target: { name: 'bankAccountDetails', value: updatedBankAccountDetails },
        });

    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        const updatedBankAccountDetails = {
            ...bankAccountDetails,
            [props.prefix ? `${props.prefix}${name}` : name]: value,
        };
        props.handleInputChange({
            target: { name: 'bankAccountDetails', value: updatedBankAccountDetails },
        });
    };

    const handleAgreementChange = (event) => {
        const { name, checked } = event.target;
        const updatedBankAccountDetails = {
            ...props.formData.bankAccountDetails,
            [props.prefix ? `${props.prefix}${name}` : name]: checked,
        };
        console.log(updatedBankAccountDetails);
        props.handleInputChange({
            target: { name: 'bankAccountDetails', value: updatedBankAccountDetails },
        });
    };


    return (
        <>
            <Grid item xs={12}>
                <TextField
                    label='Kontodaten (IBAN)'
                    name='bankAccountDetails.accountNumber'
                    value={props.formData?.bankAccountDetails?.accountNumber}
                    onChange={props.handleInputChange}
                    fullWidth
                    type='text'
                    margin='normal'
                    color='success'
                    variant='filled'
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color='success'
                            name='agreementDirectDebit'
                            checked={props.formData?.bankAccountDetails?.agreementDirectDebit ?? true}
                            onChange={handleAgreementChange}
                        />
                    }
                    label='Einwilligung Lastschriftverfahren gem. Vergütungsvereinbarung'
                    name='bankAccountDetails.agreementDirectDebit'
                    color='success'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 35 }, fontWeight: 'bold' }}
                />
            </Grid>
        </>
    );
};

export default Bank;
