import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FormGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { leadTypes } from '../../../../misc/auto_data/leadTypes.js';

const Lead = (props) => {

    const [selectedLeads, setSelectedLeads] = useState(props.formData?.leads ? props.formData?.leads : []);

    useEffect(() => {
        setSelectedLeads(props.formData?.leads ? props.formData.leads : []);
    }, [props.formData.leads]);
    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        const selectedIndex = selectedLeads.indexOf(value);
        let newSelectedLeads = [];

        if (selectedIndex === -1) {
            newSelectedLeads = [...selectedLeads, value];
        } else {
            selectedLeads.splice(selectedIndex, 1);
            newSelectedLeads = selectedLeads;
        }
        setSelectedLeads(newSelectedLeads);

        props.handleInputChange({
            target: {
                name: 'leads',
                value: newSelectedLeads,
            },
        });
    };

    return (
        <Grid item xs={12}>
            <Box textAlign='center'>
                <FormGroup
                    sx={{ width: '100%' }}
                    name='leads'
                    value={setSelectedLeads}
                    onChange={handleCheckboxChange}
                >
                    {leadTypes.map((item, index) => {
                        const isChecked = selectedLeads.includes(item.name);
                        return (
                            <FormControlLabel
                                control={<Checkbox color='success' checked={isChecked} />}
                                key={index}
                                label={item.name}
                                value={item.name}
                                color='success'
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 35 }, fontWeight: 'bold' }}
                            />
                        );
                    })}
                </FormGroup>
            </Box>
        </Grid>
    );
};
export default Lead;