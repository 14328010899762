import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function AGBs() {
    return (
        <>
            <Row>
                <Col style={{ textAlign: 'center' }}>
                    <Container fluid className='p-5'>
                        <h2 className='h2'> We do not give garranty to anything</h2>
                        <p>Details of AGBs</p>
                    </Container>
                </Col>
            </Row>
        </>
    );
}

export default AGBs;