import React, { useState } from 'react';
import { Button } from '@mui/material';
import CustomerTable from './customerTable.js';
import NewCustomer from './newCustomer/new_customer.js';
import CustomerOverview from './displayCustomerDetails/customerOverview.js';


const Customer = () => {
    const [open, setOpen] = useState(false);
    const [isCustomerSelected, setIsCustomerSelected] = useState(false);
    const [customerUpdate, setCustomerUpdate] = useState(false);

    if (!isCustomerSelected) {
        return (
            <>
                <Button variant='contained' color='success' onClick={() => setOpen(true)}>Kontakt
                    Hinzufügen</Button>
                <CustomerTable isCustomerSelected={isCustomerSelected} setIsCustomerSelected={setIsCustomerSelected}
                               customerUpdate={customerUpdate} />
                <NewCustomer setOpen={setOpen} open={open} setCustomerUpdate={setCustomerUpdate} />
            </>
        );
    } else {
        return (<CustomerOverview />);
    }
};
export default Customer;