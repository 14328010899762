import React from 'react';

export const leadTypes = [

    {
        name: 'Empfehlung',
    },
    {
        name: 'YouTube/Podcast',

    },
    {
        name: 'Website',
    },
    {
        name: 'Suchmaschine',

    },
];