const companyRoleTypes = [
    {
        name: 'Geschäftsführer (ohne Anteile)',
        label: 'managing_director',
        user: ['UG', 'GmbH', 'PartG', 'Stiftung'],
    },
    {
        name: 'Gesellschafter',
        label: 'shareholder',
        user: ['UG', 'GmbH', 'GbR', 'OHG'],
    },
    {
        name: 'Geschäftsführender Gesellschafter',
        label: 'executive_partner',
        user: ['OHG', 'GbR', 'UG', 'GmbH'],
    },
    {
        name: 'Komplentär',
        label: 'general_partner',
        user: ['KG', 'GmbH & Co. KG'],
    },
    {
        name: 'Kommanditist',
        label: 'limited_partner',
        user: ['KG', 'GmbH & Co. KG'],
    },
    {
        name: 'Aktionär',
        label: 'stockholder',
        user: ['AG'],
    },
    {
        name: 'Vorstand',
        label: 'management_board',
        user: ['AG'],
    },
    {
        name: 'Destinatär',
        label: 'beneficiary',
        user: ['german_private_foundation', 'german_charity_foundation', 'liechtenstein_foundation'],
    },
    {
        name: 'Stifter',
        label: 'stifter',
        user: ['german_private_foundation', 'german_charity_foundation', 'liechtenstein_foundation'],
    },
    {
        name: 'Vorstand',
        label: 'chairman',
        user: ['german_private_foundation', 'german_charity_foundation', 'liechtenstein_foundation'],
    },
    {
        name: 'Einzelunternehmer',
        label: 'sole_proprietor',
        user: ['Einzelunternehmen'],
    },
];

export default companyRoleTypes;