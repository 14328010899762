import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { companyType } from '../../../../misc/auto_data/companyTypes.js';

const Rechtsform = (props) => {

    return (
        <>
            <Grid item xs={12} sm={12}>
                <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                    <InputLabel>Rechtsform</InputLabel>
                    <Select
                        value={props.formData?.entity?.type || ''}
                        name='entity.type'
                        onChange={props.handleInputChange}
                    >
                        {
                            companyType.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.label}>{item.name}</MenuItem>
                                );

                            })}
                        <MenuItem key={-1} value={null}></MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label='Registergericht'
                    name='identification_number.Registergericht'
                    value={props?.formData?.identification_number?.Registergericht || ''}
                    onChange={props.handleInputChange}
                    fullWidth
                    type='text'
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label='Registernummer (HRA oder HRB Nummer)'
                    name='identification_number.Registernummer'
                    value={props?.formData?.identification_number?.Registernummer || ''}
                    onChange={props.handleInputChange}
                    fullWidth
                    type='text'
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label='Stammkapital'
                    name='entity.share_capital'
                    value={props?.formData?.entity?.share_capital || ''}
                    onChange={props.handleInputChange}
                    fullWidth
                    type='number'
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>

        </>
    );
};

export default Rechtsform;
