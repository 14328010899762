import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField } from '@mui/material';
import { styled } from '@mui/system';
import APICaller from '../../misc/APICaller.js';
import { AiFillRobot } from 'react-icons/ai';
import { FaUser } from 'react-icons/fa';

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '80vh',
}));

const ChatBox = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '93%',
}));

const MessageList = styled(List)(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
}));

const MessageItem = styled(ListItem)(({ theme }) => ({
    display: 'flex',
}));

const MessageText = styled(ListItemText)(({ theme }) => ({
    whiteSpace: 'pre-wrap',
}));

const InputBox = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
}));

const InputField = styled(TextField)(({ theme }) => ({
    flexGrow: 1,
    marginRight: theme.spacing(2),
}));

const SendButton = styled(Button)(({ theme }) => ({
    minWidth: '100px',
}));

const UserText = styled('div')(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const AiText = styled('div')(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const UserArea = styled(ListItem)(({ theme }) => ({
    backgroundColor: 'white',
    padding: '15px',
    marginBottom: '15px',
}));

const AiAvatar = styled(Avatar)(({ theme }) => ({
    color: 'white',
}));

const Assistant = () => {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [aiAnswer, setAiAnswer] = useState('');
    const ApiCaller = new APICaller();


    useEffect(() => {
        const interval = setInterval(() => {
            setAiAnswer((aiAnswer) => aiAnswer + '.');
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const sendMessage = async () => {
        if (input) {
            const userMessage = { text: input, from: 'user' };
            const aiResponse = (await ApiCaller.callApi('chat', 'POST', { prompt: input })).msg;
            console.log(aiResponse);
            setInput('');
            setAiAnswer(aiResponse);
            setMessages([...messages, userMessage, { text: aiResponse, from: 'ai' }]);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };

    return (
        <Root>
            <ChatBox>
                <MessageList>
                    {messages.map((message, index) => (
                        <MessageItem
                            key={index}
                            className={message.from === 'user' ? 'userArea' : ''}
                            alignItems='flex-start'
                        >
                            <ListItemAvatar>
                                {message.from === 'ai' ? (
                                    <AiAvatar sx={{ bgcolor: 'black' }}><AiFillRobot /></AiAvatar>
                                ) : (
                                    <Avatar sx={{ bgcolor: 'green' }}><FaUser /></Avatar>
                                )}
                            </ListItemAvatar>
                            <MessageText
                                primary={message.text}
                            />
                        </MessageItem>
                    ))}
                </MessageList>

                <InputBox>
                    <InputField
                        variant='outlined'
                        value={input}
                        onChange={(event) => setInput(event.target.value)}
                        onKeyPress={handleKeyPress}
                        color='success'
                        fullWidth
                    />
                    <SendButton
                        variant='contained'
                        color='success'
                        onClick={sendMessage}
                    >
                        Send
                    </SendButton>
                </InputBox>
            </ChatBox>
        </Root>
    );
};

export default Assistant;