import React, { useEffect, useState } from 'react';
import APICaller from '../../misc/APICaller.js';
import {
    Avatar,
    Box,
    Checkbox,
    Dialog,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material';
import DraftStepper from './drafts/draftStepper.js';
import { useNotification } from '../../misc/notification.js';
import CLApiCaller from '../../misc/CL_APICaller.js';
import {
    ArrowDropDown,
    ArrowDropUp,
    BadgeRounded,
    Close,
    CottageRounded,
    DeleteSweepRounded,
    Downloading,
    DownloadingOutlined,
    Edit,
    HistoryEduRounded,
    NoteAddRounded,
    Search,
} from '@mui/icons-material';
import { sortArrayOfObjects } from '../../misc/helper.js';
import { styled } from '@mui/system';
import ConfirmationDialog from './drafts/components/ConfirmDialog.js';
import { formatEntityString } from './helper.js';
import DraftButtonMenue from './drafts/components/DraftButtonMenue.js';

const ColoredBorderIconButton = styled(IconButton)(({ theme }) => ({
    border: '1px solid lightgray',
    boxShadow: '2px 1px 1px rgba(0.2, 0, 0, 0.2)',
    margin: '5px',
}));


const Drafts = () => {
    const apiCaller = new APICaller();
    const clApiCaller = new CLApiCaller();
    const [searchTerm, setSearchTerm] = useState('');
    const [drafts, setDrafts] = useState([]);
    const [open, setOpen] = useState(false);
    const [draftData, setDraftData] = useState({});
    const [draftType, setDraftType] = useState('empty');
    const [sortBy, setSortBy] = useState(localStorage.getItem('draft_sortBy') || 'creation_date');
    const [sortOrder, setSortOrder] = useState(localStorage.getItem('draft_sortOrder') || 'desc');
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedDraftId, setSelectedDraftId] = useState(null);
    const [newDraft, seNewDraft] = useState(false);
    const [showPersonalOnly, setShowPersonalOnly] = useState(false);
    const [consultantEntityID, setConsultantEntityID] = useState(null);
    const [draftsMessage, setDraftsMessage] = useState('Loading');
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const { showNotification } = useNotification();

    const handleOpenDeleteDialog = (id) => {
        setIsDeleteDialogOpen(true);
        setSelectedDraftId(id);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    async function handleDelete() {
        if (selectedDraftId !== null) {
            const resultDelete = await apiCaller.callApi('deleteDraft', 'GET', {}, { draft_id: selectedDraftId });
            handleCloseDeleteDialog();
            await loadDrafts(false, sortBy, sortOrder);
        }
    }

    const extractSurname = (name) => {
        const parts = name?.split(' ');
        return parts.length > 1 ? parts[parts.length - 1] : '';
    };

    const filteredDrafts = drafts && drafts.filter((row) => {
        const name = `${row.template} ${row.name}`;
        const isPersonalDraft = row.person_id === consultantEntityID;
        return name.toLowerCase().includes(searchTerm.toLowerCase()) && (!showPersonalOnly || isPersonalDraft);
    });

    const sortBySurname = (a, b) => {
        const surnameA = extractSurname(a.name);
        const surnameB = extractSurname(b.name);

        if (sortOrder === 'asc') {
            return surnameA.localeCompare(surnameB);
        } else {
            return surnameB.localeCompare(surnameA);
        }
    };

    const sortByConsultant = (a, b) => {
        const surnameA = a.person_name.name;
        const surnameB = b.person_name.name;
        if (sortOrder === 'asc') {
            return surnameA.localeCompare(surnameB);
        } else {
            return surnameB.localeCompare(surnameA);
        }
    };

    const getSortingFunction = (column) => {
        if (column === 'name') {
            return sortBySurname;
        } else if (column === 'person_name') {
            return sortByConsultant;
        } else {
            return (a, b) => {
                const order = sortOrder || 'desc';
                if (order === 'asc') {
                    return a[column] > b[column] ? 1 : -1;
                } else {
                    return b[column] > a[column] ? 1 : -1;
                }
            };
        }
    };


    const handleSortClick = (column) => {
        setSortBy(column);
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        localStorage.setItem('draft_sortOrder', newSortOrder);
        localStorage.setItem('draft_sortBy', column);
    };

    useEffect(() => {
        const fetchData = async () => {
            await loadDrafts(false, sortBy, sortOrder);
            const entityID = await apiCaller.callApi('getUserDataByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
            setConsultantEntityID(entityID.data[0].id);
        };
        fetchData();
        const personView = localStorage.getItem('setting_onlyPersonalDrafts');
        setShowPersonalOnly(personView === 'true');

    }, []);

    const sortedData = filteredDrafts && [...filteredDrafts].sort(getSortingFunction(sortBy));

    async function loadDrafts(initial = false, sortBy = 'creation_date', sortOrder = 'desc') {
        const id = localStorage.getItem('id');
        setDraftsMessage('Loading...');
        const result = await apiCaller.callApi('getDrafts', 'GET', {}, { user_id: id });
        let sortedResults = result.data;
        if (sortedResults)
            sortedResults = sortArrayOfObjects(sortedResults, sortBy, sortOrder);
        if (sortedResults.length <= 0)
            setDraftsMessage('Keine Entwürfe gefunden.');
        setDrafts(sortedResults);
        setSortBy(sortBy);
        setSortOrder(sortOrder);
    }

    async function downloadDraft(id) {
        try {
            const response = await clApiCaller.callApi('download_document', 'GET', {}, {
                draft_id: id,
            }, false, true);
            const name = await clApiCaller.callApi('download_document', 'GET', {}, {
                draft_id: id,
                name_only: 1,
            }, false, false);
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            let fileName = 'downloaded_file.docx';  // Default file name
            const test = name.data.name;
            if (name?.data?.name) {
                fileName = name?.data?.name;
            }
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);  // You can set any file name you want
            document.body.appendChild(link);
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            showNotification('success', 'Download wurde gestartet.');
        } catch (error) {
            console.log(error);
            showNotification('error', 'Download konnte nicht gestartet werden.');
        }
    }


    useEffect(() => {
        console.log('sortedData');
        console.log(sortedData);
    }, [sortedData]);

    function handleDialogClose() {
        setOpen(false);
        console.log('draftDataReset');
        setDraftData({});
    }

    function handleNewDraft() {
        seNewDraft(!newDraft);
    }

    function handleEditingForm(row) {
        try {
            const data = JSON.parse(row.attr);
            data.id = row.id;
            data.type = row.type;
            data.name = row.name;
            setDraftType(row.type);
            setDraftData(data);
            setOpen(true);
        } catch (err) {
            console.log(err);
        }
    }

    const renderArrowIcon = (column) => {
        if (sortBy === column) {
            return sortOrder === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />;
        }
        return null; // No arrow icon if column is not currently sorted
    };

    function selectDraftType(type) {
        setDraftType(type);
        setOpen(true);
    }

    const handleCheckboxChange = (event) => {
        setShowPersonalOnly(event.target.checked);
        localStorage.setItem('setting_onlyPersonalDrafts', event.target.checked);
    };

    return (
        <>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                {/*<Grid item xs={12}>
                    <Button variant='contained' onClick={handleOpenDialog} color='success'>
                        Neuer Entwurf
                    </Button>
                </Grid>*/}
                <Grid item xs={12}>
                    <DraftButtonMenue selectDraftType={selectDraftType} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant='outlined'
                        size='medium'
                        color='success'
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: <Search fontSize='medium' style={{ marginRight: '10px' }} />,
                        }}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TableContainer className='customerTable'>
                        <Table>
                            <TableHead>
                                <TableRow>

                                    <TableCell />
                                    <TableCell onClick={() => handleSortClick('name')}
                                               style={{ cursor: 'pointer' }}> Name
                                        {renderArrowIcon('name')}</TableCell>
                                    <TableCell onClick={() => handleSortClick('template')}
                                               style={{ cursor: 'pointer' }}> Art
                                        {renderArrowIcon('template')}</TableCell>
                                    <TableCell onClick={() => handleSortClick('creation_date')}
                                               style={{ cursor: 'pointer' }}> Erstellt
                                        {renderArrowIcon('creation_date')}</TableCell>
                                    <TableCell onClick={() => handleSortClick('person_name')}
                                               style={{ cursor: 'pointer' }}> Ersteller
                                        {renderArrowIcon('person_name')}
                                    </TableCell>

                                    <TableCell>
                                        <Checkbox
                                            checked={showPersonalOnly}
                                            onChange={handleCheckboxChange}
                                            color='success'
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
                                        /> Meine
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {drafts && drafts.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={5}>{draftsMessage ?? ''}</TableCell>
                                    </TableRow>
                                ) : sortedData && sortedData.length > 0 ? (
                                    sortedData.map((row) => (
                                        <React.Fragment key={row.id}>
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <Avatar color='success' sx={{ bgcolor: '#3e697c' }}>
                                                        {row.template === 'real_estate_transfer_pptx'
                                                            ? <CottageRounded />
                                                            : row.template === 'memo_doc'
                                                                ? <NoteAddRounded />
                                                                : row.template === 'lawyer_letter_doc' || row.type === 'lawyer_letter_2_0' ?
                                                                    <HistoryEduRounded />
                                                                    : row.template === 'contract_managing_director_doc' ?
                                                                        <BadgeRounded /> : 'O'}
                                                    </Avatar>
                                                </TableCell>
                                                <TableCell>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>
                                                    {row.template === 'real_estate_transfer_pptx'
                                                        ? 'Immobilienübertragung'
                                                        : row.template === 'memo_doc'
                                                            ? 'Gesprächsnotiz'
                                                            : row.template === 'lawyer_letter_doc' ?
                                                                'Anschreiben' : row.type === 'lawyer_letter_2_0' ?
                                                                    row?.template ?? 'unbekanntest Anschreiben' :
                                                                    row.template === 'contract_managing_director_doc' ? 'Geschäftsführeranstellungsvertrag' : 'Unbekannt'}
                                                </TableCell>
                                                <TableCell>
                                                    {new Date(row.creation_date).toLocaleDateString('de-DE', {
                                                        year: '2-digit',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: false,
                                                    }) + 'Uhr'}
                                                </TableCell>
                                                <TableCell>
                                                    {formatEntityString(row.person_name)}
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title='Entwurf editieren'>
                                                        <ColoredBorderIconButton
                                                            color='success'
                                                            aria-label='Editieren'
                                                            component='a'
                                                            onClick={() => handleEditingForm(row)}
                                                        >
                                                            <Edit fontSize='medium' />
                                                        </ColoredBorderIconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={row.doc_name != null && row.doc_name != '' ? 'Download Entwurf' : ''}>
                                                        <ColoredBorderIconButton
                                                            disabled={row.doc_name != null && row.doc_name != '' ? false : true}
                                                            color='success'
                                                            aria-label='Download'
                                                            component='b'
                                                            onClick={() => downloadDraft(row.id)}>
                                                            {row.downloading ? (
                                                                < Downloading className='downloading'
                                                                              fontSize='medium' />
                                                            ) : (
                                                                <DownloadingOutlined fontSize='medium' />
                                                            )}
                                                        </ColoredBorderIconButton>

                                                    </Tooltip>
                                                    <Tooltip title={'Löschen'}>
                                                        <ColoredBorderIconButton
                                                            color='success'
                                                            aria-label='Löschen'
                                                            component='b'
                                                            onClick={() => handleOpenDeleteDialog(row.id)}
                                                        >
                                                            <DeleteSweepRounded />
                                                        </ColoredBorderIconButton>

                                                    </Tooltip>
                                                    <ConfirmationDialog
                                                        isOpen={isDeleteDialogOpen}
                                                        onClose={handleCloseDeleteDialog}
                                                        onConfirm={() => handleDelete(row.id)}
                                                        message='Soll der Entwurf wirklich gelöscht werden?'
                                                        confirmText='OK'
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}
                                                           colSpan={9}></TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5}>Kein Draft gefunden.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {open && (
                        <Dialog
                            open={open}
                            onClose={() => handleDialogClose()}
                            fullScreen  // Set the Dialog to fullscreen

                            PaperProps={{
                                style: {
                                    backgroundColor: 'gray',
                                    alignItems: 'center',
                                    id: 'dialog-content',
                                    justifyContent: draftType === 'memo' || draftType === 'contract_managing_director' || draftType === 'lawyer_letter_2_0' ? 'flex-start' : 'center',
                                },
                            }}
                        >
                            <Box className='box' width='900px'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <IconButton
                                            aria-label='Close'
                                            style={{ position: 'absolute', top: 10, right: 0, color: 'gray' }}
                                            onClick={() => handleDialogClose()}
                                        >
                                            <Close />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                                        <DraftStepper draftData={draftData} setOpen={setOpen}
                                                      loadDrafts={loadDrafts}
                                                      type={draftType} setDraftData={setDraftData} />
                                    < /Grid>
                                </Grid>
                            </Box>
                        </Dialog>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default Drafts;
