import React from 'react';
import {
    CalendarMonthOutlined,
    HomeWork,
    NotesOutlined,
    PeopleOutlined,
    PieChart,
    SettingsOutlined,
} from '@mui/icons-material';

const root = '/dashboard';
const className = 'sec-color';


export const SidebarData = [
    {
        title: 'Dashboard',
        path: root + '/home',
        icon: <HomeWork size={35} className={className} />,
        cName: 'sidebar-text',
        auth: ['client', 'user', 'admin', 'manager', 'super-admin'],
        badge: false,
    },
    {
        title: 'Aufgaben',
        path: root + '/tasks',
        icon: <PieChart size={35} className={className} />,
        cName: 'sidebar-text',
        auth: ['super-admin'],
        badge: true,
    },
    {
        title: 'Kontakte',
        path: root + '/customer',
        icon: <PeopleOutlined size={35} className={className} />,
        cName: 'sidebar-text',
        auth: ['admin', 'manager', 'super-admin'],
        badge: false,
    },
    {
        title: 'Entwürfe',
        path: root + '/drafts',
        icon: <PieChart size={35} className={className} />,
        cName: 'sidebar-text',
        auth: ['admin', 'super-admin', 'manager'],
        badge: false,
    },
    {
        title: 'Projekte',
        path: root + '/projects',
        icon: <PieChart size={35} className={className} />,
        cName: 'sidebar-text',
        auth: ['super-admin'],
        badge: false,
    },

    {
        title: 'Termine',
        path: root + '/appointments',
        icon: <CalendarMonthOutlined size={35} className={className} />,
        cName: 'sidebar-text',
        auth: ['super-admin'],
        badge: false,
    },
    {
        title: 'Dokumente',
        path: root + '/documents',
        icon: <PieChart size={35} className={className} />,
        cName: 'sidebar-text',
        auth: ['super-admin'],
        badge: false,
    },
    {
        title: 'Kommunikation',
        path: root + '/mailbox',
        icon: <NotesOutlined size={35} className={className} />,
        cName: 'sidebar-text',
        auth: ['super-admin'],
        badge: true,
    },

    {
        title: 'Einstellungen',
        path: root + '/settings',
        icon: <SettingsOutlined size={35} className={className} />,
        cName: 'sidebar-text',
        auth: ['admin', 'super-admin'],
        badge: false,
    },
];