import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import Anschrift from './formComponents/anschrift.js';
import Telefonnummer from './formComponents/telefonnummer.js';
import Finanzamt from './formComponents/finanzamt.js';
import Assets from './formComponents/assets.js';
import { CmxDivider, CMXTextInput } from './formComponents/utils.js';
import Lead from './formComponents/lead.js';
import Fokus from './formComponents/fokus.js';
import { ExpandCircleDown } from '@mui/icons-material';
import DateField from './formComponents/dateField.js';
import Associates from './formComponents/associates.js';
import Bank from './formComponents/bank.js';
import TaxCompliance from './formComponents/taxCompliance.js';
import { styled } from '@mui/system';


const StyledAccordion = styled(Accordion)({
    boxShadow: 'none', // Remove the drop shadow
});

const StyledAccordionSummary = styled(AccordionSummary)({
    display: 'flex',
    alignItems: 'flex-end', // Align items to the bottom
});

const StyledCmxDivider = styled(CmxDivider)({
    flex: 1, // Make the divider expand to fill available space
});

const StyledExpandIcon = styled(ExpandCircleDown)({
    marginLeft: 'auto',
    marginBottom: '10px', // Move the expand icon to the right
});


const Stiftung = (props) => {

    return (
        <>
            <div style={{ padding: '20px' }}>
                <Grid container spacing={2} justify='center' style={{ marginBottom: '25px' }}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Divider className='custom-divider'>
                                <Typography variant='h6'>Rechtsform</Typography>
                            </Divider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }} required>
                            <InputLabel>Rechtsform</InputLabel>
                            <Select
                                value={props.formData?.entity?.type || ''}
                                name='entity.type'
                                onChange={props.handleInputChange}
                            >
                                <MenuItem value={'german_private_foundation'}>Deutsche private Stiftung</MenuItem>
                                <MenuItem value={'german_charity_foundation'}>Deutsche gemeinnützige Stiftung</MenuItem>
                                <MenuItem value={'liechtenstein_foundation'}>Liechtensteinische Stiftung</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Stiftungsregister'
                            name='identification_number.Stiftungsregister'
                            value={props?.formData?.identification_number?.Stiftungsregister || ''}
                            onChange={props.handleInputChange}
                            fullWidth
                            type='text'
                            margin='normal'
                            color='success'
                            variant='filled'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Registernummer'
                            name='identification_number.Registernummer'
                            value={props?.formData?.identification_number?.Registernummer || ''}
                            onChange={props.handleInputChange}
                            fullWidth
                            type='text'
                            margin='normal'
                            color='success'
                            variant='filled'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label='Stammkapital'
                            name='entity.share_capital'
                            value={props?.formData?.entity?.share_capital || ''}
                            onChange={props.handleInputChange}
                            fullWidth
                            type='number'
                            margin='normal'
                            color='success'
                            variant='filled'
                        />
                    </Grid>
                    <DateField
                        formData={props.formData}
                        handleInputChange={props.handleInputChange}
                        label='Gründungsdatum'
                        name='entity.birth_date'
                    />

                    <Grid item xs={12} sm={12}>
                        <TextField
                            label='Stiftungszweck'
                            name='entity.purpose'
                            value={props?.formData?.entity?.purpose || ''}
                            onChange={props.handleInputChange}
                            fullWidth
                            multiline={true}
                            minRows='2'
                            type='text'
                            margin='normal'
                            color='success'
                            variant='filled'
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Divider className='custom-divider'>
                                <Typography variant='h6'>Stiftungsstammdaten</Typography>
                            </Divider>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <TextField
                                    label='Stiftungsname'
                                    fullWidth
                                    name='entity.name_full'
                                    helperText={!props.formData?.entity?.name_full && 'Diese Information muss gegeben sein.'}
                                    value={props?.formData?.entity?.name_full || ''}
                                    onChange={props.handleInputChange}
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label='Stiftungskürzel'
                                    helperText={'Kürzel wie IHK oder ähnliches'}
                                    name='entity.name'
                                    value={props?.formData?.entity?.name || ''}
                                    onChange={props.handleInputChange}
                                    fullWidth
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Divider className='custom-divider'>
                            <Typography variant='h6'>Kontaktdaten</Typography>
                        </Divider>
                    </Grid>*/}
                    <Anschrift formData={props.formData} handleInputChange={props.handleInputChange} />
                    <Telefonnummer formData={props.formData}
                                   handleInputChange={props.handleInputChange}
                                   name={'contact.Tel'} />

                    <CMXTextInput
                        widthInPercent={50}
                        formData={props.formData}
                        handleInputChange={props.handleInputChange}
                        label={'E-Mail'}
                        name='contact.Mail' />

                    <CMXTextInput
                        widthInPercent={50}
                        formData={props.formData}
                        handleInputChange={props.handleInputChange}
                        label={'Website'}
                        name='contact.URL' />

                    {/***********************************************Beteiligte Personen****************************************/}
                    {props?.formData?.entity?.type &&
                        <>
                            <CmxDivider title='Beteiligte' />
                            <Associates formData={props.formData} handleInputChange={props.handleInputChange}
                                        setFormData={props.setFormData} />
                        </>
                    }
                    {/***********************************************Bankdaten****************************************/}
                    <Grid item xs={12}>
                        <Divider className='custom-divider'>
                            <Typography variant='h6'>Bankkonto</Typography>
                        </Divider>
                    </Grid>
                    <Bank formData={props.formData} handleInputChange={props.handleInputChange} />

                    {/***********************************************Steuerdaten****************************************/}
                    <CmxDivider title='Steuerdaten' />
                    <Finanzamt formData={props.formData} handleInputChange={props.handleInputChange} loading={true} />


                    {/***********************************************Steuerdaten****************************************/}
                    <Grid item xs={12}>
                        <Divider className='custom-divider'>
                            <Typography variant='h6'>Assets</Typography>
                        </Divider>
                    </Grid>
                    <Assets formData={props.formData} handleInputChange={props.handleInputChange} />


                    {/******************************************Category Divider****************************************/}
                    {/***********************************************+++++++++++****************************************/}
                    <CmxDivider size='large' margin={50} />


                    <CmxDivider title='Tax Compliance' />
                    <TaxCompliance
                        formData={props.formData}
                        handleInputChange={props.handleInputChange}
                        arrayName={'entity.tax_compliance'} />

                    {/***********************************************Lead****************************************/}

                    <CmxDivider title='Themenschwerpunkt' />
                    <Fokus
                        formData={props.formData}
                        handleInputChange={props.handleInputChange} />

                    <CmxDivider title='Lead' />
                    <Lead formData={props.formData}
                          handleInputChange={props.handleInputChange} />


                    {/***********************************************Kanzleiinterne Informationen****************************************/}
                    <Grid item xs={12}>
                        <StyledAccordion>
                            <StyledAccordionSummary
                                expandIcon={<StyledExpandIcon color='success' fontSize='large' />}>
                                <CmxDivider title='Kanzleiinterne Informationen' xsWidth={11.5} />

                            </StyledAccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox color='success' />}
                                            label='Wichtiges Mandat'
                                            name={'entity.important_mandat'}
                                            checked={props.formData.entity?.important_mandat === 1}
                                            color='success'
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 35 }, fontWeight: 'bold' }}
                                            onChange={(event) => {
                                                const value = event.target.checked ? 1 : 0;
                                                props.handleInputChange({
                                                    target: {
                                                        name: 'entity.important_mandat',
                                                        value: value,
                                                    },
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <CMXTextInput
                                        formData={props.formData}
                                        handleInputChange={props.handleInputChange}
                                        name='entity.profession'
                                        label='Branche'
                                        widthInPercent={50}
                                        type='text'
                                    />
                                    <CMXTextInput
                                        formData={props.formData}
                                        handleInputChange={props.handleInputChange}
                                        name='entity.tax_relevant_income'
                                        label='Gewinn  (pro Jahr in Euro geschätzt)'
                                        widthInPercent={50}
                                        type='number'
                                    />
                                    <CMXTextInput
                                        formData={props.formData}
                                        handleInputChange={props.handleInputChange}
                                        name='entity.net_income'
                                        label='Nettogewinn (pro Jahr in Euro geschätzt)'
                                        widthInPercent={50}
                                        type='number'
                                    />
                                    <CMXTextInput
                                        formData={props.formData}
                                        handleInputChange={props.handleInputChange}
                                        name='entity.estimated_wealth'
                                        label='Betriebsvermögen (in Euro geschätzt)'
                                        widthInPercent={50}
                                        type='number'
                                    />

                                </Grid>
                            </AccordionDetails>
                        </StyledAccordion>
                    </Grid>
                </Grid>
            </div>
        </>
    )
        ;
};
export default Stiftung;
