import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React from 'react';
import { mapPercentageWidthToInt } from '../../helper.js';

const CMXTextInput = (props) => {
    const inputLabelProps = props.type === 'date' ? { shrink: true } : {};
    return (
        <>
            <Grid item xs={mapPercentageWidthToInt(props.widthInPercent !== undefined ? props.widthInPercent : 100)}>
                <TextField
                    label={props.label}
                    name={props.name}
                    value={props?.name.split('.').reduce((obj, key) => (obj ? obj[key] : obj), props.formData) ? props?.name.split('.').reduce((obj, key) => (obj ? obj[key] : obj), props.formData) : ''}
                    onChange={props.handleInputChange}
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                    type={props.type ?? 'text'}
                    InputLabelProps={inputLabelProps}
                />
            </Grid>
        </>
    );
};


const CmxDivider = (props) => {
    const className = props.size === 'large' ? 'custom-divider-large' : 'custom-divider';
    const gridItemStyle = props.margin ? {
        marginTop: props.margin,
        marginBottom: props.margin,
    } : { marginTop: '40px' };

    return (
        <>
            <Grid item xs={props.xsWidth ? props.xsWidth : 12} style={gridItemStyle}>
                <Divider className={className}>
                    {props.title &&
                        <Typography variant='h6'>{props.title}</Typography>
                    }
                </Divider>
            </Grid>
        </>
    );
};


const CmxSelectionMenu = (props) => {
        return (
            <>
                <Grid item xs={mapPercentageWidthToInt(props.widthInPercent !== undefined ? props.widthInPercent : 100)}>
                    <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                        <InputLabel>{props.label}</InputLabel>
                        <Select
                            value={props?.name.split('.').reduce((obj, key) => (obj ? obj[key] : obj), props.formData) ? props?.name.split('.').reduce((obj, key) => (obj ? obj[key] : obj), props.formData) : ''}
                            label={props.label}
                            name={props.name}
                            onChange={props.handleInputChange}
                        >
                            {props.menuItems && props.menuItems.length > 0 ?
                                props.menuItems.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.name}
                                    </MenuItem>
                                )) :
                                <MenuItem key={1} value={'empty'}>Empty</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </>
        )
            ;
    }
;

const CmxFormElement = (props) => {
    const type = props.formElement ? props.formElement : 'TextInput';

    if (type === 'TextInput') {
        return <CMXTextInput {...props} />;
    } else if (type === 'Select') {
        return <CmxSelectionMenu {...props} />;
    } else if (type === 'Divider') {
        return <CmxDivider {...props} />;
    } else {
        throw new Error(`Unsupported form element type: ${type}`);
    }
};

export { CMXTextInput, CmxDivider, CmxSelectionMenu, CmxFormElement };


// Example code for CmxFormElement
/* <CmxFormElement
                        formElement='Divider'
                        title='Testing CmxFormElement'
                    />


                    <CmxFormElement
                        formElement='TextInput'
                        formData={props.formData}
                        handleInputChange={props.handleInputChange}
                        name='test inputText'
                        label='Whatever Text'
                        widthInPercent={50}
                    />
                    <CmxFormElement
                        formElement='Select'
                        formData={props.formData}
                        handleInputChange={props.handleInputChange}
                        name='test select'
                        label='Whatever Text'
                        widthInPercent={50}
                        menuItems={[
                            {
                                name: 'have',
                                value: 'have',
                            }, {
                                name: 'some',
                                value: 'some',
                            }, {
                                name: 'fun',
                                value: 'fun',
                            },
                        ]}
                    />

 */