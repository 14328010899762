import React, { useEffect, useState } from 'react';
import GemFa from '../../../../misc/auto_data/GemFA.json';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Button,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import TaxNumber from './taxNumber.js';
import TaxID from './taxID.js';
import { AddCircleRounded, Close, ExpandCircleDownSharp, Phone, Sms } from '@mui/icons-material';
import { styled } from '@mui/system';
import ApiCaller from '../../../../misc/APICaller.js';
import { useNotification } from '../../../../misc/notification.js';


const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const Finanzamt = (props) => {
    const [faData, setFaData] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const [contactPersons, setContactPersons] = useState(props.formData.taxAuthorityContactPersons ? props.formData.taxAuthorityContactPersons : []);
    const [contactPersonLoadingFirst, setContactPersonLoadingFirst] = useState(false);
    const [taxAuthorityContactPersonFirstName, setTaxAuthorityContactPersonFirstName] = useState('');
    const [taxAuthorityContactPersonLastName, setTaxAuthorityContactPersonLastName] = useState('');
    const [taxAuthorityContactPersonPhoneNumber, setTaxAuthorityContactPersonPhoneNumber] = useState('');
    const [taxAuthorityContactPersonGender, setTaxAuthorityContactPersonGender] = useState('');
    const [firstLoadingTaxAuth, setFirstLoadingTaxAuth] = useState(true);
    const [initialContactPersonLoad, setInitialContactPersonLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const apiCaller = new ApiCaller();

    function getContact(contacts, type) {
        if (contacts.length > 0) {
            for (const contact of contacts) {
                if (contact.type === type) {
                    return contact.text;
                }
            }
            return null;
        }
        return '';
    }

    useEffect(() => {
        if (faData.length > 0) {
            if (props.loading & firstLoadingTaxAuth) {
                if (props.formData.taxAuthorityID) {
                    const selectedOption = faData.find((option) => option.id === props.formData.taxAuthorityID);
                    setSelectedOption(selectedOption);
                    handleAutocompleteChange(null, selectedOption);
                }
                setFirstLoadingTaxAuth(false);
            }
        }
    }, [faData]);

    const { showNotification } = useNotification();

    const genderOptions = [
        { value: 'male', label: 'Herr' },
        { value: 'female', label: 'Frau' },
        { value: 'diverse', label: '-' },
        { value: '', label: 'Keine' },
        { value: null, label: '' },
    ];


    const handleAutocompleteChange = (event, value) => {
        if (value) {
            const address = value.location[0];
            const updatedFields = {
                taxAuthorityzipCode: address?.zipcode || '',
                taxAuthorityCity: address?.city || '',
                taxAuthorityName: value.name_full || '',
                taxAuthorityStreet: address?.street || '',
                taxAuthorityStreetNumber: address?.street_number || '',
                taxAuthorityPhoneNumber: getContact(value.contact, 'Tel') || '',
                taxAuthorityFax: getContact(value.contact, 'Fax') || '',
                taxAuthorityEmail: getContact(value.contact, 'Mail'),
                taxAuthorityID: value.id,

            };
            Object.entries(updatedFields).forEach(([name, value]) => {
                const fieldEvent = {
                    target: {
                        name,
                        value,
                    },
                };
                props.handleInputChange(fieldEvent);
            });

        } else {
            setSelectedOption(null);
            const resetFields = [
                'taxAuthorityName',
                'taxAuthorityStreet',
                'taxAuthorityStreetNumber',
                'taxAuthorityzipCode',
                'taxAuthorityCity',
                'taxAuthorityPhoneNumber',
                'taxAuthorityFax',
                'taxAuthorityEmail',
                'taxAuthorityAvailabilityNote',
            ];

            resetFields.forEach((fieldName) => {
                const resetEvent = {
                    target: {
                        name: fieldName,
                        value: '',
                    },
                };
                props.handleInputChange(resetEvent);
            });
        }
    };

    const handleContactPersonChange = (index, field, value) => {
        setContactPersons(prevContactPersons => {
            const updatedContactPersons = [...prevContactPersons];
            updatedContactPersons[index][field] = value;
            return updatedContactPersons;
        });
    };


    const handleRemoveContactPerson = (index) => {
        setContactPersons(prevContactPersons => [
            ...prevContactPersons.slice(0, index),
            ...prevContactPersons.slice(index + 1),
        ]);
    };
    const handleAddContactPerson = () => {
        const newContactPerson = {
            //first_name: taxAuthorityContactPersonFirstName || '',
            gender: taxAuthorityContactPersonGender || '',
            name: taxAuthorityContactPersonLastName || '',
            Tel: taxAuthorityContactPersonPhoneNumber || '',
        };
        setContactPersons([...contactPersons, newContactPerson]);
        setTaxAuthorityContactPersonPhoneNumber('');
        setTaxAuthorityContactPersonFirstName('');
        setTaxAuthorityContactPersonLastName('');
        setTaxAuthorityContactPersonGender('');
    };

    useEffect(() => {
        if (contactPersonLoadingFirst) {
            props.handleInputChange({
                target: {
                    name: 'taxAuthorityContactPersons',
                    value: contactPersons,
                },
            });
        } else {
            setContactPersonLoadingFirst(true);
        }
        console.log('actual state');
        console.log(props.formData.taxAuthorityContactPersons);
        console.log(contactPersons);
    }, [contactPersons]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const taxAuthList = await apiCaller.callApi('getTaxAuthorities', 'GET');
                setFaData(taxAuthList.data);
                setIsLoading(false);

            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const filteredData = GemFa.filter((item) => item?.AdresseListe[0]?.Adresse[0]['$']?.Ort === selectedOption?.['$']?.Name);
    }, [selectedOption]);

    useEffect(() => {
        if (initialContactPersonLoad && props.formData.taxAuthorityContactPersons !== undefined) {
            setContactPersons(props.formData.taxAuthorityContactPersons);
            setInitialContactPersonLoad(false);
        }
    }, [props.formData.taxAuthorityContactPersons]);

    return (
        isLoading ? (
            <>
                <Grid item xs={12}>
                    <p style={{ textAlign: 'center' }}> Finanzämter werden geladen</p>
                    <Skeleton variant='rounded' height={800} animation='wave' />
                    {/*<LinearProgress color='success' />
                    <br />
                    <p style={{ textAlign: 'center' }}> Finanzämter werden geladen</p>*/}
                </Grid>
            </>
        ) : (
            <>
                <Grid item xs={12} sm={12}>
                    <Autocomplete
                        filterOptions={(options, params) => {
                            if (params.inputValue.length < 3) {
                                return [];
                            }
                            return options.filter((option) => {
                                return (
                                    option.name_full.toLowerCase().includes(params.inputValue.toLowerCase())

                                );
                            });
                        }}
                        options={faData}
                        value={selectedOption === undefined ? '' : selectedOption}
                        noOptionsText='Bitte Ort oder Finanzamtname eingeben und auswählen'
                        getOptionLabel={(option) => option.name_full}
                        onChange={handleAutocompleteChange}
                        renderOption={(props, option) => (
                            <li {...props}>
                            <span>{option.name_full},{' '}
                                {option.location[0]?.street}{' '}
                                {option.location[0]?.street_number},{' '}
                                {option.location[0]?.zipcode}{' '}
                                {option.location[0]?.zipcode.city}
                            </span>
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                label='Name Finanzamt'
                                name='taxAuthorityName'
                                id='taxAuthorityName'
                                value={props.formData.taxAuthorityName}
                                onChange={props.handleInputChange}
                                fullWidth
                                type='text'
                                margin='normal'
                                color='success'
                                variant='filled'
                                {...params}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        label='Straße Finanzamt'
                        name='taxAuthorityStreet'
                        type='text'
                        value={props.formData.taxAuthorityStreet}
                        onChange={props.handleInputChange}
                        fullWidth
                        margin='normal'
                        color='success'
                        variant='filled'
                        InputLabelProps={{
                            shrink: props.formData.taxAuthorityStreet !== '' && props.formData.taxAuthorityStreet !== undefined,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label='Nr Finanzamt'
                        name='taxAuthorityStreetNumber'
                        value={props.formData.taxAuthorityStreetNumber}
                        onChange={props.handleInputChange}
                        fullWidth
                        type='text'
                        margin='normal'
                        color='success'
                        variant='filled'
                        InputLabelProps={{
                            shrink: props.formData.taxAuthorityStreetNumber !== '' && props.formData.taxAuthorityStreetNumber !== undefined,
                        }}
                    />

                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label='PLZ Finanzamt'
                        name='taxAuthorityzipCode'
                        value={props.formData.taxAuthorityzipCode}
                        onChange={props.handleInputChange}
                        fullWidth
                        type='text'
                        margin='normal'
                        color='success'
                        variant='filled'
                        InputLabelProps={{
                            shrink: props.formData.taxAuthorityzipCode !== '' && props.formData.taxAuthorityzipCode !== undefined,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label='Ort Finanzamt'
                        name='taxAuthorityCity'
                        value={props.formData.taxAuthorityCity}
                        onChange={props.handleInputChange}
                        fullWidth
                        type='text'
                        margin='normal'
                        color='success'
                        variant='filled'
                        InputLabelProps={{
                            shrink: props.formData.taxAuthorityCity !== '' && props.formData.taxAuthorityCity !== undefined,
                        }}
                    />

                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label='Telefonnummer Finanzamt'
                        name='taxAuthorityPhoneNumber'
                        value={props.formData.taxAuthorityPhoneNumber}
                        onChange={props.handleInputChange}
                        fullWidth
                        margin='normal'
                        color='success'
                        variant='filled'
                        InputLabelProps={{
                            shrink: props.formData.taxAuthorityPhoneNumber !== '' && props.formData.taxAuthorityPhoneNumber !== undefined,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label='Faxnummer Finanzamt'
                        name='taxAuthorityFax'
                        value={props.formData.taxAuthorityFax}
                        onChange={props.handleInputChange}
                        fullWidth
                        type='text'
                        margin='normal'
                        color='success'
                        variant='filled'
                        InputLabelProps={{
                            shrink: props.formData.taxAuthorityFax !== '' && props.formData.taxAuthorityFax !== undefined,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label='E-Mail Finanzamt'
                        name='taxAuthorityEmail'
                        value={props.formData.taxAuthorityEmail}
                        onChange={props.handleInputChange}
                        type='email'
                        fullWidth
                        margin='normal'
                        color='success'
                        variant='filled'
                        InputLabelProps={{
                            shrink: props.formData.taxAuthorityEmail !== '' && props.formData.taxAuthorityEmail !== undefined,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label='Bundesfinanzamt Nummer (BuFaNr)'
                        name='taxAuthorityBuFaNr'
                        value={props.formData.taxAuthorityBuFaNr}
                        onChange={props.handleInputChange}
                        fullWidth
                        type='text'
                        margin='normal'
                        color='success'
                        variant='filled'
                    />
                </Grid>
                <TaxNumber formData={props.formData} handleInputChange={props.handleInputChange} />
                <TaxID formData={props.formData} handleInputChange={props.handleInputChange} />

                {/********************************************Ansprechpartner Finanzamt *************************************************************/}
                <Grid item xs={12} sm={12}>
                    {contactPersons.map((contact, index) => (
                        <Accordion
                            style={{ backgroundColor: 'whitesmoke' }}
                            key={index}
                            color='success'
                            variant='filled'
                        >
                            <StyledAccordionSummary
                                expandIcon={<ExpandCircleDownSharp />}
                                aria-label='Add'
                            >
                                <Typography
                                    variant='body1'><b>Ansprechpartner {index + 1} {' '}:</b>
                                    {/*{contact?.first_name ? contact?.first_name + ' ' : ''},*/}
                                    {contact.gender ? ' ' + genderOptions.find((obj) => obj.value == contact.gender).label + ' ' : ''}
                                    {contact?.name ? contact?.name + ' ' : ''}
                                    {contact?.Tel && (
                                        <Tooltip title='Zum Kopieren klicken'>
                                            <Button variant='text'
                                                    color='success'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        showNotification('success', 'Nummber erfolgreich kopiert', 2000);
                                                        navigator.clipboard.writeText(contact.Tel);
                                                    }}>
                                                {contact.Tel}
                                            </Button>
                                        </Tooltip>
                                    )}
                                    {contact.Tel !== '' && (
                                        < >
                                            <IconButton
                                                color='success'
                                                aria-label='Call'
                                                component='a'
                                                href={`tel:${contact.Tel}`}
                                            >
                                                <Phone color='success' fontSize='small' />
                                            </IconButton>
                                            <IconButton
                                                color='primary'
                                                aria-label='Call'
                                                component='a'
                                                href={`sms:${contact.Tel}`}
                                            >
                                                <Sms color='success' fontSize='small' />
                                            </IconButton>
                                        </>

                                    )}
                                </Typography>
                                <IconButton
                                    color='success'
                                    aria-label='Remove'
                                    onClick={() => handleRemoveContactPerson(index)}
                                >
                                    <Close />
                                </IconButton>
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>

                                    {/*<Grid item xs={12} sm={6}>
                                        <TextField
                                            label='Vorname'
                                            value={contact.first_name}
                                            onChange={(event) => handleContactPersonChange(index, 'first_name', event.target.value)}
                                            fullWidth
                                            type='text'
                                            margin='normal'
                                            color='success'
                                            variant='filled'
                                        />
                                    </Grid>*/}
                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant='filled' color='success' fullWidth
                                                     style={{ marginTop: '15px' }}>
                                            <InputLabel id='salutation-id'>Anrede</InputLabel>
                                            <Select
                                                defaultValue=''
                                                value={contact.gender}
                                                label='Anrede'
                                                name='gender'
                                                onChange={(event) => handleContactPersonChange(index, 'gender', event.target.value)}
                                            >
                                                {genderOptions.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label='Nachname'
                                            value={contact.name}
                                            onChange={(event) => handleContactPersonChange(index, 'name', event.target.value)}
                                            fullWidth
                                            type='text'
                                            margin='normal'
                                            color='success'
                                            variant='filled'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label='Telefonnummer'
                                            value={contact.Tel}
                                            onChange={(event) => handleContactPersonChange(index, 'Tel', event.target.value)}
                                            fullWidth
                                            margin='normal'
                                            color='success'
                                            variant='filled'
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))
                    }
                </Grid>
                <Container maxWidth style={{ marginTop: '30px' }}>
                    <div style={{ width: '100%' }} className='box'>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Ansprechpartner Finanzamt</Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={3.5}>
                                <TextField
                                    label='Vorname'
                                    name='taxAuthorityContactPersonFirstName'
                                    value={taxAuthorityContactPersonFirstName}
                                    onChange={(event) => setTaxAuthorityContactPersonFirstName(event.target.value)}
                                    fullWidth
                                    type='text'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>*/}
                            <Grid item xs={12} sm={3.5}>
                                <FormControl variant='filled' color='success' fullWidth
                                             style={{ marginTop: '15px' }}>
                                    <InputLabel id='salutation-id'>Anrede</InputLabel>
                                    <Select
                                        defaultValue=''
                                        value={taxAuthorityContactPersonGender}
                                        label='Anrede'
                                        name='taxAuthorityContactPersonGender'
                                        onChange={(event) => setTaxAuthorityContactPersonGender(event.target.value)}
                                    >
                                        {genderOptions.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3.5}>
                                <TextField
                                    label='Nachname'
                                    name='taxAuthorityContactPersonLastName'
                                    value={taxAuthorityContactPersonLastName}
                                    onChange={(event) => setTaxAuthorityContactPersonLastName(event.target.value)}
                                    fullWidth
                                    type='text'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>


                            <Grid item xs={12} sm={3.5}>
                                <TextField
                                    label='Telefonnummer'
                                    name='taxAuthorityContactPersonPhoneNumber'
                                    value={taxAuthorityContactPersonPhoneNumber}
                                    onChange={(event) => setTaxAuthorityContactPersonPhoneNumber(event.target.value)}
                                    fullWidth
                                    type='text'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>

                            <Grid item xs={12} sm={1} style={{ marginTop: '20px' }}>
                                <IconButton color='success' onClick={handleAddContactPerson}>
                                    <AddCircleRounded fontSize='large' />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
                <Grid item xs={12} sm={12}>
                    <TextField
                        label='Notiz zu Verfügbarkeit Finanzamt'
                        name='taxAuthorityAvailabilityNote'
                        value={props.formData.taxAuthorityAvailabilityNote}
                        onChange={props.handleInputChange}
                        fullWidth
                        multiline={true}
                        rows={2}
                        type='text'
                        margin='normal'
                        color='success'
                        variant='filled'
                        InputLabelProps={{
                            shrink: props.formData.taxAuthorityAvailabilityNote !== '' && props.formData.taxAuthorityAvailabilityNote !== undefined,
                        }}
                    />
                </Grid>
            </>
        )
    )
        ;

};

export default Finanzamt;

// Find list and autocomplete Finanzamt data