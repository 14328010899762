const leads = [
    {
        name: 'Empfehlung',
        image: 'recommendation.png',
    },
    {
        name: 'Website',
        image: 'website.png',
    },
    {
        name: 'Podcast / YouTube',
        image: 'podcast.png',
    },

    {
        name: 'Suchmaschine',
        image: 'searchengine.png',
    },
];

export default leads;