import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Tooltip,
} from '@mui/material';
import React from 'react';
import Rechtsform from './formComponents/rechtsform.js';
import Anschrift from './formComponents/anschrift.js';
import Telefonnummer from './formComponents/telefonnummer.js';
import { CmxDivider, CmxSelectionMenu, CMXTextInput } from './formComponents/utils.js';
import Assets from './formComponents/assets.js';
import Bank from './formComponents/bank.js';
import TaxCompliance from './formComponents/taxCompliance.js';
import Fokus from './formComponents/fokus.js';
import Finanzamt from './formComponents/finanzamt.js';
import Lead from './formComponents/lead.js';
import DateField from './formComponents/dateField.js';
import Associates from './formComponents/associates.js';
import { styled } from '@mui/system';
import { ExpandCircleDown, InfoOutlined } from '@mui/icons-material';

const StyledAccordion = styled(Accordion)({
    boxShadow: 'none', // Remove the drop shadow
});

const StyledAccordionSummary = styled(AccordionSummary)({
    display: 'flex',
    alignItems: 'flex-end', // Align items to the bottom
});

const StyledCmxDivider = styled(CmxDivider)({
    flex: 1, // Make the divider expand to fill available space
});

const StyledExpandIcon = styled(ExpandCircleDown)({
    marginLeft: 'auto',
    marginBottom: '10px', // Move the expand icon to the right
});

const Gesellschaft = (props) => {
    return (
        <>
            <div style={{ padding: '20px' }}>
                <Grid container spacing={2} justify='center' style={{ marginBottom: '25px' }}>
                    {/***********************************************Rechtsform****************************************/}
                    <CmxDivider title='Rechtsform' />
                    <Rechtsform formData={props.formData} handleInputChange={props.handleInputChange} />
                    <Grid item xs={12} sm={12}>
                        <TextField
                            label='Gesellschaftszweck laut Handelsregister'
                            name='entity.purpose'
                            value={props?.formData?.entity?.purpose || ''}
                            onChange={props.handleInputChange}
                            fullWidth
                            multiline={true}
                            minRows='2'
                            type='text'
                            margin='normal'
                            color='success'
                            variant='filled'
                        />
                    </Grid>
                    {/***********************************************Firmenstammdaten****************************************/}
                    <CmxDivider title={'Firmenstammdaten'} />
                    <Grid item xs={9}>
                        <TextField
                            label='Firmenname'
                            required
                            name='entity.name_full'
                            helperText={!props.formData?.entity?.name_full && 'Diese Information muss gegeben sein.'}
                            value={props?.formData?.entity?.name_full || ''}
                            onChange={props.handleInputChange}
                            fullWidth
                            margin='normal'
                            color='success'
                            variant='filled'
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Namenskürzel'
                            helperText={'Kürzel wie IHK oder ähnliches'}
                            name='entity.name'
                            value={props?.formData?.entity?.name || ''}
                            onChange={props.handleInputChange}
                            fullWidth
                            margin='normal'
                            color='success'
                            variant='filled'
                        />
                    </Grid>
                    <Anschrift formData={props.formData} handleInputChange={props.handleInputChange} />
                    <Telefonnummer formData={props.formData} handleInputChange={props.handleInputChange}
                                   name={'contact.Tel'} />
                    <CMXTextInput
                        widthInPercent={50}
                        formData={props.formData}
                        handleInputChange={props.handleInputChange}
                        label={'E-Mail'}
                        name='contact.Mail'
                    />
                    <CMXTextInput
                        widthInPercent={50}
                        formData={props.formData}
                        handleInputChange={props.handleInputChange}
                        label={'Website'}
                        name='contact.URL'
                    />
                    <DateField
                        formData={props.formData}
                        handleInputChange={props.handleInputChange}
                        label='Gründungsdatum'
                        name='entity.birth_date'
                    />
                    {/***********************************************Beteiligte Personen****************************************/}
                    {props?.formData?.entity?.type && (
                        <>
                            <CmxDivider title='Beteiligte' />
                            <Associates
                                formData={props.formData}
                                handleInputChange={props.handleInputChange}
                                setFormData={props.setFormData}
                            />
                        </>
                    )}
                    {/******************************************Category Divider****************************************/}
                    {/***********************************************+++++++++++****************************************/}
                    <CmxDivider size='large' margin={50} />
                    {/***********************************************Kontoverbindung****************************************/}
                    <CmxDivider title='Bankverbindung' />
                    <Bank formData={props.formData} handleInputChange={props.handleInputChange} />
                    {/***********************************************Steuerdaten****************************************/}
                    <CmxDivider title='Steuerdaten' />
                    <Finanzamt formData={props.formData} handleInputChange={props.handleInputChange} loading={true} />
                    {/***********************************************Assets****************************************/}
                    <CmxDivider title='Assets' />
                    <Assets handleInputChange={props.handleInputChange} formData={props.formData} />
                    <CmxDivider title='Tax Compliance' />
                    <TaxCompliance
                        formData={props.formData}
                        handleInputChange={props.handleInputChange}
                        arrayName={'entity.tax_compliance'}
                    />
                    {/******************************************Category Divider****************************************/}
                    {/***********************************************+++++++++++****************************************/}
                    <CmxDivider size='large' margin={50} />
                    {/***********************************************Lead****************************************/}
                    <CmxDivider title='Themenschwerpunkt' />
                    <Fokus formData={props.formData} handleInputChange={props.handleInputChange} />
                    <CmxDivider title='Lead' />
                    <Lead formData={props.formData} handleInputChange={props.handleInputChange} />
                    {/***********************************************Kanzleiinterne Informationen****************************************/}
                    <Grid item xs={12}>
                        <StyledAccordion>
                            <StyledAccordionSummary
                                expandIcon={<StyledExpandIcon color='success' fontSize='large' />}
                            >
                                <StyledCmxDivider title='Kanzleiinterne Informationen' xsWidth={11.5} />
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox color='success' />}
                                            label='Wichtiges Mandat'
                                            name={'entity.important_mandat'}
                                            checked={props.formData.entity?.important_mandat === 1}
                                            color='success'
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 35 }, fontWeight: 'bold' }}
                                            onChange={(event) => {
                                                const value = event.target.checked ? 1 : 0;
                                                props.handleInputChange({
                                                    target: {
                                                        name: 'entity.important_mandat',
                                                        value: value,
                                                    },
                                                });
                                            }}
                                        />
                                        <Tooltip
                                            title='Umsatz > EUR 5.000 p.a., sympathisch, strategisch interessant und Weiterempfehlungen'>
                                            <InfoOutlined />
                                        </Tooltip>
                                    </Grid>
                                    <CMXTextInput
                                        formData={props.formData}
                                        handleInputChange={props.handleInputChange}
                                        name='entity.profession'
                                        label='Branche'
                                        widthInPercent={50}
                                        type='text'
                                    />
                                    <CMXTextInput
                                        formData={props.formData}
                                        handleInputChange={props.handleInputChange}
                                        name='entity.tax_relevant_income'
                                        label='Gewinn  (pro Jahr in Euro geschätzt)'
                                        widthInPercent={50}
                                        type='number'
                                    />
                                    <CMXTextInput
                                        formData={props.formData}
                                        handleInputChange={props.handleInputChange}
                                        name='entity.net_income'
                                        label='Nettogewinn (pro Jahr in Euro geschätzt)'
                                        widthInPercent={50}
                                        type='number'
                                    />
                                    <CMXTextInput
                                        formData={props.formData}
                                        handleInputChange={props.handleInputChange}
                                        name='entity.estimated_wealth'
                                        label='Betriebsvermögen (in Euro geschätzt)'
                                        widthInPercent={50}
                                        type='number'
                                    />
                                    <CmxSelectionMenu
                                        formData={props.formData}
                                        handleInputChange={props.handleInputChange}
                                        name={'entity.emigrate_thoughts'}
                                        label={'Planen Gesellschafter, in den nächsten 10 Jahren einen Wohnsitz im Ausland zu begründen'}
                                        menuItems={[
                                            { name: 'Ja', value: 'yes' },
                                            { name: 'Nein', value: 'no' },
                                        ]}
                                    />
                                </Grid>
                            </AccordionDetails>
                        </StyledAccordion>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default Gesellschaft;
