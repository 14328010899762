const relationTypes = [
    {
        name: 'Bruder',
        type: 'person',
        label: 'brother',
        user: ['Person'],
    },
    {
        name: 'Schwester',
        type: 'person',
        label: 'sister',
        user: ['Person'],
    },
    {
        name: 'Sohn',
        label: 'son',
        type: 'person',
        user: ['Person'],
    },
    {
        name: 'Tochter',
        type: 'person',
        label: 'daughter',
        user: ['Person'],
    },
    {
        name: 'Mutter',
        type: 'person',
        label: 'mother',
        user: ['Person'],
    },
    {
        name: 'Stiefmutter',
        label: 'stepmother',
        user: ['Person'],
    },

    {
        name: 'Vater',
        type: 'person',
        label: 'father',
        user: ['Person'],
    },
    {
        name: 'Stiefvater',
        type: 'person',
        label: 'stepfather',
        user: ['Person'],
    },
    {
        name: 'Großmutter',
        type: 'person',
        label: 'grandmother',
        user: ['Person'],
    },
    {
        name: 'Großvater',
        type: 'person',
        label: 'grandfather',
        user: ['Person'],
    },
    {
        name: 'Ehepartner',
        type: 'person',
        label: 'spouse',
        user: ['Person'],
    },
    {
        name: 'Enkeltochter',
        type: 'person',
        label: 'granddaughter',
        user: ['Person'],
    }, {
        name: 'Enkelsohn',
        type: 'person',
        label: 'grandson',
        user: ['Person'],
    }];


export default relationTypes;