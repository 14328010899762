import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import ReceiverAddressField from './ReceiverAddressField.js';
import ApiCaller from '../../../../misc/APICaller.js';
import { shortenTaxAuhtorityString } from '../../helper.js';

function CustomTabPanel(props) {
    const { children, value, index, receiver, ...other } = props;


    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ReceiverTabs(props) {
    const [tabValue, setTabValue] = useState(props?.draftData?.receiver_type ?? 'authority');
    const [presets, setPresets] = useState([]);
    const [buttonIndex, setButtonIndex] = useState(props?.draftData?.buttonIndex || -1);
    const apiCaller = new ApiCaller();

    useEffect(() => {
        console.log('Button Index');
        console.log(buttonIndex);
        console.log('props button index');
        console.log(props?.draftData?.buttonIndex);
    }, [buttonIndex]);

    useEffect(() => {
        console.log('DraftData');
        console.log(props?.draftData);
        const fetchData = async () => {
            await loadPresets(tabValue);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await loadPresets(tabValue);
        };
        fetchData();
    }, [props.draftData?.[props.question.dependency]]);


    async function loadPresets(type) {
        let presetInfo = [];
        if (props.draftData?.[props.question.dependency]) {
            if (type === 'authority') {
                presetInfo = await apiCaller.callApi('getTaxAuthorityListByEntity', 'GET', {}, { entity_id: props.draftData?.[props.question.dependency] });
                setPresets(presetInfo.data);
            } else if (type === 'court') {
                console.log('court');
                setPresets([]);
            } else {
                return null;
            }
        }
    }

    const setAddress = (index) => {
        if (index === buttonIndex) {
            setButtonIndex(-1);
            props.setDraftData({ ...props.draftData, ['buttonIndex']: -1 });
        } else {
            setButtonIndex(index);
            props.setDraftData({ ...props.draftData, ['buttonIndex']: index });
        }
    };

    const handleChange = (event, newReceiver) => {
        setTabValue(newReceiver);
    };

    return (
        <Grid container xs={11.8} spacing={2}>
            <Box sx={{ width: '99%', marginTop: '10px' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label='basic tabs example'>
                        <Tab label='Behörde'
                             value='authority' {...a11yProps('authority')} /> {/* Set value prop to receiver string */}
                        <Tab label='Gericht'
                             value='court' {...a11yProps('court')} /> {/* Set value prop to receiver string */}
                        <Tab label='Mandant'
                             value='mandator' {...a11yProps('mandator')} /> {/* Set value prop to receiver string */}
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={'authority'} receiver={'authority'} preset={presets}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Grid container spacing={2}>
                                {presets ? presets.map((obj, index) => (
                                    <Grid item key={index}>
                                        <Button
                                            key={index}
                                            variant={buttonIndex === index ? 'contained' : 'outlined'}
                                            onClick={() => setAddress(index)}
                                            color='primary' // Use 'primary' or 'secondary' instead of 'success'
                                        >
                                            {shortenTaxAuhtorityString(obj.entity.name_full ?? '')}
                                        </Button>
                                    </Grid>
                                )) : null}
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <ReceiverAddressField draftData={props.draftData} setDraftData={props.setDraftData}
                                                  presets={presets} buttonIndex={buttonIndex}
                                                  setButtonIndex={setButtonIndex}
                                                  question={props.question} />
                        </Grid>
                    </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={'court'} receiver={'court'}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid xs={9}>
                            <ReceiverAddressField draftData={props.draftData} setDraftData={props.setDraftData}
                                                  presets={presets} buttonIndex={buttonIndex}
                                                  setButtonIndex={setButtonIndex}
                                                  question={props.question} />
                        </Grid>
                    </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={'mandator'} receiver={'mandator'}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>

                        </Grid>
                        <Grid xs={9}>
                            <ReceiverAddressField draftData={props.draftData} setDraftData={props.setDraftData}
                                                  presets={presets} buttonIndex={buttonIndex}
                                                  setButtonIndex={setButtonIndex}
                                                  question={props.question} />
                        </Grid>
                    </Grid>
                </CustomTabPanel>
                {/*   <CustomTabPanel value={value} index={3}>
                    Kontakt/Neu
                </CustomTabPanel>*/}
            </Box>
        </Grid>
    );
}
