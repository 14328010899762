import React, { useEffect, useState } from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { AccountBalance, Close, CloseSharp, InfoOutlined } from '@mui/icons-material';
import ApiCaller from '../../../misc/APICaller.js';
import { draftTemplates } from '../../../misc/auto_data/draftTemplates.js';
import { useNotification } from '../../../misc/notification.js';
import CL_APICaller from '../../../misc/CL_APICaller.js';
import { styled } from '@mui/system';
import { downloadDraft, mapPercentageWidthToInt } from '../../../misc/helper.js';
import dayjs from 'dayjs';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import ButtonSelect from './ButtonSelect.js';
import ButtonMultiSelect from './ButtonMultiSelect.js';
import NewContact from './components/newContact.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js'; // Import the localized format plugin
import utc from 'dayjs/plugin/utc.js'; // Import the UTC plugin
import timezone from 'dayjs/plugin/timezone.js';
import { formatEntityString, loadCustomer } from '../helper.js';
import ImmoStepper from './components/ImmoStepper.js';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import LawyerLetterStepper from './components/LawyerLetterStepper.js';
import ReceiverTabs from './components/ReceiverTabs.js';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const StepperWrapper = styled('div')({
    width: '100%',
});

function sortByAttribute(array, attribute) {
    return array.slice().sort((a, b) => {
        if (a[attribute] < b[attribute]) {
            return -1;
        }
        if (a[attribute] > b[attribute]) {
            return 1;
        }
        return 0;
    });
}

const NumericIntegerCurrency = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                const intValue = parseInt(values.value.replace(/\D/g, ''), 10);
                onChange({
                    target: {
                        name: props.name,
                        value: intValue,
                    },
                });
            }}
            thousandSeparator='.'
            decimalSeparator=','
        />
    );
});

NumericIntegerCurrency.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const StepperForm = ({ data, draft, setOpen, loadDrafts, resetDraftData, type_step }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [stepType, setStepType] = useState(type_step);
    const [draftData, setDraftData] = useState(draft);
    const [options, setOptions] = useState({});
    const [checkboxItems, setCheckboxItems] = useState({});
    const [selectionItems, setSelectionItems] = useState({});
    const apiCaller = new ApiCaller();
    const clApiCaller = new CL_APICaller();
    const { showNotification } = useNotification();
    const [showAnimation, setShowAnimation] = useState(false);
    const [loadedAutocompleteOptions, setLoadedAutocompleteOptions] = useState({});
    const [freeSoloItems, setFreeSoloItems] = useState({});
    const [consultantEntity, setConsultantEntity] = useState(0);
    const [mandateID, setMandateID] = useState('');
    const [previousMandate, setPreviousMandate] = useState('');
    const [textFieldOptions, setTextFieldOptions] = useState([]);
    const [selectedButtonFocus, setSelectedButtonFocus] = useState(draftData['selectedButtonFocus'] || []);
    const localTimeZone = 'Europe/Berlin'; // Replace with your local time zone
    const [initialDateSet, setInitialDateSet] = useState(false);


    const loadAutoCompleteData = async (type, strings = '', val = null) => {
        if (type === 'clients') {
            let values = [{ label: 'keine', value: 'keine' }]; // Initialize with a default option
            const id = localStorage.getItem('id');
            const response = await apiCaller.callApi('getClientsByUserID', 'GET', {}, { user_id: id });
            values = response.data.persons
                .sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                })
                .map((person) => {
                    const label = `${person.title ? person.title + ' ' : ''}${person.first_name} ${person.name}`;
                    const value = person.id;
                    return {
                        label,
                        value,
                    };
                });
            return values;
        } else if (type == 'allClients') {
            let values = [{ label: 'keine', value: 'keine' }]; // Initialize with a default option
            const response = await loadCustomer(localStorage.getItem('id'));
            values = response.map((person) => {
                const label = formatEntityString(person);
                const value = person.id;
                return {
                    label,
                    value,
                };
            });
            return values;
        } else if (type == 'companies') {
            let values = [{ label: 'keine', value: 'keine' }]; // Initialize with a default option
            const response = await loadCustomer(localStorage.getItem('id'));
            const companies = response.filter(item => item.entity_type !== 'person');
            console.log('Companies Companies');
            console.log(companies);
            values = companies.map((person) => {
                const label = formatEntityString(person);
                const value = person.id;
                return {
                    label,
                    value,
                };
            });
            return values;
        } else if (type == 'real_estate_owner') {
            let values = [{ label: 'keine', value: 'keine' }]; // Initialize with a default option
            const id = localStorage.getItem('id');
            const response = await apiCaller.callApi('getRealEstateClientsByUserID', 'GET', {}, { user_id: id });
            values = response.data.map((person) => {
                const label = `${person.title ? person.title : ''} ${person.name} ${person.first_name}`;
                const value = person[val];
                return {
                    label,
                    value,
                };
            });
            return values;
        } else if (type === 'real_estates') {
            if (draftData.giver && draftData.giver.length > 0) {
                const real_estates = await apiCaller.callApi('getRealEstatesByEntityID', 'GET', {}, { giver: draftData.giver });
                return real_estates.data;
            } else {
                return [];
            }
        } else if (type === 'topics') {
            const topics = strings.map((obj) => ({ id: obj, name: obj }));
            return topics;
        } else if (type === 'employees') {
            const consultants = await apiCaller.callApi('employeesByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
            return consultants.data;
        } else if (type === 'mandate') {
            const mandate = await apiCaller.callApi('mandateByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
            const sortedMandate = sortByAttribute(mandate.data, 'name');
            return sortedMandate;
        } else {
            return null;
        }
    };

    useEffect(() => {
        console.log('textFieldOptions');
        console.log(textFieldOptions);
    }, [textFieldOptions]);

    useEffect(() => {
        console.log('options');
        console.log(options);
    }, [options]);

    useEffect(() => {
        console.log('selectionItems');
        console.log(selectionItems);
    }, [selectionItems]);

    useEffect(() => {
        console.log('draftData');
        console.log(draftData);
    }, [draftData]);

    useEffect(() => {
        const fetchData = async () => {
            const id = await apiCaller.callApi('getEntity', 'GET', {}, { user_id: localStorage.getItem('id') });
            if (data[0].name === 'Gesprächsnotiz') {
                if (id.data) {
                    setDraftData({ ...draftData, ['consultant_id']: id.data });
                }
            }
        };
        fetchData();
    }, []);
    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handlePrevious = () => {
        setActiveStep((prevStep) => prevStep - 1);

    };

    const setCheckFieldValue = (question, label, value) => {
        setDraftData((prevDraftData) => {
            const fieldValue = prevDraftData[question.label] || [];

            if (value) {
                return {
                    ...prevDraftData,
                    [question.label]: [...fieldValue, label],
                };
            } else {
                return {
                    ...prevDraftData,
                    [question.label]: fieldValue.filter((option) => option !== label),
                };
            }
        });
    };


    function setFieldValue(question, value) {
        if (question.fieldType === 'textfield_multiple') {
            setDraftData((prevDraftData) => {
                const updatedFields = prevDraftData[question.label] || [];

                if (value) {
                    updatedFields.push(value);
                }
                return {
                    ...prevDraftData,
                    [question.label]: updatedFields.filter((field) => field !== ''),
                };
            });
        } else if (question.fieldType === 'area_select') {
            setDraftData((prevDraftData) => ({
                ...prevDraftData,
                [question.label]: value,
            }));
        } else if (question.fieldType === 'selection') {
            setDraftData((prevDraftData) => ({
                ...prevDraftData,
                [question.label]: value,
            }));
        } else if (question.fieldType === 'textfield') { // Add this condition for textfield
            setDraftData((prevDraftData) => ({
                ...prevDraftData,
                [question.label]: value,
            }));
        } else {
            setDraftData((prevDraftData) => ({
                ...prevDraftData,
                [question.label]: value,
            }));
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            const autoCompleteOptions = {};
            for (const question of data[activeStep]?.questions) {
                if (question.fieldType === 'textfield_multiple' && question.autocomplete) {
                    if (!loadedAutocompleteOptions[question.label]) { // Check if options are already loaded
                        const options = await loadAutoCompleteData(
                            question.autocomplete,
                            question.autocomplete_strings,
                            question.autocomplete_value,
                        );

                        autoCompleteOptions[question.label] = options;
                        setLoadedAutocompleteOptions((prevLoadedOptions) => ({
                            ...prevLoadedOptions,
                            ...autoCompleteOptions,
                        }));
                    }
                    setOptions((prevOptions) => ({
                        ...prevOptions,
                        ...autoCompleteOptions,
                    }));
                } else if (question.fieldType === 'checkbox') {
                    var options = await loadAutoCompleteData(
                        question.autocomplete,
                        question?.autocomplete_strings,
                        question?.autocomplete_value,
                    );
                    console.log('options');
                    console.log(options);
                    if (options === null & question.options !== null) {
                        options = question.options;
                    }
                    setCheckboxItems(options);
                    if (question.preSelect && (!draftData[question.label] || draftData[question.label] === 0)) {
                        if (question.autocomplete_strings) {
                            draftData[question.label] = question.autocomplete_strings;
                        }
                    }

                } else if (question.fieldType === 'selection') {
                    const options = await loadAutoCompleteData(
                        question.autocomplete,
                        question?.autocomplete_strings,
                        question?.autocomplete_value,
                    );
                    setSelectionItems(options);
                } else if (question.fieldType === 'freeSolo_textfield') {
                    const options = await loadAutoCompleteData(
                        question.autocomplete,
                        question?.autocomplete_strings,
                        question?.autocomplete_value,
                    );
                    setFreeSoloItems(options);
                } else if (question.fieldType === 'textfield') {
                    const options = await loadAutoCompleteData(
                        question.autocomplete,
                        question?.autocomplete_strings,
                        question?.autocomplete_value,
                    );
                    if (question.preValue && !draftData[question.label]) {
                        setDraftData((prevDraftData) => {
                            const updatedFields = { ...prevDraftData };
                            updatedFields[question.label] = question.preValue;
                            return updatedFields;
                        });

                    }
                    setTextFieldOptions(options);
                }
            }

        };

        fetchData();
    }, [activeStep]); // Add draftData as a dependency


    useEffect(() => {
        console.log('draftData');
        console.log(draftData);
    });


    const handleAddDynamicField = (question, index) => {
        setDraftData((prevDraftData) => {
            const updatedFields = { ...prevDraftData };
            updatedFields[question.label] = [...(updatedFields[question.label] || []), ''];
            return updatedFields;
        });
    };


    const handleDeleteDynamicField = (question, index) => {
        setDraftData((prevDraftData) => {
            const updatedFields = { ...prevDraftData };
            updatedFields[question.label].splice(index, 1);

            // Reload the options for the textfield_multiple field
            if (question.fieldType === 'textfield_multiple' && question.autocomplete) {
                loadAutoCompleteData(question.autocomplete, question.autocomplete_strings, question.autocomplete_value)
                    .then((options) => {
                        setOptions((prevOptions) => ({
                            ...prevOptions,
                            [question.label]: options,
                        }));
                    })
                    .catch((error) => {
                        console.error('Error loading autocomplete data:', error);
                    });
            }

            return updatedFields;
        });
    };

    const closeForm = async () => {
        setOpen(false);
        await loadDrafts();
        resetDraftData({});
    };


    const finalizeProcedure = async () => {
        try {
            let template = null;
            let type;
            if (stepType !== 'empty') {
                type = stepType;
            } else {
                type = draftData.type;
            }
            if (type === 'real_estate_transfer') {
                template = 'real_estate_transfer_pptx';
            } else if (type === 'memo') {
                template = 'memo_doc';
            } else if (type === 'lawyer_letter') {
                template = 'lawyer_letter_doc';
            } else if (type === 'lawyer_letter_2_0') {
                type = 'lawyer_letter';
                template = draftData?.document + '_doc';
            } else if (type === 'contract_managing_director') {
                template = 'contract_managing_director_doc';
            }
            const result = await apiCaller.callApi('saveAndUpdateDraft', 'POST', {
                draft: draftData,
                user_id: localStorage.getItem('id'),
                template: template,
                type: type,
            });
            setOpen(false);
            await loadDrafts();
            resetDraftData({});
            if (result.severity === 'success') {
                showNotification('info', 'Dokument wird generiert.');
                const result_api = await clApiCaller.callApi('create_document', 'POST', {}, { draft_id: result.data.id }, false);
                //const result_api = await clApiCaller.callApi('create_document', 'POST', {}, { draft_id: 242 });
                console.log('result_api');
                console.log(result_api);
                if (result_api?.status !== 200) {
                    showNotification('error', 'Entwurf wurde gespeichert, es konnte jedoch kein Dokument generiert werden: \u26A0\uFE0F ' + result_api.error);
                } else {
                    if (type === 'memo') {
                        await downloadDraft(result.data.id);
                    }
                    showNotification(result.severity, result.msg);
                }

            } else {
                showNotification(result.severity, result.msg);
            }
        } catch (err) {
            console.log(err);
            if (err.response.data.error) {
                showNotification('error', err.response.data.error);
            } else {
                showNotification('error', err.message);
            }
            setOpen(false);
            await loadDrafts();
        }
    };

    useEffect(() => {
        console.log('Loaded options for question label:', options);
    }, []);

    function addSuggestion(question, draftString) {
        setFieldValue(question, draftString);
    }

    async function getPreSelectedSelectionValue(question) {
        if (draftData[question.preLoadVariable] !== null && draftData[question.preLoadVariable] !== '') {
            const response = await apiCaller.callApi('getMandatManager', 'GET', {}, { mandant_id: draftData[question.preLoadVariable] });
            if (response.data[0][question.preSetVariable]) {
                console.log(response.data[0][question.preSetVariable]);
                const updatedDraftData = {
                    ...draftData,
                    [question.label]: response.data[0][question.preSetVariable],
                };
                setDraftData(updatedDraftData);
                return response.data[0][question.preSetVariable];
            } else {
                return null;
            }
        }

        return 10;
    }

    return (
        <Grid spacing={2} container justifyContent='center' alignItems='center'>
            <Grid item xs={12}
                  style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '-35px',
                      marginRight: '-35px',
                      padding: '0px',
                  }}>
                <Tooltip title={'Schließen'}>
                    <IconButton onClick={closeForm} variant='outlined'
                                color='success'>
                        <CloseSharp />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                <StepperWrapper>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {data.map((step, index) => (
                            <Step key={index} sx={{
                                '& .MuiStepLabel-root .Mui-completed': { color: '#3e697c' },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: '#3e697c',
                                    fontSize: '1.1em',// circle color (ACTIVE)
                                    transform: 'scale(1.1)',
                                    fontWeight: 'bold',
                                },
                            }}>
                                <StepLabel>{step.name}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </StepperWrapper>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '20px' }}></Grid>
            <Grid item xs={12} container justifyContent='center' alignItems='center' spacing={4}>
                {data[activeStep]?.questions?.map((question, questionIndex) => (
                        <Grid item xs={12} key={questionIndex} container
                              alignItems='center'
                              justifyContent='right'>
                            <Grid item xs={mapPercentageWidthToInt(question.size)}>

                                <Typography variant='body' style={{ padding: '1rem', fontWeight: 'bold' }}>
                                    {question.name}
                                </Typography>
                                {question.tooltip && (
                                    <Tooltip title={question.tooltip}>
                                        <InfoOutlined fontSize={'small'} />
                                    </Tooltip>
                                )}

                            </Grid>
                            {question.fieldType === 'selection' ? (
                                Array.isArray(selectionItems) && selectionItems.length > 0 ? (
                                    <Grid item xs={mapPercentageWidthToInt(question.size)}>
                                        <TextField
                                            color='success'
                                            select
                                            variant='filled'
                                            fullWidth
                                            name={question.label}
                                            value={
                                                question.preLoadVariable != null
                                                    ? (question.preLoadVariable && !draftData[question.label] ? getPreSelectedSelectionValue(question) : draftData[question.label] || '')
                                                    : (question.selfSelect && !draftData[question.label]
                                                        ? consultantEntity
                                                        : draftData[question.label] || '')
                                            }
                                            onChange={(event) => setFieldValue(question, event.target.value)}
                                        >
                                            {selectionItems.map((option, optionIndex) => (
                                                <MenuItem key={optionIndex} value={option.id}>
                                                    {`${option.title != null ? option.title : ''} ${option.first_name} ${option.name}`}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                ) : (
                                    <Typography variant='body' style={{ padding: '1rem' }}>
                                        Es wurden keine Daten gefunden.
                                    </Typography>)
                            ) : question.fieldType === 'checkbox' ? (
                                <>
                                    <Grid container spacing={2}
                                          style={{ marginTop: '15px', marginLeft: '5px' }}>
                                        {Array.isArray(checkboxItems) && checkboxItems.length > 0 ? (
                                            checkboxItems.map((option) => (
                                                <Grid item xs={6} key={option.id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color='success'
                                                                checked={draftData[question.label]?.includes(option.id) || false}
                                                                onChange={(event) => {
                                                                    const checked = event.target.checked;
                                                                    setCheckFieldValue(question, option.id, checked ? option : null);
                                                                }}
                                                            />
                                                        }
                                                        label={option.name}
                                                        name={option.name}
                                                        value={option.id}
                                                        color='success'
                                                        sx={{
                                                            '& .MuiSvgIcon-root': { fontSize: 35 },
                                                            fontWeight: 'bold',
                                                        }}
                                                    />
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography variant='body' style={{ padding: '1rem' }}>
                                                Es wurden keine Daten gefunden.
                                            </Typography>
                                        )}
                                    </Grid>
                                </>
                            ) : question.fieldType === 'textfield_multiple' ? (
                                <Grid item xs={mapPercentageWidthToInt(question.size)}>
                                    {draftData[question.label]?.map((fieldValue, index) => (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                             key={index}>
                                            <Grid item xs={11} style={{ alignItems: 'center' }}>
                                                {question.autocomplete ? (
                                                    <Autocomplete
                                                        options={options[question.label] || []}
                                                        value={options[question.label]?.find(option => option.value === fieldValue) || null}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionSelected={(option, value) => option.value === value}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                color='success'
                                                                fullWidth
                                                                margin='normal'
                                                                variant='filled'
                                                                value={fieldValue}
                                                                onChange={(event) => {
                                                                    const typedValue = event.target.value;
                                                                    const matchingOption = options[question.label].find(option =>
                                                                        option.label === typedValue,
                                                                    );

                                                                    if (matchingOption) {
                                                                        // Add the selected option value to the draftData
                                                                        setFieldValue(question, matchingOption.value);
                                                                    }
                                                                    // Ignore the typed value if it doesn't match any option
                                                                }}

                                                            />
                                                        )}
                                                        onChange={(event, value) => {
                                                            if (value) {
                                                                setFieldValue(question, value.value);
                                                            }
                                                        }}
                                                    />

                                                ) : (
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            color='success'
                                                            fullWidth
                                                            variant='filled'
                                                            margin='normal'
                                                            defaultValue={fieldValue}
                                                            onChange={(event) => {
                                                                const updatedFields = [...draftData[question.label]];
                                                                updatedFields[index] = event.target.value;
                                                                setDraftData((prevDraftData) => ({
                                                                    ...prevDraftData,
                                                                    [question.label]: updatedFields,
                                                                }));
                                                            }}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid xs={1}>
                                                <IconButton
                                                    color='error'
                                                    onClick={() => handleDeleteDynamicField(question, index)}
                                                    aria-label='Remove Field'>
                                                    <Close />
                                                </IconButton>
                                            </Grid>
                                        </div>
                                    ))}
                                    <Grid item xs={12}>
                                        <Button
                                            variant='outlined'
                                            color='success'
                                            onClick={() => handleAddDynamicField(question, questionIndex)}
                                        >
                                            Hinzufügen
                                        </Button>
                                    </Grid>
                                    {
                                        question.suggestions && (
                                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                                <Typography variant='body2'>
                                                    Vorschläge <Typography variant='caption'>(Bitten anklicken)</Typography>
                                                </Typography>
                                                {question.suggestions.formFields ? (
                                                    <>
                                                        {question.suggestions.formFields.map((field, index) => (
                                                            draftData[field] && draftData[question.label] && !draftData[question.label].includes(draftData[field]) ? (
                                                                <Button variant='outlined' key={index}
                                                                        style={{ marginRight: '5px' }}
                                                                        onClick={() => addSuggestion(question, draftData[field])}
                                                                >{draftData[field]}</Button>
                                                            ) : null

                                                        ))}
                                                    </>
                                                ) : null}

                                                {question.suggestions.requests ?
                                                    <>
                                                        {question.suggestions.requests.map((field, index) => (
                                                            <Button variant='outlined' key={index} endIcon={<AccountBalance />}
                                                                    style={{ marginRight: '5px' }}>{field}</Button>
                                                        ))}
                                                    </>
                                                    : null}
                                            </Grid>
                                        )
                                    }
                                </Grid>

                            ) : question.fieldType === 'multiline_textfield' ? (
                                <>
                                    <Grid item xs={mapPercentageWidthToInt(question.size)}
                                          style={{ border: '1px solid lightgray' }}>
                                        <TextField
                                            fullWidth
                                            variant='filled'
                                            multiline
                                            value={draftData[question.label] || ''}
                                            onChange={(event) => setFieldValue(question, event.target.value)}
                                            rows={question.rows ? question.rows : null}
                                            minRows={question.minRows ? question.minRows : null}
                                            color='success'
                                        />
                                    </Grid>
                                </>
                            ) : question.fieldType === 'freeSolo_textfield' && question.autocomplete && freeSoloItems.length > 0 ? (
                                <>
                                    <Grid item xs={mapPercentageWidthToInt(question.size)}
                                          style={{ marginTop: '10px' }}>
                                        <Autocomplete
                                            freeSolo
                                            options={freeSoloItems.map((option) => ({
                                                id: question.autocomplete === 'clients' || question.autocomplete === 'allClients' || question.autocomplete === 'companies' ? option.value : option.id,
                                                label: question.autocomplete === 'clients' || question.autocomplete === 'allClients' || question.autocomplete === 'companies' ? option.label : `${option.title ? option.title + ' ' : ''}${option.first_name} ${option.name}`,
                                            }))}
                                            //options={freeSoloItems.map((option) => `${option.title ? option.title + ' ' : ''}${option.first_name} ${option.name}`) || []}
                                            value={draftData[question.label] || ''}

                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setFieldValue(question, newValue.label);
                                                    if (newValue.id) {
                                                        setDraftData((prevDraftData) => ({
                                                            ...prevDraftData,
                                                            [question.id_label_name ? question.id_label_name : question.label + '_id']: newValue.id,
                                                        }));
                                                        setMandateID(newValue.id);
                                                        setPreviousMandate(newValue.label);
                                                    }
                                                } else {
                                                    setMandateID(null);
                                                    setFieldValue(question, null);
                                                }
                                            }}
                                            onInputChange={(event) => {
                                                if (event && event.target) {
                                                    setFieldValue(question, event.target.value);
                                                    setMandateID(null);
                                                    setDraftData((prevDraftData) => ({
                                                        ...prevDraftData,
                                                        [question.label + '_id']: null,
                                                    }));
                                                    setPreviousMandate(event.target.value);

                                                }
                                            }
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    color='success'
                                                    variant='filled'
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>

                            ) : question.fieldType === 'DateTimePicker' ? (
                                <>

                                    {question.defaultTime && draftData[question.label] === undefined && setFieldValue(question, dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}
                                    {question.timePoint === 'next_month_first' && draftData[question.label] === undefined && setFieldValue(question, dayjs().add(1, 'month').startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}
                                    <Grid
                                        container
                                        xs={mapPercentageWidthToInt(question.size)}
                                        alignItems='center'
                                        justifyContent='flex-end'

                                    >
                                        {question.setTimeButton &&
                                            <Grid item xs={2}>
                                                <Button color='success'
                                                        variant='contained'
                                                        style={{ marginLeft: '-10px' }}
                                                        onClick={() => setFieldValue(question, dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'))}>{question.setTimeButton}</Button>
                                            </Grid>
                                        }
                                        <Grid item xs={question.setTimeButton ? 10 : 12}>
                                            <DesktopDateTimePicker
                                                style={{ width: '100px' }}
                                                color='success'
                                                variant={question.variant ? question.variant : 'filled'}
                                                value={
                                                    draftData[question.label]
                                                        ? dayjs(draftData[question.label]).tz(localTimeZone)
                                                        : null
                                                }
                                                onChange={(newValue) => {
                                                    const formattedDate = newValue.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                                                    setFieldValue(question, formattedDate);
                                                }}
                                                format={question.format || 'DD.MM.YYYY HH:mm'}
                                                ampm={false}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        variant: question.variant ? question.variant : 'filled',
                                                        helperText: question.helperText ? question.helperText : '',
                                                        color: 'success',
                                                    },
                                                }}
                                            />

                                        </Grid>
                                    </Grid>
                                </>
                            ) : question.fieldType === 'button_select' ? (
                                <>
                                    <Grid container justifyContent='flex-end' style={{ marginBottom: '50px' }}>
                                        <ButtonSelect draftData={draftData} setDraftData={setDraftData} question={question}
                                                      attributeName={question.label}
                                                      setSelectedButtonFocus={setSelectedButtonFocus}
                                                      selectedButtonFocus={selectedButtonFocus} />
                                    </Grid>
                                </>

                            ) : question.fieldType === 'button_multi_select' ? (
                                <ButtonMultiSelect draftData={draftData} setDraftData={setDraftData} question={question}
                                                   attributeName='inter' setFieldValue={setFieldValue}
                                                   mandateID={mandateID} />

                            ) : question.fieldType === 'component_loader' ? (
                                question.component === 'newContact' ? (
                                    <NewContact data={draftData} setDraftData={setDraftData} question={question}
                                                setTextFieldOptions={setTextFieldOptions} />
                                ) : null
                            ) : question.fieldType === 'immo_stepper' ? (
                                <ImmoStepper />
                            ) : question.fieldType === 'lawyer_letter_stepper' ?
                                (
                                    <LawyerLetterStepper draftData={draftData} setDraftData={setDraftData} />
                                ) : question.fieldType === 'receiver_tabs' ?
                                    (
                                        <ReceiverTabs setDraftData={setDraftData} draftData={draftData}
                                                      question={question} />
                                    ) : (
                                        <>
                                            <Grid item xs={mapPercentageWidthToInt(question.size)}
                                                  style={{ marginTop: '10px' }}>
                                                {question.autocomplete ? (
                                                    <Autocomplete
                                                        color='success'
                                                        fullWidth
                                                        value={
                                                            Array.isArray(textFieldOptions)
                                                                ? textFieldOptions.find((option) => option.value === draftData[question.label]) || null
                                                                : null
                                                        }
                                                        options={Array.isArray(textFieldOptions) ? textFieldOptions.map((option) => ({
                                                            id: option.value,
                                                            label: option.label,
                                                        })) : []}
                                                        getOptionLabel={(option) => option.label}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant='filled'
                                                                style={{ marginTop: '10px' }}
                                                            />
                                                        )}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                setFieldValue(question, newValue.id); // Store the option.value (id) in draftData
                                                            } else {
                                                                setFieldValue(question, ''); // Handle the case when nothing is selected
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <TextField
                                                        color='success'
                                                        fullWidth
                                                        helperText={question.helperText || null}
                                                        label={false}
                                                        size='small'
                                                        id='outlined-basic'
                                                        value={draftData[question.label]}
                                                        variant={question.variant || 'filled'}
                                                        style={{ marginTop: '10px' }}
                                                        InputProps={
                                                            question.formatType === 'currency_int'
                                                                ? { inputComponent: NumericIntegerCurrency }
                                                                : undefined
                                                        }
                                                        onChange={(event) => {
                                                            setFieldValue(question, event.target.value);
                                                        }
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                        </>
                                    )}
                        </Grid>
                    ),
                )}
            </Grid>
            <Grid
                item
                xs={12}
                container
                justifyContent='space-between'
                alignItems='center'
                style={{ marginTop: '2rem' }}>
                <Grid item>
                    {activeStep !== 0 ? (
                        <Button disabled={activeStep === 0} onClick={handlePrevious} variant='outlined'
                                color='success'>
                            Zurück
                        </Button>
                    ) : (
                        <Button onClick={closeForm} variant='outlined'
                                color='success'>
                            Schließen
                        </Button>
                    )
                    }
                </Grid>
                <Grid item>
                    {activeStep < data.length - 1 && (
                        <Button onClick={handleNext} variant='contained' color='success'>
                            Weiter
                        </Button>
                    )}
                    {activeStep === data.length - 1 && (
                        <Button onClick={finalizeProcedure} variant='contained' color='success'>
                            {data[0].name === 'Gesprächsnotiz' ? 'Dokument Generieren' : 'Speichern'}
                        </Button>
                    )}
                </Grid>

            </Grid>
            {
                showAnimation && (
                    <Box className='box'>
                        <Alert
                            variant='body1'
                            style={{
                                position: 'fixed',
                                bottom: '10%',
                                left: '-100%',
                                transform: 'translateX(-100%)',
                                transition: 'transform 0.5s ease-out',
                                zIndex: 99999,
                            }}>
                            <CircularProgress color='success' />
                            Dokument wird generiert
                        </Alert>
                    </Box>
                )
            }
        </Grid>
    );
};

const DraftStepper = (props) => {
    const findDraftData = (type, draftTemplates) => {
        if (type === 'real_estate_transfer') {
            return draftTemplates.real_estate_transfer;
        } else if (type === 'memo') {
            return draftTemplates.memo;
        } else if (type === 'lawyer_letter') {
            return draftTemplates.lawyer_letter_2_0;
        } else if (type === 'real_estate_transfer_2_0') {
            return draftTemplates.real_estate_transfer_2_0;
        } else if (type === 'contract_managing_director') {
            return draftTemplates.contract_managing_director;
        } else if (type === 'lawyer_letter_2_0') {
            return draftTemplates.lawyer_letter_2_0;
        } else {
            return [];
        }
    };

    const selectDraft = (type, draftTemplates) => {
        if ('type' in props.draftData) {
            return findDraftData(props.draftData.type, draftTemplates);
        } else {
            return findDraftData(props.type, draftTemplates);
        }
    };
    const data = selectDraft(props.type, draftTemplates);
    return (
        <div>
            <StepperForm data={data} draft={props.draftData} setOpen={props.setOpen} loadDrafts={props.loadDrafts}
                         resetDraftData={props.setDraftData} type_step={props.type} />
        </div>
    );
};

export default DraftStepper;

