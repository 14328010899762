import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import Anschrift from './formComponents/anschrift.js';
import Personendaten from './formComponents/personendaten.js';
import TaxCompliance from './formComponents/taxCompliance.js';
import Fokus from './formComponents/fokus.js';
import Assets from './formComponents/assets.js';
import Relation from './formComponents/relations.js';
import Bank from './formComponents/bank.js';
import Lead from './formComponents/lead.js';
import { CmxDivider } from './formComponents/utils.js';
import Finanzamt from './formComponents/finanzamt.js';
import Contact from './formComponents/contact.js';
import Internals from './formComponents/internals.js';
import { ExpandCircleDown } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledAccordion = styled(Accordion)({
    boxShadow: 'none', // Remove the drop shadow
});

const StyledAccordionSummary = styled(AccordionSummary)({
    display: 'flex',
    alignItems: 'flex-end', // Align items to the bottom
});

const StyledCmxDivider = styled(CmxDivider)({
    flex: 1, // Make the divider expand to fill available space
});

const StyledExpandIcon = styled(ExpandCircleDown)({
    marginLeft: 'auto',
    marginBottom: '10px', // Move the expand icon to the right
});


const Person = (props) => {
    return (
        <>
            <div style={{ padding: '20px' }}>
                <Grid container spacing={2} justify='center' style={{ marginBottom: '25px' }}>
                    {/******************************************Category Divider****************************************/}
                    {/***********************************************+++++++++++****************************************/}

                    {/***********************************************Personendaten****************************************/}

                    <Grid item xs={12}>
                        <CmxDivider title='Personendaten' />
                    </Grid>
                    <Personendaten formData={props.formData} handleInputChange={props.handleInputChange}
                                   group='userData' />

                    {/***********************************************Kontaktdaten****************************************/}
                    <Grid item xs={12}>
                        <CmxDivider title='Kontaktdaten' />
                    </Grid>
                    <Anschrift formData={props.formData} handleInputChange={props.handleInputChange} />
                    <Contact formData={props.formData} handleInputChange={props.handleInputChange} />

                    {/***********************************************Bankkonto****************************************/}
                    <Grid item xs={12}>
                        <Divider className='custom-divider'>
                            <Typography variant='h6'>Bankverbindung</Typography>
                        </Divider>
                    </Grid>
                    <Bank formData={props.formData} handleInputChange={props.handleInputChange} />

                    {/******************************************Category Divider****************************************/}
                    {/***********************************************+++++++++++****************************************/}
                    <CmxDivider size='large' margin={50} />


                    {/***********************************************Steuerdaten****************************************/}
                    <CmxDivider title='Steuerdaten' />
                    <Finanzamt formData={props.formData} handleInputChange={props.handleInputChange} />

                    {/****************************************Bezugspersonen****************************************/}
                    <CmxDivider title='Beziehungen' />
                    <Relation formData={props.formData} handleInputChange={props.handleInputChange} />

                    {/***********************************************Assets****************************************/}
                    <CmxDivider title='Assets' />
                    <Assets formData={props.formData} handleInputChange={props.handleInputChange} />


                    {/*****************************************Tax Compliance****************************************/}
                    <CmxDivider title='Tax Compliance' />
                    <TaxCompliance formData={props.formData} handleInputChange={props.handleInputChange} />

                    {/******************************************Category Divider****************************************/}
                    {/***********************************************+++++++++++****************************************/}
                    <CmxDivider size='large' margin={50} />

                    {/***********************************************Themenschwerpunkte****************************************/}
                    <CmxDivider title='Themenschwerpunkte' />
                    <Fokus formData={props.formData} handleInputChange={props.handleInputChange} />

                    {/***********************************************Lead****************************************/}
                    <CmxDivider title='Lead' />
                    <Lead formData={props.formData} handleInputChange={props.handleInputChange} />

                    {/*********************************************** Kanzleiinterne Informationen ****************************************/}
                    <Grid item xs={12}>
                        <StyledAccordion>
                            <StyledAccordionSummary
                                expandIcon={<StyledExpandIcon color='success' fontSize='large' />}
                            >
                                <CmxDivider title='Kanzleiinterne Informationen' xsWidth={12} />
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Internals formData={props.formData} handleInputChange={props.handleInputChange} />
                                </Grid>
                            </AccordionDetails>
                        </StyledAccordion>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
export default Person;

