import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
    Accordion,
    AccordionSummary,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import companyRolesTypes from '../../../../misc/auto_data/companyRolesTypes.js';
import AddClient from './AddClient.js';
import { Close } from '@mui/icons-material';

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const Associates = (props) => {
    const [role, setRole] = useState('');
    const [removeIndex, setRemoveIndex] = useState(0);

    const handleRemove = (index) => {
        setRemoveIndex(index);
    };

    return (
        <>
            <Grid container xs={12}>
                {
                    props.formData.associates &&
                    props.formData.associates.map((item, index) => (
                        <Grid item xs={12} key={index}>
                            <Accordion
                                style={{ backgroundColor: 'whitesmoke' }}
                                key={index}
                                color='success'
                                variant='filled'
                            >
                                <StyledAccordionSummary>
                                    <Typography variant='body1'>
                                        {/*<b>{companyRolesTypes.find(item => item.label === props.formData.associates[index].asset.role)?.name}</b>{': '}*/}
                                        {props.formData.associates[index].type === 'client' || props.formData.associates[index].type === 'person' ? (
                                            (props.formData.associates[index].gender ? (props.formData.associates[index].gender === 'male' ? 'Herr' : 'Frau') + ' ' : '') +
                                            (props.formData.associates[index].title ? props.formData.associates[index].title + ' ' : '') +
                                            (props.formData.associates[index].first_name ? props.formData.associates[index].first_name + ' ' : '') +
                                            (props.formData.associates[index].name ? props.formData.associates[index].name : '')
                                        ) : (
                                            (props.formData.associates[index].name_full ? props.formData.associates[index].name_full + ' ' : '') +
                                            (props.formData.associates[index].name ? '(' + props.formData.associates[index].name + ')' : '')
                                        )}
                                        {', ' + companyRolesTypes.find(item => item.label === props.formData.associates[index].asset.role)?.name}
                                        {(props.formData.associates[index]?.asset?.shares ? ' (' + props.formData.associates[index]?.asset?.shares + '%)' : '')}

                                    </Typography>
                                    <IconButton
                                        color='success'
                                        aria-label='Remove'
                                        onClick={() => handleRemove(index)}
                                    >
                                        <Close />
                                    </IconButton>
                                </StyledAccordionSummary>

                            </Accordion>
                        </Grid>
                    ))
                }
            </Grid>
            {props?.formData?.entity?.type &&
                <Grid item xs={12} className='box'>
                    <Typography variant='h6'>Beteiligten hinzufügen</Typography>
                    <FormControl variant='filled' color='success' fullWidth
                                 style={{ marginTop: '15px' }}>
                        <InputLabel>Rolle</InputLabel>
                        <Select
                            value={role}
                            name='role'
                            onChange={(event) => setRole(event.target.value)}
                        >
                            {
                                companyRolesTypes.map((item, index) => {
                                    if (item.user.includes(props.formData?.entity?.type)) {
                                        return (
                                            <MenuItem key={index}
                                                      value={item.label}>{item.name}</MenuItem>
                                        );
                                    }
                                })}
                            <MenuItem key={-1} value={null}>Keine</MenuItem>
                        </Select>
                    </FormControl>

                    {

                        <AddClient role={role} data={props.formData} handleInputChange={props.handleInputChange}
                                   setRole={setRole} removeIndex={removeIndex} setRemoveIndex={setRemoveIndex} />

                    }
                </Grid>
            }
        </>
    );
};
export default Associates;
