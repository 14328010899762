import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FormGroup } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { focusTypes } from '../../../../misc/auto_data/foucsTypes.js';

const Fokus = (props) => {
    const [selectedFocus, setSelectedFocus] = useState(props.formData?.focus ? props.formData.focus : []);

    useEffect(() => {
        setSelectedFocus(props.formData?.focus ? props.formData.focus : []);
    }, [props.formData.focus]);
    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        const selectedIndex = selectedFocus.indexOf(value);
        let newSelectedFocus = [];

        if (selectedIndex === -1) {
            newSelectedFocus = [...selectedFocus, value];
        } else {
            selectedFocus.splice(selectedIndex, 1);
            newSelectedFocus = selectedFocus;
        }
        setSelectedFocus(newSelectedFocus);

        props.handleInputChange({
            target: {
                name: 'focus',
                value: newSelectedFocus,
            },
        });
    };

    return (
        <Grid item xs={12}>
            <Box textAlign='center'>
                <FormGroup
                    sx={{ width: '100%' }}
                    name='focus'
                    value={setSelectedFocus}
                    onChange={handleCheckboxChange}
                >
                    {focusTypes.map((item, index) => {
                        const isChecked = selectedFocus.includes(item.name);
                        return (
                            <FormControlLabel
                                control={<Checkbox color='success' checked={isChecked} />}
                                key={index}
                                label={item.name}
                                value={item.name}
                                color='success'
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 35 }, fontWeight: 'bold' }}
                            />
                        );
                    })}
                </FormGroup>
            </Box>
        </Grid>
    );
};
export default Fokus;
