import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <Container>
            <div className='footer'>
                {/*<Link disabled to='/about'>About</Link>
                <Link to='/AGBs'>AGBs</Link>*/}
                <Link to='/Impressum'>Impressum</Link>
            </div>
        </Container>
    );
}

export default Footer;
