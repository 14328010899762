import {
    AccountBalance,
    AddBusiness,
    AlternateEmail,
    ArticleRounded,
    Diversity3,
    Fax,
    GpsFixed,
    GpsOff,
    LocationCity,
    Mail,
    ManageAccounts,
    Person4,
    Phone,
    ThumbDown,
    ThumbUp,
    VideoCall,
} from '@mui/icons-material';
import React from 'react';
import { Mailbox } from 'react-bootstrap-icons';

export const draftTemplates = {
    real_estate_transfer: [
        /*{
            name: 'Name',
            questions: [
                {
                    name: 'Geben Sie einen Namen für den Entwurf ein.',
                    fieldType: 'textfield',
                    size: 60,
                    label: 'name',
                },
            ],
        },*/
        {
            name: 'Personen',
            questions: [
                {
                    name: 'Wer schenkt?',
                    fieldType: 'textfield_multiple',
                    size: 50,
                    label: 'giver',
                    autocomplete: 'real_estate_owner',
                    autocomplete_strings: ['title', 'first_name', 'last_name'],
                    autocomplete_value: ['id'],
                },
                {
                    name: 'Wer wird beschenkt?',
                    fieldType: 'textfield_multiple',
                    size: 50,
                    label: 'receiver',
                    multiple: true,
                    autocomplete: 'clients',
                    autocomplete_strings: ['title', 'first_name', 'last_name'],
                    autocomplete_value: ['id'],
                },
            ],
        },
        {
            name: 'Immobilie',
            questions: [
                {
                    name: 'Welche Immobilien sollen umfasst werden?',
                    fieldType: 'checkbox',
                    autocomplete: 'real_estates',
                    options: ['Haus A', 'Haus B', 'Haus C'],
                    size: 100,
                    label: 'real_estates',
                },
            ],
        },
        {
            name: 'Inhalte',
            questions: [
                {
                    name: 'Welche Inhalte sollen angezeigt werden?',
                    fieldType: 'checkbox',
                    autocomplete: 'topics',
                    autocomplete_strings: [
                        'Zusammenfassung',
                        'Sachverhalt und Zielsetzung',
                        'Familienkonstellation',
                        'Bestandsvermögen',
                        'Schenkungslösung',
                        'Verkaufslösung',
                        'Gesellschaftslösung',
                        'Stiftungslösung',
                    ],
                    size: 100,
                    label: 'topics',
                    preSelect: true,
                },
            ],
        },
        {
            name: 'Bearbeiter',
            questions: [
                {
                    name: 'Wer ist der erste Bearbeiter?',
                    fieldType: 'selection',
                    autocomplete: 'employees',
                    size: 50,
                    label: 'consultant_1',
                },
                {
                    name: 'Wer ist der zweite Bearbeiter?',
                    fieldType: 'selection',
                    autocomplete: 'employees',
                    size: 50,
                    label: 'consultant_2',
                },
            ],
        },
    ],
    memo: [
        {
            name: 'Gesprächsnotiz',
            questions: [
                {
                    fieldType: 'DateTimePicker',
                    size: 20,
                    label: 'time_start',
                    defaultTime: true,
                    variant: 'standard',
                    helperText: 'Gesprächsbeginn',
                },
                {
                    name: 'Mandant',
                    fieldType: 'freeSolo_textfield',
                    autocomplete: 'allClients',
                    //autocomplete: 'clients',
                    size: 50,
                    label: 'mandate',
                },
                /*  {
                      name: 'Gesprächsteilnehmer',
                      fieldType: 'textfield_multiple',
                      size: 50,
                      label: 'interlocutors',
                      multiple: true,
                      suggestions: { formFields: ['mandate'], requests: ['financeAuthority'], target: 'interlocutors' },
                  },*/
                {
                    name: 'Gesprächsteilnehmer',
                    fieldType: 'button_multi_select',
                    size: 100,
                    label: 'interlocutors',
                    dependency: 'mandate',
                    preLoad: [
                        { name: 'mandate', type: 'formField', select: false, icon: <Person4 />, tooltip: 'Mandant' },
                        {
                            name: 'consultant',
                            type: 'request',
                            preSelect: true,
                            icon: <ManageAccounts />,
                            tooltip: 'Sie',
                        },
                        {
                            name: 'management',
                            type: 'request',
                            preSelect: false,
                            icon: <ManageAccounts />,
                            tooltip: 'Berater',
                        },
                        {
                            name: 'financeAuthority',
                            type: 'request',
                            preSelect: false,
                            icon: <AccountBalance />,
                            tooltip: 'Finanzbeamter',
                        },
                        {
                            name: 'relatives',
                            type: 'request',
                            preSelect: false,
                            icon: <Diversity3 />,
                            tooltip: 'Verwandter',
                        },
                        {
                            name: 'company_associates',
                            type: 'request',
                            preSelect: false,
                            icon: <AddBusiness />,
                            tooltip: 'Unternehmensbeteiligter',
                        },
                    ],
                },
                {
                    name: 'Gesprächsart',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Vor Ort', 'Videocall', 'Telefonat'],
                    icons: [{ icon: <LocationCity /> }, { icon: <VideoCall /> }, { icon: <Phone /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'conversational_type',
                    children:
                        [{ type: 'textfield', label: 'Ort' },
                            { type: 'textfield', label: 'Plattform' },
                            { type: 'textfield', label: 'Telefonnummer' }],
                },
                {
                    name: 'Thema',
                    fieldType: 'textfield',
                    size: 50,
                    label: 'topic',
                },
                {
                    name: 'Aktenzeichen',
                    fieldType: 'textfield',
                    size: 50,
                    label: 'reference_number',
                },
                {
                    name: 'Notizen',
                    fieldType: 'multiline_textfield',
                    size: 100,
                    //rows: 5,
                    minRows: 5,
                    label: 'notes',
                },
                {
                    name: 'Aufgaben',
                    fieldType: 'multiline_textfield',
                    size: 100,
                    rows: 5,
                    label: 'tasks',
                },
                {
                    name: 'Verantwortlicher',
                    fieldType: 'selection',
                    autocomplete: 'employees',
                    size: 50,
                    label: 'consultant_id',
                    selfSelect: true,
                },
                {
                    name: 'Gesprächsende',
                    fieldType: 'DateTimePicker',
                    size: 50,
                    setTimeButton: 'Jetzt',
                    label: 'time_end',
                },

            ],
        },
    ],

    /* lawyer_letter: [
         {
             name: 'Kontakt',
             questions: [
                 {
                     name: 'Für wen soll das Schreiben erstellt werden?',
                     fieldType: 'textfield',
                     size: 50,
                     label: 'client_name',
                     autocomplete: 'clients',
                     autocomplete_strings: ['title', 'first_name', 'last_name'],
                     autocomplete_value: 'id',
                     id_label_name: 'client',
                 },
                 {
                     button_text: 'Neuer Kontakt',
                     size: 100,
                     fieldType: 'component_loader',
                     component: 'newContact',
                     setValues: ['client'],
                 },
             ],
         },
         {
             name: 'Empfänger',
             questions: [
                 {
                     name: 'Empfänger',
                     fieldType: 'button_select',
                     label: 'destination_address',
                     autocomplete: 'topics',
                     autocomplete_strings: ['Finanzamt', 'Bestehender Kontakt', 'Addresseingabe'],
                     icons: [{
                         icon: <AccountBalance />,
                     }, { icon: <Home /> }, { icon: <Contacts /> }, {
                         icon: <AltRoute />,
                     }],
                     singleCheck: true,
                     size: 100,
                     children:
                         [
                             { type: 'taxAuthority', label: 'Addresse Eingeben', preLoadVariable: 'client' },
                             { type: 'contact', label: 'Name', return: 'string' },
                             { type: 'freeAddress', label: 'Kontakt eintragen' },
                         ],
                 },
             ],
         },
         {
             name: 'Betreff',
             questions: [
                 {
                     name: 'Betreff',
                     fieldType: 'textfield',
                     size: 50,
                     label: 'subject',
                 },
                 {
                     name: 'Anrede',
                     fieldType: 'button_select',
                     autocomplete: 'topics',
                     buttonWidth: '230px',
                     autocomplete_strings: ['Kontakt Finanzamt', 'Anrede eingeben', 'Sehr geehrte Damen und Herren'],
                     icons: [{ icon: <PeopleAlt /> }, {
                         icon: < MotionPhotosAuto />,
                     }, {
                         icon: <Groups3 />,
                     }],
                     singleCheck: true,
                     size: 100,
                     label: 'salutation',
                     children:
                         [
                             {
                                 optionalRendering: true,
                                 dependentVariable: 'client',
                                 requestedInformation: 'taxAuthorityContactPerson',
                                 type: 'taxContactPerson',
                                 singleText: true,
                             }, {
                             type: 'textfieldNewLine',
                             label: 'Anrede eingeben (z.B. Sehr geehrter Herr Meier)',
                             singleText: true,
                         }],
                 },
             ],
         },
         {
             name: 'Bearbeiter',
             questions: [
                 {
                     name: 'Wer ist der erste Bearbeiter?',
                     fieldType: 'selection',
                     autocomplete: 'employees',
                     size: 50,
                     label: 'consultant_1',
                     preLoadVariable: 'client',
                     preSetVariable: 'mandant_lead',
                 },
                 {
                     name: 'Wer ist der zweite Bearbeiter?',
                     fieldType: 'selection',
                     autocomplete: 'employees',
                     size: 50,
                     label: 'consultant_2',
                     preLoadVariable: 'client',
                     preSetVariable: 'mandant_manager',

                 },
             ],
         },
         {
             name: 'Versandart',
             questions: [
                 {
                     name: 'Versandmethode',
                     fieldType: 'button_select',
                     autocomplete: 'topics',
                     autocomplete_strings: ['beA', 'beSt', 'Post', 'Fax', 'E-Mail'],
                     icons: [{ icon: <Mailbox /> }, { icon: <Mailbox /> }, {
                         icon: <Mail />,
                     }, {
                         icon: <Fax />,
                     }, { icon: <AlternateEmail /> }],
                     singleCheck: true,
                     size: 100,
                     label: 'delivery_method',
                     children:
                         [{}, {}, {},
                             { type: 'textfieldNewLine', label: 'Faxnummer' },
                             { type: 'textfieldNewLine', label: 'Email' }],
                 },
             ],
         },
     ],*/
    real_estate_transfer_2_0: [
        {
            name: 'Immobilienübertragung 2.0',
            questions: [
                {
                    fieldType: 'immo_stepper',
                    size: 50,
                    label: 'client',
                    autocomplete: 'clients',
                    autocomplete_strings: ['title', 'first_name', 'last_name'],
                    autocomplete_value: 'id',
                },
            ],
        },
    ],
    contract_managing_director: [
        {
            name: 'Geschäftsführeranstellungsvertrag',
            questions: [
                {
                    name: 'Gesellschaft',
                    fieldType: 'freeSolo_textfield',
                    autocomplete: 'companies',
                    size: 50,
                    label: 'Party1',
                },
                {
                    name: 'Geschäftsführer der Gesellschaft',
                    fieldType: 'button_multi_select',
                    size: 100,
                    label: 'Party1_GF_fullName',
                    addLabel: 'Person hinzufügen',
                    singleChoice: true,
                    height: 50,
                    dependency: 'Party1',
                    preLoad: [
                        {
                            name: 'company_associates',
                            type: 'request',
                            preSelect: false,
                            icon: <AddBusiness />,
                            tooltip: 'Unternehmensbeteiligter',
                        },
                    ],
                },

                {
                    name: 'Einzustellender Geschäftsführer',
                    fieldType: 'button_multi_select',
                    size: 100,
                    addLabel: 'Person hinzufügen',
                    label: 'Party2',
                    dependency: 'Party1',
                    preLoad: [
                        {
                            name: 'company_associates',
                            type: 'request',
                            preSelect: false,
                            icon: <AddBusiness />,
                            tooltip: 'Unternehmensbeteiligter',
                        },
                    ],
                },
                {
                    name: 'Anschrift einzugestellender Geschäftsführer',
                    fieldType: 'textfield',
                    size: 50,
                    addLabel: 'Person hinzufügen',
                    label: 'Party2_address',
                },

                {
                    name: 'Beginn des Anstellungsverhältnisses',
                    fieldType: 'DateTimePicker',
                    size: 50,
                    format: 'DD.MM.YYYY',
                    label: 'start_date',
                    timePoint: 'next_month_first',
                    variant: 'standard',
                },
                {
                    name: 'Gründungsgeschäftsführer',
                    fieldType: 'button_select',
                    preSelect: 'Ja',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'founding_md',
                    children: [{ type: 'valueSetter', value: 'mit Gründung' }, {
                        type: 'datePicker',
                        label: 'Zeitpunkt der Einstellung',
                        stringFormating: 'DD.MM.YYYY',
                        prefix: 'am ',
                    }],
                },
                {
                    name: 'Befreit von § 181 BGB',
                    preSelect: 'Ja',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'exempted_181BGB',
                    tooltip: 'Nach § 181 BGB kann ein Vertreter nur dann im Namen des Vertretenen mit sich im eigenen Namen oder als Vertreter eines Dritten ein Rechtsgeschäft vornehmen, wenn ihm dies ausdrücklich gestattet ist.',
                    children: [],
                },
                {
                    name: 'Alleinvertretungsvollmacht',
                    preSelect: 'Ja',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'sole_power',
                    children: [],
                },
                {
                    name: 'Der Geschäftsführer kann entscheiden, ob er weitere Funktionen bei anderen Gesellschaften übernimmt',
                    fieldType: 'button_select',
                    preSelect: 'Ja',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'additional_tasks',
                    children: [],
                },
                {
                    name: 'Regelungen zu Renteneintritt u.a.',
                    preSelect: 'Nein',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'end_of_employment',
                    children: [],
                },
                {
                    name: 'Vorgaben zu Arbeitszeit und Arbeitsort',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    preSelect: 'Nein',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'working_hours',
                    children: [],
                },
                {
                    name: 'Abführung von Sozialabgaben',
                    fieldType: 'button_select',
                    preSelect: 'Nein',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'social_contributions',
                    children: [],
                },

                {
                    name: 'Entgeltfortzahlung im Krankheitsfall',
                    fieldType: 'button_select',
                    preSelect: 'Nein',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'continued_remuneration',
                    children: [],
                },
                {
                    name: 'Nebentätigkeitsgenehmigungserfordernis',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    preSelect: 'Nein',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'side_hustle',
                    children: [],
                },
                {
                    name: 'Zuständigkeit der Arbeitsgerichte',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    preSelect: 'Nein',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'labor_courts',
                    children: [],
                },
                {
                    name: 'Nachträgliches Wettbewerbsverbot und Regelungen zu Diensterfindungen',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Ja', 'Nein'],
                    preSelect: 'Nein',
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'subsequent_ncc',
                    children: [],
                },
                {
                    name: 'Umfang der geschuldeten Arbeitskraft',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    preSelect: 'Kein fester Umfang',
                    autocomplete_strings: ['Kein fester Umfang', 'Gesamte Arbeitskraft'],
                    icons: [{ icon: <GpsOff /> }, { icon: <GpsFixed /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'scope_of_work',
                    children: [],
                },
                {
                    name: 'Verfügungsrahmen bei Darlehensaufnahme etc.',
                    fieldType: 'textfield',
                    size: 50,
                    label: 'power_amount',
                    preValue: 100000,
                    formatType: 'currency_int',
                    helperText: 'Standard sind EUR 100.000,00',
                },
                {
                    name: 'Verfügungsrahmen bei sonstigen Geschäften',
                    fieldType: 'textfield',
                    size: 50,
                    label: 'power_amount2',
                    preValue: 100000,
                    formatType: 'currency_int',
                    helperText: 'Standard sind EUR 100.000,00',
                },
                {
                    name: 'Jahresgehalt',
                    fieldType: 'textfield',
                    size: 50,
                    label: 'income_value',
                    preValue: 75000,
                    formatType: 'currency_int',
                    helperText: 'Standard sind EUR 75.000,00',
                },
                {
                    name: 'Maximaler Bruttokaufpreis Dienstwagen',
                    fieldType: 'textfield',
                    size: 50,
                    label: 'car_total_cost',
                    preValue: 100000,
                    formatType: 'currency_int',
                    helperText: 'Standard sind EUR 100.000,00',
                },
                {
                    name: 'Urlaubstage',
                    fieldType: 'textfield',
                    size: 50,
                    label: 'vacation_days',
                    preValue: 30,
                    formatType: 'currency_int',
                    helperText: 'Standard 30',
                },
                {
                    name: 'Vertrauensurlaub des Geschäftsführers',
                    fieldType: 'button_select',
                    preSelect: 'Ja',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Ja', 'Nein'],
                    icons: [{ icon: <ThumbUp /> }, { icon: <ThumbDown /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'vacation_terms',
                    children: [],
                },
            ],
        },
    ],
    lawyer_letter_2_0: [
        {
            name: 'Schriftsatz 2.0',
            questions: [
                {
                    name: 'Mandant',
                    fieldType: 'freeSolo_textfield',
                    autocomplete: 'allClients',
                    //autocomplete: 'clients',
                    size: 50,
                    label: 'client_name',
                    id_label_name: 'client',
                },
                {
                    name: 'Empfänger',
                    fieldType: 'receiver_tabs',
                    dependency: 'client',
                    size: 100,
                    label: 'receiver',
                },

                {
                    name: 'Dokument',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    autocomplete_strings: ['SFA_Fristverlängerung', 'SFA_Vertretungsanzeige'],
                    icons: [{ icon: <ArticleRounded /> }, { icon: <ArticleRounded /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'document',
                    children: [],
                },
                {
                    fieldType: 'lawyer_letter_stepper',
                    size: 100,
                },
                {
                    name: 'Verfasser 1',
                    fieldType: 'selection',
                    autocomplete: 'employees',
                    size: 50,
                    label: 'consultant_1',
                },
                {
                    name: 'Verfasser 2',
                    fieldType: 'selection',
                    autocomplete: 'employees',
                    size: 50,
                    label: 'consultant_2',
                },
                {
                    name: 'Anlage',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    autocomplete_strings: ['Anlage', 'Anlagen'],
                    icons: [{ icon: <Mailbox /> }, { icon: <Mailbox /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'annex',
                    children:
                        [],
                },
                {
                    name: 'Versandmethode',
                    fieldType: 'button_select',
                    autocomplete: 'topics',
                    autocomplete_strings: ['beA', 'beSt', 'Post', 'Fax', 'E-Mail'],
                    icons: [{ icon: <Mailbox /> }, { icon: <Mailbox /> }, {
                        icon: <Mail />,
                    }, {
                        icon: <Fax />,
                    }, { icon: <AlternateEmail /> }],
                    singleCheck: true,
                    size: 100,
                    label: 'transmission_type',
                    children:
                        [{}, {}, {}, {}, {}],
                },

            ],
        },
    ],
};
