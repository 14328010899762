import { Grid, TextField } from '@mui/material';
import React from 'react';

const TaxID = (props) => {
    return (
        <Grid item xs={12} sm={6}>
            <TextField
                label={props.formData.type !== 'person' ? 'USt-IdNr' : 'Steuer-ID'}
                name={props.formData.type !== 'person' ? 'identification_number.UStID' : 'taxID'}
                value={props.formData.type !== 'person' ? props.formData?.identification_number?.UStID : (props.formData?.taxID !== null ? props.formData?.taxID : '')}
                onChange={props.handleInputChange}
                fullWidth
                margin='normal'
                color='success'
                variant='filled'
            />
        </Grid>
    );
};
export default TaxID;

// TODO: Implement routine for checking the structure of the german "TaxNumber"