export const complianceType = [

    {
        name: 'UStJE',
        user: ['Einzelunternehmen', 'Gesellschaft', 'Stiftung'],
    },
    {
        name: 'UStVA',
        user: ['Einzelunternehmen', 'Gesellschaft', 'Stiftung'],
    },
    {
        name: 'KStE',
        user: ['Gesellschaft', 'Stiftung', 'person'],
    },
    {
        name: 'EStE',
        user: ['Einzelunternehmen', 'Gesellschaft', 'person'],
    },
    {
        name: 'GewStE',
        user: ['Einzelunternehmen', 'Gesellschaft', 'Stiftung'],
    },
    {
        name: 'lexoffice',
        user: ['Einzelunternehmen', 'Gesellschaft', 'Stiftung'],
    },
    {
        name: 'LStE',
        user: ['Einzelunternehmen', 'Gesellschaft', 'Stiftung'],
    },
    {
        name: 'EÜR',
        user: ['Einzelunternehmen', 'Gesellschaft', 'Stiftung'],
    },
    {
        name: 'Jahresabschluss',
        user: ['Einzelunternehmen', 'Gesellschaft', 'Stiftung'],
    },
    {
        name: 'Buchhaltung',
        user: ['Einzelunternehmen', 'person', 'Gesellschaft', 'Stiftung'],
    },

];