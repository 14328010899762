import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { complianceType } from '../../../../misc/auto_data/complianceTypes.js';
import { FormGroup } from 'react-bootstrap';

const TaxCompliance = (props) => {
    const [selectedCompliance, setSelectedCompliance] = useState(
        props.formData.compliance ? props.formData.compliance : [],
    );

    useEffect(() => {
        setSelectedCompliance(props.formData?.compliance ? props.formData.compliance : []);
    }, [props.formData.compliance]);

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        const selectedIndex = selectedCompliance.indexOf(value);
        let newSelectedCompliance = [];

        if (selectedIndex === -1) {
            newSelectedCompliance = [...selectedCompliance, value];
        } else {
            selectedCompliance.splice(selectedIndex, 1);
            newSelectedCompliance = selectedCompliance;
        }

        setSelectedCompliance(newSelectedCompliance);
        if (props.formData.type !== 'Gesellschaft') {
            props.handleInputChange({
                target: {
                    name: 'compliance',
                    value: newSelectedCompliance,
                },
            });
        } else {
            props.handleInputChange({
                target: {
                    name: 'compliance',
                    value: newSelectedCompliance,
                },
            });
        }
    };

    const filteredCompliance = complianceType.filter((item) => {
        if (item.user && Array.isArray(item.user)) {
            return item.user.includes(props.formData.type);
        } else {
            return item.user === props.formData.type;
        }
    });

    return (
        <Grid item xs={12}>
            <Box textAlign='center'>
                <FormGroup
                    sx={{ width: '100%' }}
                    name='focus'
                    value={selectedCompliance}
                    onChange={handleCheckboxChange}
                >
                    {filteredCompliance.map((item, index) => {
                        const isChecked = selectedCompliance.includes(item.name);
                        return (
                            <FormControlLabel
                                control={<Checkbox color='success' checked={isChecked} />}
                                key={index}
                                label={item.name}
                                value={item.name}
                                color='success'
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 35 }, fontWeight: 'bold' }}
                            />
                        );
                    })}
                </FormGroup>
            </Box>
        </Grid>
    );
};

export default TaxCompliance;
