import { createTheme } from '@mui/material/styles/index.js';

const theme = createTheme({
    palette: {
        success: {
            //main: '#3e697c',
            main: '#3e697c',
        },
        gray: {
            main: '#a9a9a9',
        },
        green:{
            main: '#2e712a'
        }
    },
});

export default theme;