import { Autocomplete, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ApiCaller from '../../../../misc/APICaller.js';
import { sortArrayOfObjects } from '../../../../misc/helper.js';

const SelectExistingContact = (props) => {
        const apiCaller = new ApiCaller();
        const [clientOptions, setClientOptions] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const [selectedOption, setSelectedOption] = useState(props?.draftData?.[props.question.label] || null);
        const [returnValue, setReturnValue] = useState('string');
        const [message, setMessage] = useState('');

        const checkAddress = (value) => {
            if (!value?.location[0]?.street_number || !value?.location[0]?.street || !value?.location[0]?.city || !value?.location[0]?.zipcode) {
                setMessage(prevMessage => prevMessage + 'Es sind nicht ausreichend Informationen für den Kontakt im System vorhanden. Es fehlen: \n');

                if (!value?.location[0]?.street) {
                    setMessage(prevMessage => prevMessage + 'Straße ');
                }
                if (!value?.location[0]?.street_number) {
                    setMessage(prevMessage => prevMessage + ', Hausnummer ');
                }
                if (!value?.location[0]?.zipcode) {
                    setMessage(prevMessage => prevMessage + ' , PLZ');
                }

                if (!value?.location[0]?.city) {
                    setMessage(prevMessage => prevMessage + ', Stadt');
                }

            }
        };

        const handleAutocompleteChange = (event, value) => {
            if (value) {
                if (returnValue === 'string') {
                    setMessage('');
                    checkAddress(value);
                    const client = [
                        [value?.title, value?.first_name, value?.name].filter(Boolean).join(' '),
                        [value?.location[0]?.street, value?.location[0]?.street_number].filter(Boolean).join(' '),
                        [value?.location[0]?.zipcode, value?.location[0]?.city].filter(Boolean).join(' '),
                    ].filter(Boolean).join('\n');
                    props.setDraftData((prevData) => ({
                        ...prevData,
                        [props.question.label]: client,
                        [props.question.label + '_id']: value.id,
                    }));
                }
            }
        };


        useEffect(() => {
            console.log('selected option');
            console.log(selectedOption);
        }, [selectedOption]);

        useEffect(() => {
            const fetchData = async () => {
                try {
                    setIsLoading(true);
                    const clients = await apiCaller.callApi('getClientsByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
                    const sortedClients = sortArrayOfObjects(clients.data.persons, 'name');
                    setClientOptions(sortedClients);
                    if (props.draftData?.[props.question.label]) {
                        const matchingObjects = sortedClients.filter(item => {
                            if (item.id == props.draftData?.[props.question.label + '_id']) {
                                return (
                                    item
                                );
                            }
                            return false;
                        });

                        if (matchingObjects.length > 0) {
                            setSelectedOption(matchingObjects[0]);
                            setMessage('');
                            checkAddress(matchingObjects[0]);
                        }
                    }
                    setIsLoading(false);
                } catch (error) {
                    console.error(error);
                    setIsLoading(false);
                }
            };
            fetchData();

        }, []);

        return (
            <Grid container spacing={2}>
                {isLoading ? (
                    <>
                        <Grid container xs={12} spacing={2} style={{ marginTop: '20px' }}>
                            <Grid item xs={1}>
                                <CircularProgress
                                    color='primary'
                                    size={30}
                                    style={{
                                        transform: 'translateY(-50%)',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography>Kontakte werden geladen</Typography>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} sm={11.8}>
                        <Autocomplete
                            filterOptions={(options, params) => {
                                const inputValue = params.inputValue.toLowerCase();
                                return options.filter((option) => {
                                    return (
                                        (option.name && option.name.toLowerCase().includes(inputValue)) ||
                                        (option.first_name && option.first_name.toLowerCase().includes(inputValue))
                                    );
                                });
                            }}
                            options={clientOptions || []} // Ensure clientOptions is an array
                            value={selectedOption || null}
                            noOptionsText='Bitte Kontakt auswählen'
                            getOptionLabel={(option) => {
                                const fullName = [option.title, option.first_name, option.name].filter(Boolean).join(' ');
                                return fullName;
                            }}

                            onChange={(event, newValue) => {
                                setSelectedOption(newValue);
                                handleAutocompleteChange(event, newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    label='Kontakt wählen'
                                    name='contact'
                                    value={selectedOption || ''}
                                    fullWidth
                                    type='text'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                    {...params}
                                />
                            )}
                        />
                        <Typography style={{ width: '500px' }} color='red'>{message}</Typography>
                    </Grid>
                )}

            </Grid>

        );
    }
;

export default SelectExistingContact;
