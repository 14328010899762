import { Grid, TextField } from '@mui/material';
import React from 'react';

const Telefonnummer = (props) => {
    return (
        <Grid item xs={12} sm={6}>
            <TextField
                label='Telefonnummer'
                name={props.name}
                value={props?.name?.split('.').reduce((obj, key) => (obj ? obj[key] : obj), props.formData) ? props?.name.split('.').reduce((obj, key) => (obj ? obj[key] : obj), props.formData) : ''}
                onChange={props.handleInputChange}
                fullWidth
                margin='normal'
                color='success'
                variant='filled'
            />
        </Grid>
    );
};
export default Telefonnummer;
