export const JustificationTypes = [

    {
        name: 'Abstimmungsbedarf',
    },
    {
        name: 'Urlaub',
    },
    {
        name: 'Krankheit',
    },
    {
        name: 'Arbeitsaufkommen',
    },
    {
        name: 'Komplexität',
    },
    {
        name: 'Kurzfristige Mandatierung',
    },
];