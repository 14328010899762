export const companyType = [
    {
        name: 'Unternehmergesellschaft (UG)',
        label: 'UG',
        gender: 'female',
    },
    {
        name: 'Aktiengesellschaft (AG)',
        label: 'AG',
        gender: 'female',
    }, {
        name: 'Gesellschaft bürgerlichen Rechts (GbR)',
        label: 'GbR',
        gender: 'female',
    },
    {
        name: 'Gesellschaft mit beschränkter Haftung (GmbH)',
        label: 'GmbH',
        gender: 'female',
    },
    {
        name: 'Kommanditgesellschaft (KG)',
        label: 'KG',
        gender: 'female',
    },
    {
        name: 'GmbH & Co. KG',
        label: 'GmbH & Co. KG',
        gender: 'female',
    },
    {
        name: 'Offene Handelsgesellschaft (OHG)',
        label: 'OHG',
        gender: 'female',
    },
    {
        name: 'Partnerschaftsgesellschaft (PartG)',
        label: 'PartG',
        gender: 'female',
    },
];
companyType.sort((a, b) => a.name.localeCompare(b.name));