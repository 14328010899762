const focusData = [
    {
        name: 'Immobilien',
        image: 'realestate.png',
    },
    {
        name: 'Kryptoassets',
        image: 'cryptoassets.png',
    },
    {
        name: 'Digitale Geschäftsmodelle',
        image: 'rocketlaunch.png',
    },
    {
        name: 'Unternehmen / Anteile',
        image: 'company.png',
    },

];

export default focusData;