import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    Autocomplete,
    Button,
    Container,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { AddCircleRounded, Close } from '@mui/icons-material';
import ApiCaller from '../../../../misc/APICaller.js';
import { styled } from '@mui/system';


const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const RealEstateOwner = (props) => {
    const [clientList, setClientList] = useState([]);
    const [ownerList, setOwnerList] = useState([]);
    const [knownClient, setKnownClient] = useState(true);
    const apiCaller = new ApiCaller();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [ownerPercentage, setOwnerPercentage] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const clients = await apiCaller.callApi('getClientsByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
                console.log('clientList');
                console.log(clients.data.persons);
                setClientList(clients.data.persons);
                checkListItems();
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        console.log('called Relations');
        checkListItems();
        console.log(clientList);

    }, [props.formData.relations]);

    useEffect(() => {
        console.log('clientList');
        console.log(clientList);
    }, [clientList]);


    useEffect(() => {
        console.log('ownerList');
        console.log(ownerList);
        props.setOwnerList(ownerList);
    }, [ownerList]);


    function checkListItems() {
        props.formData.relations && props.formData.relations.forEach((entry) => {
            if (entry.tempID !== undefined && entry.tempID !== null) {
                const tempID = entry.tempID;
                const exists = clientList.some((client) => client.tempID === tempID);
                console.log(exists);
                if (!exists) {
                    setClientList((prevClientList) => [
                        ...prevClientList,
                        {
                            tempID: entry.tempID,
                            first_name: entry.person.first_name,
                            name: entry.person.name,
                        },
                    ]);
                }
            }
        });
        setClientList((prevClientList) => {
            if (props.formData.relations) {
                const tempIDs = props.formData.relations.map((entry) => entry.tempID);
                return prevClientList.filter((client) => !client.tempID || tempIDs.includes(client.tempID));
            }
        });
    }

    useEffect(() => {
        console.log('ChildComponent - addedPushed:', props.addedPushed);
    }, [props.addedPushed]);


    function handleAddOwner() {
        if (knownClient) {
            if (selectedOption) {
                selectedOption.ownership_percent = ownerPercentage;
                setOwnerList(prevOwnerList => [...prevOwnerList, selectedOption]);
                setSelectedOption(null);
                setOwnerPercentage('');
            }
        } else {
            // Handle adding new person as owner
            const newOwner = {
                first_name: firstName,
                name: lastName,
                ownership_percent: ownerPercentage,
            };
            setOwnerList(prevOwnerList => [...prevOwnerList, newOwner]);
        }

        // Clear input fields
        setFirstName('');
        setLastName('');
        setOwnerPercentage('');
    }

    const handleRemoveRelation = (index) => {
        setOwnerList((prevOwnerList) => [...prevOwnerList.slice(0, index), ...prevOwnerList.slice(index + 1)]);
    };

    function calculateTotalShares() {
        const own = parseFloat(props.fields.owner_percentage.value) || 0;
        const totalShares = ownerList.reduce((sum, owner) => {
            const percent = parseFloat(owner.ownership_percent);
            return sum + (isNaN(percent) ? 0 : percent);
        }, 0) + own;
        return totalShares;
    }

    return (
        <>
            {ownerList.length > 0 ? (
                <>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Anteilhaber</Typography>
                    </Grid>
                    {ownerList.map((owner, index) => (
                        <Grid item xs={12} key={index}>
                            <Accordion
                                style={{ backgroundColor: 'whitesmoke' }}
                                color='success'
                                variant='filled'
                            >
                                <StyledAccordionSummary>
                                    <Typography variant='body1'>
                                        {owner.first_name} {owner.name}: <b>{owner.ownership_percent} %</b>{' '}
                                    </Typography>
                                    <IconButton
                                        color='success'
                                        aria-label='Remove'
                                        onClick={() => handleRemoveRelation(index)}
                                    >
                                        <Close />
                                    </IconButton>
                                </StyledAccordionSummary>
                            </Accordion>
                        </Grid>
                    ))}

                    <Grid xs={12} style={{ marginLeft: '20px' }}>
                        <br />
                        <Typography variant='h6'>Anteile
                            Gesamt:
                            <b style={{ color: calculateTotalShares() === 100 ? 'green' : 'black' }}> {calculateTotalShares()} % </b>
                        </Typography>
                    </Grid>
                </>
            ) : null}

            <Container maxWidth style={{ marginTop: '30px' }}>
                {knownClient && clientList && clientList.length > 0 ? (

                    <div style={{ width: '100%', marginTop: '20px' }} className='box'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginLeft: '10px', marginBottom: '-20px' }}>
                                <Typography variant='h6'>Anteilhaber hinzufügen</Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Autocomplete
                                    options={clientList}
                                    getOptionLabel={(option) => `${option.title ? option.title : ''} ${option.first_name} ${option.name} ${option.birth_date ? ', ' + format(new Date(option.birth_date), 'dd.MM.yyyy') : ''}`}
                                    value={selectedOption}
                                    onChange={(event, newValue) => setSelectedOption(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Bestandssuche'
                                            name='mandantSearch'
                                            fullWidth
                                            margin='normal'
                                            color='success'
                                            variant='filled'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <TextField
                                    label='Anteile in %'
                                    name='ownerPercentage'
                                    value={ownerPercentage}
                                    onChange={(event) => setOwnerPercentage(event.target.value)}
                                    fullWidth
                                    type='number'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>
                            <Grid item xs={12} sm={1} style={{ marginTop: '20px' }}>
                                <IconButton color='success' onClick={handleAddOwner}>
                                    <AddCircleRounded fontSize='large' />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Typography>
                            <Button variant='text' color='success'
                                    onClick={() => setKnownClient(false)}>Neue
                                Person anlegen</Button>
                        </Typography>
                    </div>
                ) : (
                    <div style={{ width: '100%', marginTop: '20px' }} className='box'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginLeft: '10px', marginBottom: '-20px' }}>
                                <Typography variant='h6'>Neuer Kontakt</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4.5}>
                                <TextField
                                    label='Vorname'
                                    name='firstName'
                                    value={firstName}
                                    onChange={(event) => setFirstName(event.target.value)}
                                    fullWidth
                                    type='text'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>
                            <Grid item xs={12} sm={4.5}>
                                <TextField
                                    label='Nachname'
                                    name='lastName'
                                    value={lastName}
                                    onChange={(event) => setLastName(event.target.value)}
                                    fullWidth
                                    type='text'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    label='Anteile in %'
                                    name='ownerPercentage'
                                    value={ownerPercentage}
                                    onChange={(event) => setOwnerPercentage(event.target.value)}
                                    fullWidth
                                    type='number'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>
                            <Grid item xs={12} sm={1} style={{ marginTop: '20px' }}>
                                <IconButton color='success' onClick={handleAddOwner}>
                                    <AddCircleRounded fontSize='large' />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <Button variant='text' color='success'
                                            onClick={() => setKnownClient(true)}>Aus
                                        Bestand wählen
                                    </Button>
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </Container>
        </>
    )
        ;
};

export default RealEstateOwner;
