import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import 'bulma/css/bulma.css';
import reportWebVitals from './reportWebVitals.js';
import { BrowserRouter as Router } from 'react-router-dom';
import Favicon from 'react-favicon';

const germanLocale = {
    language: 'de-DE',
};
Intl.NumberFormat('de-DE', germanLocale.options);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <Favicon url='./public/favicon.ico' />
        <Router>
            <App />
        </Router>
    </>,
);

reportWebVitals();
