import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Checkbox,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material';
import {
    ArrowDropDown,
    ArrowDropUp,
    CalendarMonthRounded,
    Edit,
    Phone,
    Search,
    Sms,
    StarBorderOutlined,
} from '@mui/icons-material';
import ApiCaller from '../../misc/APICaller.js';
import FormLoader from './loadCustomer/formLoader.js';
import { formatEntityString, loadCustomer } from './helper.js';
import { StarFill } from 'react-bootstrap-icons';
import { styled } from '@mui/system';

const ColoredBorderIconButton = styled(IconButton)(({ theme }) => ({
    border: '1px solid lightgray',
    boxShadow: '2px 1px 1px rgba(0.2, 0, 0, 0.2)',
    margin: '5px',
}));


const CustomerTable = (props) => {
        const [searchTerm, setSearchTerm] = useState('');
        const [customerList, setCustomerList] = useState([]);
        const [openFormLoader, setOpenFormLoader] = useState(false);
        const [rows, setRows] = useState([]);
        const apiCaller = new ApiCaller();
        const [sortColumn, setSortColumn] = useState(localStorage.getItem('customer_sortColumn') || 'name');
        const [customerData, setCustomerData] = useState({});
        const [sortDirection, setSortDirection] = useState(localStorage.getItem('customer_sortDirection') || 'asc');
        const [favorites, setFavorites] = useState((localStorage.getItem('favorite_state') === 'true') || false);
        const [showMandantenOnly, setShowMandantenOnly] = useState(localStorage.getItem('setting_onlyMandants') || false);
        const [filteredRows, setFilteredRows] = useState([]);
        const [statusMessage, setStatusMessage] = useState('Loading..');
        const UpdateCustomerList = async () => {
            try {
                setStatusMessage('Loading...');
                const clients = await loadCustomer(localStorage.getItem('id'));
                if (clients?.length <= 0)
                    setStatusMessage('Keine Mandanten gefunden.');
                setCustomerList(clients);
            } catch (error) {
                console.error(error);
            }
        };

        useEffect(() => {
            async function fetchData() {
                try {
                    if (props.customerUpdate) {
                        setStatusMessage('Loading...');
                        const clients = await loadCustomer(localStorage.getItem('id'));
                        if (clients?.length <= 0)
                            setStatusMessage('Keine Mandanten gefunden.');
                        setCustomerList(clients);
                    }
                } catch
                    (error) {
                    console.error('Error fetching data:', error);
                }
            }

            fetchData();
        }, [props.customerUpdate]);


        const handleSearchChange = (e) => {
            setSearchTerm(e.target.value);
        };
        const handleSort = (column, initial = false) => {
            if (sortColumn === column) {
                if (!initial) {
                    const direction = sortDirection === 'asc' ? 'desc' : 'asc';
                    setSortDirection(direction);
                    localStorage.setItem('customer_sortDirection', direction);
                } else {
                    setSortDirection(sortDirection);
                    localStorage.setItem('customer_sortDirection', sortDirection);
                }
            } else {
                setSortColumn(column);
                localStorage.setItem('customer_sortColumn', column);
                setSortDirection('asc');
                localStorage.setItem('customer_sortDirection', 'asc');
            }
        };
        const handleFavorites = (event) => {
            localStorage.setItem('favorite_state', (!favorites).toString());
            setFavorites(!favorites);
        };

        const handleInputChange = (e) => {
            const { name, value, type, checked } = e.target;

            let newValue;
            if (type === 'checkbox') {
                newValue = checked;
            } else {
                newValue = value;
            }

            setCustomerData((prevCustomerData) => {
                const updatedCustomerData = { ...prevCustomerData };
                const nameParts = name.split('.');
                let currentObject = updatedCustomerData;
                for (let i = 0; i < nameParts.length; i++) {
                    const currentPart = nameParts[i];
                    if (i === nameParts.length - 1) {
                        currentObject[currentPart] = newValue;
                    } else {
                        currentObject = currentObject[currentPart];
                    }
                }

                return updatedCustomerData;
            });
        };

        const handleContactFavorite = async (row) => {
            try {
                console.log('WHAT');
                const importance = row.important_mandat === 0 || row.important_mandat === null ? 1 : 0;
                row.important_mandat = importance;
                const result = await apiCaller.callApi('updateEntity', 'POST', {
                    entity: {
                        id: row.id,
                        important_mandat: importance,
                    },
                }, {});
                console.log('result');
                console.log(result);
            } catch (err) {
                console.error(err);
            }
        };

        const handleDataRefresh = () => {
            // Trigger data refresh logic here
            UpdateCustomerList(); // Make sure to update your data accordingly
            // You may also want to refresh the filteredRows state if needed
        };


        useEffect(() => {
            async function fetchData() {
                setStatusMessage('Loading...');
                const clients = await loadCustomer(localStorage.getItem('id'));
                if (clients?.length <= 0)
                    setStatusMessage('Keine Mandanten gefunden.');
                if (clients.length > 0)
                    setCustomerList(clients);
            }

            fetchData();
            const mandantView = localStorage.getItem('setting_onlyMandants');
            setShowMandantenOnly(mandantView === 'true');
            handleSort(sortColumn, true);

        }, []);

        const handleRowClick = (id) => {
            if (customerList.length > 0) {
                const updatedRows = customerList.map((row) => {
                    if (row.id === id) {
                        row.open = !row.open;
                    } else {
                        row.open = false;
                    }
                    return row;
                });
                setRows(updatedRows);
            }
        };

        const handleCheckboxChange = (event) => {
            setShowMandantenOnly(event.target.checked);
            localStorage.setItem('setting_onlyMandants', event.target.checked);
        };

        const renderArrowIcon = (column) => {
            if (sortColumn === column) {
                return sortDirection === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />;
            }
            return null; // No arrow icon if column is not currently sorted
        };


        const sortedRows = [...Array.from(customerList)].sort((a, b) => {
                if (sortColumn === 'name_full') {
                    const nameA = (a.type === 'person' ? (a.name ? a.name.toLowerCase() : '') : (a.name_full ? a.name_full.toLowerCase() : ''));
                    const nameB = (b.type === 'person' ? (b.name ? b.name.toLowerCase() : '') : (b.name_full ? b.name_full.toLowerCase() : ''));
                    return sortDirection === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
                } else if (sortColumn === 'mandant_manager_entity') {
                    // Sort by mandant_manager_entity
                    const nameA = (a?.mandant_manager_entity?.name || '').toLowerCase();
                    const nameB = (b?.mandant_manager_entity?.name || '').toLowerCase();

                    // Handle null values
                    if (!nameA && !nameB) {
                        return 0; // Both values are null, consider them equal
                    } else if (!nameA) {
                        return 1; // A is null, place it after B
                    } else if (!nameB) {
                        return -1; // B is null, place it after A
                    }

                    return sortDirection === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
                } else if (sortColumn === 'mandant_lead_entity') {
                    // Sort by mandant_manager_entity
                    const nameA = (a?.mandant_lead_entity?.name || '').toLowerCase();
                    const nameB = (b?.mandant_lead_entity?.name || '').toLowerCase();

                    // Handle null values
                    if (!nameA && !nameB) {
                        return 0; // Both values are null, consider them equal
                    } else if (!nameA) {
                        return 1; // A is null, place it after B
                    } else if (!nameB) {
                        return -1; // B is null, place it after A
                    }

                    return sortDirection === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
                } else if (sortColumn === 'estimated_wealth') {
                    const wealthA = a.estimated_wealth !== null && typeof a.estimated_wealth === 'number' ? a.estimated_wealth : 0;
                    const wealthB = b.estimated_wealth !== null && typeof b.estimated_wealth === 'number' ? b.estimated_wealth : 0;
                    return sortDirection === 'asc' ? wealthA - wealthB : wealthB - wealthA;
                } else if (sortColumn === 'location[0].city') {
                    // Sort by location[0].city
                    const cityA = a.location[0]?.city?.toLowerCase() || '';
                    const cityB = b.location[0]?.city?.toLowerCase() || '';
                    return sortDirection === 'asc' ? cityA.localeCompare(cityB) : cityB.localeCompare(cityA);
                } else if (sortColumn === 'focus') {
                    // Sort by focus
                    const focusA = (a.focus || '').toLowerCase();
                    const focusB = (b.focus || '').toLowerCase();
                    return sortDirection === 'asc' ? focusA.localeCompare(focusB) : focusB.localeCompare(focusA);
                }
            },
        );

        useEffect(() => {
            const filteredRows = sortedRows.filter((row) => {
                const fullName = `${row.name_full} ${row.mandant_manager_entity?.name} ${row.mandant_lead_entity?.name} ${row.name} ${row.first_name} ${row.mandant_lead_entity?.first_name}`;
                const isMandant = row.role === 'mandator';
                const importantMandat = row.important_mandat;
                const meetsSearchTermCondition = fullName.toLowerCase().includes(searchTerm.toLowerCase());
                const meetsMandantCondition = !showMandantenOnly || isMandant;
                const meetsFavs = favorites ? importantMandat : true;
                return meetsSearchTermCondition && meetsMandantCondition && meetsFavs;
            });
            setFilteredRows(filteredRows);
        }, [sortedRows, searchTerm, showMandantenOnly]);


        async function handleEditingForm(id) {
            try {
                setOpenFormLoader(true);
                const testPersonID = id;
                const data = await apiCaller.callApi('loadFormData', 'GET', {}, { personId: testPersonID });
                setCustomerData(data);
            } catch (err) {
                setCustomerData({});
            }

        }

        useEffect(() => {
            console.log('customerList');
            console.log(customerList);
        }, [customerList]);


        return (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 5 }}>
                    <TextField
                        variant='outlined'
                        size='medium'
                        color='success'
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: <Search fontSize='medium' style={{ marginRight: '10px' }} />,
                        }}
                        fullWidth
                    />
                </Box>
                <TableContainer className='customerTable'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <IconButton onClick={handleFavorites} aria-label='favorite'>
                                        {favorites ? <StarFill color='#FFD700' sx={{ fontSize: 26 }} /> :
                                            <StarBorderOutlined sx={{ fontSize: 26 }} />}
                                    </IconButton>
                                </TableCell>
                                <TableCell onClick={() => handleSort('name_full')}
                                           style={{ cursor: 'pointer' }}>
                                    Name / Firma
                                    {renderArrowIcon('name_full')}
                                </TableCell>
                                <TableCell
                                    onClick={() => handleSort('mandant_manager_entity')} style={{ cursor: 'pointer' }}>
                                    Mandatsmanager
                                    {renderArrowIcon('mandant_manager_entity')}
                                </TableCell>
                                <TableCell onClick={() => handleSort('mandant_lead_entity')}
                                           style={{ cursor: 'pointer' }}>
                                    Mandatsverantwortlicher
                                    {renderArrowIcon('mandant_lead_entity')}
                                </TableCell>
                                <TableCell>Art</TableCell>
                                {/*<TableCell onClick={() => handleSort('estimated_wealth')} style={{ cursor: 'pointer' }}>
                                    Vermögen (EUR)
                                    {renderArrowIcon('estimated_wealth')}
                                </TableCell>*/}

                                <TableCell>
                                    <Checkbox
                                        checked={showMandantenOnly}
                                        onChange={handleCheckboxChange}
                                        color='success'
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
                                    /> nur Mandanten
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows && filteredRows.length > 0 ? (
                                filteredRows.map((row) => (
                                    <>
                                        <TableRow key={row.id} onClick={() => handleRowClick(row.id)}>
                                            <TableCell>
                                                <IconButton color='success' onClick={() => handleContactFavorite(row)}>
                                                    <Avatar color='success' sx={{ bgcolor: '#3e697c' }}>
                                                        {row.important_mandat ?
                                                            <StarFill color='#FFD700' sx={{ fontSize: 26 }} /> :
                                                            <StarBorderOutlined sx={{ fontSize: 26 }} />}
                                                        {/* {row.role === 'client' ? <Person /> : row.role === 'mandator' ?
                                                        <Person4 /> : 'U'}*/}
                                                    </Avatar>
                                                </IconButton>
                                            </TableCell>
                                            {/*<TableCell>
                                                {row.type === 'person' ? `${row.title ? row.title + ' ' : ''}${row.first_name ? row.first_name + ' ' : ''}${row.name ? row.name + ' ' : ''}` :
                                                    `${row.name_full ? row.name_full + ' ' : ''}${row.name ? '(' + row.name + ')' : ''}`}
                                            </TableCell>*/}
                                            <TableCell>
                                                {row ? formatEntityString(row) : ''}
                                            </TableCell>
                                            <TableCell>{row.mandant_manager_entity ? formatEntityString(row.mandant_manager_entity) : ''}</TableCell>
                                            <TableCell>{row.mandant_lead_entity ? formatEntityString(row.mandant_lead_entity) : ''}</TableCell>
                                            <TableCell>
                                                {row.corporation_type ||
                                                    (row.type === 'client' ? 'Kontakt' : row.role === 'mandator' ? 'Mandant' : 'Kontakt')}
                                            </TableCell>
                                            {/*<TableCell>
                                                {row.estimated_wealth !== null && typeof row.estimated_wealth === 'number'
                                                    ? row.estimated_wealth.toLocaleString('de-DE', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                        useGrouping: true,
                                                    })
                                                    : ''}
                                            </TableCell>*/}

                                            <TableCell>
                                                <Tooltip title='Stammdaten editieren'>
                                                    <ColoredBorderIconButton
                                                        color='success'
                                                        aria-label='Editieren'
                                                        component='a'
                                                        onClick={() => handleEditingForm(row.id)}
                                                    >
                                                        <Edit fontSize='medium' />
                                                    </ColoredBorderIconButton>
                                                </Tooltip>
                                                <ColoredBorderIconButton
                                                    disabled
                                                    color='success'
                                                    aria-label='Anrufen'
                                                    component='a'
                                                    href={`tel:${row.zipCode}`}
                                                >
                                                    <Phone fontSize='medium' />
                                                </ColoredBorderIconButton>
                                                <ColoredBorderIconButton
                                                    disabled
                                                    color='success'
                                                    aria-label='Nachricht schicken'
                                                    component='a'
                                                    href={`sms:${row.zipCode}`}
                                                >
                                                    <Sms fontSize='medium' />
                                                </ColoredBorderIconButton>
                                                <ColoredBorderIconButton
                                                    disabled
                                                    color='success'
                                                    aria-label='Termin ausmachen'
                                                    component='a'
                                                    href={`sms:${row.zipCode}`}
                                                >
                                                    <CalendarMonthRounded fontSize='medium' />
                                                </ColoredBorderIconButton>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                                                <Collapse in={row.open} timeout='auto' unmountOnExit>
                                                    {/*
                                                <Grid container spacing={2} xs={12} style={{ margin: '10px' }}>
                                                    <Grid item xs={4}>
                                                        <Typography variant='h6'>Gesellschafter</Typography>
                                                        <Typography variant='body'>Dr. Stefan
                                                            Gradl <br /></Typography>
                                                        <Typography variant='body'>Dr. Maximilian
                                                            Freyenfeld <br /></Typography>
                                                        <Typography variant='body'>Markus
                                                            Wirth <br /></Typography>

                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant='h6'>Finanzamt</Typography>
                                                        <Typography variant='body'>Finanzamt
                                                            Nürnberg-Süd<br /></Typography>
                                                        <Typography variant='body'> Steuernummer
                                                            645/345/345<br /></Typography>
                                                        <Typography variant='body'>Kontakt: Hans
                                                            Huber <br /></Typography>
                                                        Telefon: 0911/8888565
                                                        <IconButton
                                                            color='success'
                                                            aria-label='Anrufen'
                                                            component='a'
                                                            href={`tel:${row.zipCode}`}
                                                        >
                                                            <Phone color='success' fontSize='small' />
                                                        </IconButton>

                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant='h6'>Kontakt</Typography>
                                                        <Typography variant='body'>info@cryptolight.io
                                                            <IconButton
                                                                color='success'
                                                                aria-label='Anrufen'
                                                                component='a'
                                                                href={`mailto:info@cryptolight.io`}
                                                            >
                                                                <Mail color='success' fontSize='small' />
                                                            </IconButton>
                                                            <br /></Typography>
                                                        <Typography variant='body'>0911/8888560
                                                            <IconButton
                                                                color='success'
                                                                aria-label='Anrufen'
                                                                component='a'
                                                                href={`mailto:info@cryptolight.io`}
                                                            >
                                                                <Phone color='success' fontSize='small' />
                                                            </IconButton><br />
                                                        </Typography>
                                                        <Typography variant='body'>Termin vereinbaren:
                                                            <IconButton
                                                                color='success'
                                                                aria-label='Anrufen'
                                                                component='a'
                                                                href={`mailto:info@cryptolight.io`}
                                                            >
                                                                <CalendarMonthRounded color='success'
                                                                                      fontSize='small' />
                                                            </IconButton><br />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                */}
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5}>{statusMessage}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormLoader openFormloader={openFormLoader} customerData={customerData}
                            setCustomerData={setCustomerData}
                            setOpenFormLoader={setOpenFormLoader} handleInputChange={handleInputChange}
                            setCustomerList={setCustomerList} customerList={customerList}
                            UpdateCustomerList={UpdateCustomerList} onClose={handleDataRefresh} />
            </>
        );
    }
;

export default CustomerTable;
