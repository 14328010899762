import React from 'react';
import { Badge } from '@mui/material';

const Mailbox = () => {
    return (
        <>
            <Badge color='success' badgeContent={99}>
                <h2 className='h2'> Posteingang</h2>
            </Badge>
        </>
    );
};

export default Mailbox;