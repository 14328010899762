import React, { useEffect, useState } from 'react';
import ApiCaller from '../../../misc/APICaller.js';
import { Button, Tooltip } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { formatEntityString } from '../helper.js';

const apiCaller = new ApiCaller();

const PreloadButtonGroup = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [textFieldBounds, setTextFieldBounds] = useState([]);
    const setSelected = (preloadObject) => {
        if (preloadObject.preSelect) {
            for (const value of preloadObject.values) {
                props.handleButtonSelection(props.question.label, value);
            }
        }
    };

    const checkPreSelect = (entity, select) => {
        if (select) {
            return true;
        } else {
            const entityString = formatEntityString(entity);
            return props?.draftData[props.question.label]?.includes(entityString);
        }
    };

    const fetchDataForPreLoad = async () => {
        //props.draftData[props.question.label] = [];
        setIsLoading(true);
        for (const preloadObject of props.preLoad) {
            try {
                if (preloadObject.type === 'request') {
                    if (preloadObject.name === 'consultant') {
                        const con = await apiCaller.callApi('getUserDataByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
                        if (con.data.length > 0) {
                            props.handleSetSelectedObjects(
                                {
                                    ...con.data[0],
                                    type: preloadObject.type,
                                    select: preloadObject.preSelect,
                                    icon: preloadObject.icon,
                                    tooltip: preloadObject.tooltip,
                                },
                            );
                        }
                    } else if (preloadObject.name === 'company_associates') {
                        //const associates = await apiCaller.callApi('getCompanyAssociates', 'GET', {}, { entity_id: props.mandateID });
                        if (props.draftData[props.question.dependency + '_id']) {
                            const associates = await apiCaller.callApi('getCompanyAssociates', 'GET', {}, { entity_id: props.draftData[props.question.dependency + '_id'] });
                            console.log('associates');
                            console.log(associates);
                            if (Array.isArray(associates.data)) {
                                for (const associate of associates.data) {
                                    props.handleSetSelectedObjects(
                                        {
                                            ...associate,
                                            type: preloadObject.type,
                                            //select: preloadObject.preSelect,
                                            select: checkPreSelect(associate, preloadObject.preSelect),
                                            icon: preloadObject.icon,
                                            tooltip: preloadObject.tooltip,
                                        },
                                    );
                                }
                            }
                        }
                    } else if (preloadObject.name === 'management') {
                        preloadObject.values = [];
                        if (props.mandateID) {
                            const management = await apiCaller.callApi('getMandatManager', 'GET', {}, { mandant_id: props.mandateID });
                            for (const key in management.data[0]) {
                                if (management.data[0][key] !== null) {
                                    const entity = await apiCaller.callApi('getEntityByEntityID', 'GET', {}, { entity_id: management.data[0][key] });
                                    props.handleSetSelectedObjects(
                                        {
                                            ...entity.data[0],
                                            type: preloadObject.type,
                                            select: preloadObject.preSelect,
                                            icon: preloadObject.icon,
                                            tooltip: preloadObject.tooltip,
                                        },
                                    );
                                }
                            }
                        }

                    } else if (preloadObject.name === 'relatives') {
                        if (props.mandateID) {
                            const relations = await apiCaller.callApi('getRelations', 'GET', {}, { entity_id: props.mandateID });
                            for (const obj of relations.data) {
                                props.handleSetSelectedObjects(
                                    {
                                        ...obj.person,
                                        type: preloadObject.type,
                                        select: preloadObject.preSelect,
                                        icon: preloadObject.icon,
                                        tooltip: preloadObject.tooltip,
                                    },
                                );
                            }
                        }

                    } else if (preloadObject.name === 'financeAuthority') {
                        if (props.mandateID) {
                            const financeAuthority = await apiCaller.callApi('getTaxAuthorityContactPersonByEntity', 'GET', {}, { entity_id: props.mandateID });
                            for (const obj of financeAuthority.data) {
                                props.handleSetSelectedObjects(
                                    {
                                        ...obj,
                                        type: preloadObject.type,
                                        select: preloadObject.preSelect,
                                        icon: preloadObject.icon,
                                        tooltip: preloadObject.tooltip,
                                    },
                                );
                            }
                        }
                    }

                } else {
                    preloadObject.id = 2000000;
                    props.handleSetSelectedObjects(preloadObject);
                }
            } catch (error) {
                console.error(`Error "${error}":`);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const updatedSelectedObjects = props.selectedObjects.filter((obj) => !obj.hasOwnProperty('id'));
        props.setSelectedObjects(updatedSelectedObjects);
        fetchDataForPreLoad();
    }, [props.mandateID]);

    useEffect(() => {
        fetchDataForPreLoad();
    }, []);


    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <>
                    {props.selectedObjects.map((selObj, preloadIndex) => {
                        if (selObj.type === 'formField') {
                            return (
                                props.draftData[selObj.name] &&
                                <Tooltip title={selObj.tooltip} key={preloadIndex}>
                                    <Button
                                        endIcon={selObj.select ? <CheckCircle /> : null}
                                        startIcon={selObj.icon}
                                        key={preloadIndex}
                                        variant={
                                            selObj.select
                                                ? 'contained'
                                                : 'outlined'
                                        }
                                        color='success'
                                        style={{ margin: '0.5em' }}
                                        onClick={() =>
                                            props.handleButtonPress(selObj)
                                        }
                                    >
                                        {props.draftData[selObj.name]}
                                    </Button>
                                </Tooltip>
                            );
                        } else if (selObj.type === 'request') {
                            return (
                                <Tooltip title={selObj.tooltip} key={preloadIndex}>
                                    <Button
                                        endIcon={selObj.select ? <CheckCircle /> : null}
                                        startIcon={selObj.icon}
                                        key={preloadIndex}
                                        variant={
                                            selObj.select
                                                ? 'contained'
                                                : 'outlined'
                                        }
                                        color='success'
                                        style={{ margin: '0.5em' }}
                                        onClick={() =>
                                            props.handleButtonPress(selObj)
                                        }>
                                        {`${selObj.title ? selObj.title + ' ' : ''}${selObj.first_name} ${selObj.name}`}
                                    </Button>
                                </Tooltip>
                            );
                        } else {
                            return null;
                        }
                    })}
                </>
            )}
        </div>
    );
};

export default PreloadButtonGroup;
