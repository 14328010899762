import React from 'react';
import { Container, Grid } from '@mui/material';

function About() {
    return (
        <>
            <Grid>
                <Container fluid className='p-5'>
                    <h2 className='h2'>Willkommen auf Consultomatix</h2>
                    <p>Consultomatix ist ein Produkt von Cryptolight. Mehr Informationen dazu finden Sie hier. <a
                        href='https://www.cryptolight.io' target='_blank' rel='noreferrer'>Link</a></p>
                </Container>
            </Grid>
        </>
    );
}

export default About;