import React, { useEffect, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { mapPercentageWidthToInt } from '../../helper.js';
import RealEstateOwner from './/asset_real_estate_owner.js';
import ApiCaller from '../../../../misc/APICaller.js';
import { setFieldType } from '../../../../misc/helper.js';
import AddCompany from './AddCompany.js';

const AssetRenderer = (props) => {
    // const [selectedRole, setSelectedRole] = useState('');
    const [orderedData, setOrderedData] = useState([]);
    /*const onChangeRole = (event, item) => {
        setSelectedRole(event.target.value);
        props.onChange(event, item);
    };*/

    useEffect(() => {
        console.log('orderedData');
        console.log(orderedData);

    }, [orderedData]);

    const apiCaller = new ApiCaller();
    // const [clients, setClients] = useState({});

    const requestFormFields = async () => {
        if (props.selectedType !== '') {
            const fields = await apiCaller.callApi('get_asset_templates', 'GET', {}, { template_type: props.selectedType });
            console.log('requestFormField');
            props.setAssetFormFields(fields);
        }
    };

    useEffect(() => {
        async function fetchData() {
            await requestFormFields();
        }

        fetchData();
    }, [props.selectedType]);

    useEffect(() => {
        if (props.assetFormFields.length !== 0) {
            console.log('assetFormFields');
            console.log(props.assetFormFields);
            const data = [...props.assetFormFields.data].sort((a, b) => {
                if (a.order === null && b.order === null) {
                    return 0;
                } else if (a.order === null) {
                    return 1;
                } else if (b.order === null) {
                    return -1;
                } else {
                    return parseInt(a.order) - parseInt(b.order);
                }
            });
            setOrderedData(data);
        }
    }, [props.assetFormFields]);

    useEffect(() => {
        const apiCaller = new ApiCaller();
        const requestFormFields = async () => {
            if (props.selectedType !== '') {
                const fields = await apiCaller.callApi('get_asset_templates', 'GET', {}, { template_type: props.selectedType });
                props.setAssetFormFields(fields);
            }
        };
        requestFormFields();
    }, [props.selectedType]);

    if (props.assetFormFields.length === 0) {
        return null; // or a loading indicator, or some other fallback
    }

    return (
        <>
            {props.selectedType === 'Gesellschaft' ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AddCompany handleInputChange={props.handleInputChange} data={props.formData}
                                    orderedData={orderedData} onChange={props.onChange} />
                    </Grid>
                </Grid>


                /*orderedData.map((item, index) => {
                    let corporationForms = {};
                    if (item.attr_preset) {
                        corporationForms = item.attr_preset.split(',');
                    }
                    return (
                        <>
                            {item.attr_label === 'Rolle' &&
                                <Grid item xs={12}
                                      sm={mapPercentageWidthToInt(item.dimension ? item.dimension : 50)}
                                      key={index}>
                                    <FormControl variant='filled' color='success' fullWidth
                                                 style={{ marginTop: '15px' }}>
                                        <InputLabel>{item.attr_label} </InputLabel>
                                        <Select
                                            defaultValue=''
                                            label={item.attr_label}
                                            name={item.attr_name}
                                            onChange={(event) => onChangeRole(event, item)}
                                        >

                                            {corporationForms.length > 0 && corporationForms.map((menuItem, index) => (
                                                <MenuItem key={index} value={menuItem} name={menuItem}>
                                                    {menuItem}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            {
                                item.attr_label !== 'Rolle' && selectedRole !== '' && selectedRole !== 'Geschäftsführer (ohne Anteile)' && item.attr_label !== 'Rechtsform' &&
                                <Grid item xs={12}
                                      sm={mapPercentageWidthToInt(item.dimension ? item.dimension : 50)}
                                      key={index}>
                                    <TextField
                                        name={item.attr_name}
                                        label={item.attr_label}
                                        type='text'
                                        fullWidth
                                        margin='normal'
                                        color='success'
                                        variant='filled'
                                        onChange={(event) => props.onChange(event, item)}
                                    />
                                </Grid>
                            }

                            {
                                item.attr_label !== 'Rolle' && selectedRole !== ''
                                && selectedRole === 'Geschäftsführer (ohne Anteile)'
                                && item.attr_label == 'Name der Gesellschaft' &&
                                < Grid item xs={12}
                                       sm={mapPercentageWidthToInt(item.dimension ? item.dimension : 50)}
                                       key={index}>
                                    <TextField
                                        name={item.attr_name}
                                        label={item.attr_label}
                                        type={setFieldType(item.attr_type)}
                                        fullWidth
                                        margin='normal'
                                        color='success'
                                        variant='filled'
                                        onChange={(event) => props.onChange(event, item)}
                                    />
                                </Grid>
                            }
                            {
                                item.attr_label === 'Rechtsform' && selectedRole !== '' &&
                                < Grid item xs={12}
                                       key={index}
                                       sm={mapPercentageWidthToInt(item.dimension ? item.dimension : 50)}>
                                    <FormControl variant='filled' color='success' fullWidth
                                                 style={{ marginTop: '15px' }}>
                                        <InputLabel>{item.attr_label} </InputLabel>
                                        <Select
                                            defaultValue=''
                                            label={item.attr_label}
                                            name={item.attr_name}
                                            onChange={(event) => props.onChange(event, item)}
                                        >
                                            {item.attr_preset.split(',').map((menuItem, index) => (
                                                <MenuItem key={index} value={menuItem}>
                                                    {menuItem}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                        </>
                    );
                })*/
            ) : (
                orderedData.map((item, index) => {
                    if (item.form_type === 'text_field') {
                        //const inputProps = defineInputProps(item.attr_type);
                        const InputLabelProps = {};
                        if (item.attr_type === 'date') {
                            InputLabelProps.shrink = true;
                        }
                        return (
                            <Grid item xs={6} sm={mapPercentageWidthToInt(item.dimension ? item.dimension : 50)}
                                  key={index}>
                                <TextField
                                    name={item.attr_name}
                                    label={item.attr_label}
                                    type={setFieldType(item.attr_type)}
                                    fullWidth
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                    InputLabelProps={InputLabelProps}
                                    onChange={(event) => {
                                        props.onChange(event, item);
                                    }}

                                />
                            </Grid>
                        );
                    } else if (item.form_type === 'select') {
                        const attrPresetArray = item.attr_preset.split(',').map((item) => ({
                            value: item.trim(),
                            label: item.trim(),
                        }));
                        return (
                            <Grid item xs={6} sm={mapPercentageWidthToInt(item.dimension ? item.dimension : 50)}
                                  key={index}>
                                <FormControl variant='filled' color='success' fullWidth
                                             style={{ marginTop: '15px' }}>
                                    <InputLabel>{item.attr_label} </InputLabel>
                                    <Select
                                        defaultValue=''
                                        label={item.attr_label}
                                        name={item.attr_name}
                                        onChange={(event) => props.onChange(event, item)}
                                    >
                                        {attrPresetArray.map((menuItem, index) => (
                                            <MenuItem key={index} value={menuItem.value}>
                                                {menuItem.label}
                                            </MenuItem>
                                        ))}
                                        <MenuItem key={-1} value={null}></MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        );
                    }
                    return null;

                })
            )}

            {
                props.selectedType === 'Immobilie' && (
                    <RealEstateOwner formData={props.formData} setOwnerList={props.setOwnerList}
                                     ownerList={props.ownerList} fields={props.fields}
                                     addedPushed={props.addedPushed} />
                )
            }
        </>
    );

};
export default AssetRenderer;