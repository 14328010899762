import React from 'react';
import Dialog from '@mui/material/Dialog/index.js';
import DialogTitle from '@mui/material/DialogTitle/index.js';
import Button from '@mui/material/Button/index.js';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';


function ConfirmationDialog({ isOpen, onClose, onConfirm, message, confirmText }) {
    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                PaperProps={{
                    style: {
                        backgroundColor: 'white', // Make the background transparent
                        boxShadow: '0px 1px 1px rgba(128, 128, 128, 0.2)',// Remove the drop shadow
                        border: '1px solid lightgray',
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(255, 255, 255, 0.05)', // Make the backdrop transparent
                    },
                }}
            >
                < DialogTitle> {message}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Diese Aktion kann nicht rückgängig gemacht werden.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant='filled' color='success'>
                        Abbrechen
                    </Button>
                    <Button onClick={handleConfirm} variant='filled' color='succes'>
                        {confirmText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}

export default ConfirmationDialog;
