import React from 'react';

const Projects = () => {
    return (
        <>
            <>
                <h2 className='h2'> Projekte</h2>
            </>
        </>
    );
};

export default Projects;