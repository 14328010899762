import React, { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import JustificationForm from './Justification.js';
import dayjs from 'dayjs';

const LawyerLetterStepper = (props) => {
    const [expirationDate, setExpirationDate] = useState(props?.draftData?.deadline_extension_date ?? '');
    const [reference, addReference] = useState(props?.draftData?.reference ?? '');
    const stateUpdateFunctions = {
        reference: addReference,
    };

    const handleDatePicker = (formattedDate) => {
        console.log(formattedDate);
        props.setDraftData({ ...props.draftData, ['deadline_extension_date']: formattedDate });
    };

    const handleTextInputChange = (event) => {
        const text = event.target.value;
        const name = event.target.name;
        if (stateUpdateFunctions[name]) {
            stateUpdateFunctions[name](text);
        }
        props.setDraftData({ ...props.draftData, [event.target.name]: text });
    };

    return (
        <>
            {props.draftData.document == 'SFA_Fristverlängerung' ? (
                <>

                    <Grid container spacing={2} style={{ marginLeft: '5px' }}>
                        <Grid item xs={12}>
                            <Typography variant='body'
                                        style={{ fontWeight: 'bold' }}>Bezug</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Bezug'
                                name='reference'
                                variant='filled'
                                color='success'
                                value={reference}
                                onChange={handleTextInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body' style={{ fontWeight: 'bold' }}>Verlängerung
                                bis</Typography>
                        </Grid>
                        <Grid item xs={4}>

                            <DesktopDateTimePicker
                                style={{ width: '100px' }}
                                color='success'
                                variant={'filled'}
                                value={dayjs(expirationDate)}
                                onChange={(newValue) => {
                                    const formattedDate = newValue.format(' YYYY-MM-DDTHH:mm:ss.SSSZ');
                                    setExpirationDate(formattedDate);
                                    handleDatePicker(formattedDate);
                                }}
                                format={'DD.MM.YYYY'}
                                ampm={false}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        variant: 'filled',
                                        color: 'success',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body'
                                        style={{ fontWeight: 'bold' }}>Begründung</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <JustificationForm draftData={props.draftData} setDraftData={props.setDraftData}
                                               label={'deadline_extension_reason'} />
                        </Grid>
                    </Grid>
                </>
            ) : null}
        </>
    );
};

export default LawyerLetterStepper;
