import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Slide,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Person from './person.js';
import Einzelunternehmen from './einzelunternehmen.js';
import Gesellschaft from './gesellschaft.js';
import Stiftung from './stiftung.js';
import * as PropTypes from 'prop-types';
import { CmxDivider } from './formComponents/utils.js';
import ApiCaller from '../../../misc/APICaller.js';
import { useNotification } from '../../../misc/notification.js';

Paper.propTypes = {
    style: PropTypes.shape({ backgroundColor: PropTypes.string }),
    children: PropTypes.node,
};

const NewCustomer = (props) => {
    const [message, setMessage] = useState({});
    const apiCaller = new ApiCaller();
    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState({});
    const { showNotification } = useNotification();
    const loadEmployees = async () => {
        const result = await apiCaller.callApi('employeesByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
        setEmployees(result.data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const result = await apiCaller.callApi('submitFormData', 'POST', formData);
        console.log(result);
        if (result.valid) {
            setIsLoading(false);
            showNotification(result.severity, result.msg);
            setFormData({});
            setCustomerType('');
            props.setCustomerUpdate(true);
            props.setOpen(false);
        } else {
            showNotification(result.severity, result.msg);
        }
    };

    const handleClose = () => {
        setCustomerType('');
        setFormData({});
        props.setOpen(false);
    };

    const [formData, setFormData] = useState({});

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        let newValue;
        if (type === 'checkbox') {
            newValue = checked;
        } else {
            newValue = value;
        }

        setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData };

            // Split the name using dot notation
            const nameParts = name.split('.');

            // Initialize the nested object structure if not already present
            let currentObject = updatedFormData;
            for (let i = 0; i < nameParts.length; i++) {
                const currentPart = nameParts[i];

                if (!currentObject[currentPart]) {
                    currentObject[currentPart] = {};
                }

                if (i === nameParts.length - 1) {
                    // Last part, update the value
                    currentObject[currentPart] = newValue;
                } else {
                    // Not the last part, traverse deeper into the object
                    currentObject = currentObject[currentPart];
                }
            }

            return updatedFormData;
        });
    };


    useEffect(() => {
        async function fetchData() {
            formData.isMandant = false;
            formData.consultantUserID = localStorage.getItem('id');
            await loadEmployees();
        }

        fetchData();
    }, []);


    useEffect(() => {
        if (!formData.isMandant) {
            setFormData(prevFormData => {
                const updatedFormData = { ...prevFormData };
                delete updatedFormData.mandatManager;
                delete updatedFormData.mandatLeader;
                return updatedFormData;
            });
        }
    }, [formData.isMandant]);

    useEffect(() => {
        console.log('FormData');
        console.log(formData);
    }, [formData]);

    const [customerType, setCustomerType] = useState('');
    const handleOptionChange = (e) => {
        setCustomerType(e.target.value);
        setFormData({
            ['type']: e.target.value,
            ['isMandant']: false,
            ['consultantUserID']: localStorage.getItem('id'),
        });
        if (e.target.value === 'Einzelunternehmen') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                entity: { type: customerType },
            }));
        }
    };

    const customerDetails = {
        'Person': <Person formData={formData} handleInputChange={handleInputChange} />,
        'Einzelunternehmen': <Einzelunternehmen formData={formData} handleInputChange={handleInputChange} />,
        'Gesellschaft': <Gesellschaft formData={formData} handleInputChange={handleInputChange}
                                      setFormData={setFormData} />,
        'Stiftung': <Stiftung formData={formData} handleInputChange={handleInputChange} />,
    };

    return (
        <>
            <Dialog
                open={props.open}
                onClose={handleClose}
                TransitionComponent={Slide}
                TransitionProps={{ direction: 'down' }}
                fullScreen={true}
                PaperProps={{
                    style: {
                        backgroundColor: '#fff',
                    },
                }}
                style={{ marginBottom: '25px' }}
            >

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <form onSubmit={handleSubmit}>
                        <div style={{ width: '1024px' }} id='box'>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '25px',
                                }}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} sm={12}>
                                        <CmxDivider title='Kontakt hinzufügen' />
                                    </Grid>
                                    <Grid container spacing={2} style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        minHeight: '100%',
                                    }}>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant='filled' color='success' fullWidth
                                                     style={{ marginTop: '15px' }}>
                                            <InputLabel id='select-label'
                                                        style={{ marginRight: '10px' }}>Art</InputLabel>
                                            <Select
                                                labelId='select-label'
                                                id='select'
                                                color='success'
                                                value={customerType}
                                                onChange={handleOptionChange}
                                                style={{ minWidth: '120px' }}
                                            >
                                                <MenuItem value='Person'>Natürliche Person</MenuItem>
                                                <MenuItem value='Einzelunternehmen'>Einzelunternehmen</MenuItem>
                                                <MenuItem value='Gesellschaft'>Gesellschaft</MenuItem>
                                                <MenuItem value='Stiftung'>Stiftung</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color='success'
                                                    checked={formData.isMandant}
                                                    onChange={handleInputChange}
                                                />
                                            }
                                            label='Mandant'
                                            name='isMandant'
                                            color='success'
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 40 }, fontWeight: 'bold' }}
                                        />
                                    </Grid>
                                    {formData.isMandant && (
                                        <>
                                            <Grid item xs={12}>
                                                <CmxDivider title='Mandantenmanagement' />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl variant='filled' color='success' fullWidth
                                                             style={{ marginTop: '15px' }}>
                                                    <InputLabel>Mandatsmanager</InputLabel>
                                                    <Select
                                                        value={formData.type === 'Gesellschaft' ? formData?.entity?.mandant_manager : (formData.mandatManager || '')}
                                                        label='mandatManager'
                                                        name={formData.type === 'Gesellschaft' ? 'entity.mandant_manager' : 'mandatManager'}
                                                        onChange={handleInputChange}
                                                    >
                                                        {employees ? (
                                                            employees.map((employee) => (
                                                                <MenuItem key={employee.id} value={employee.id}>
                                                                    {employee.title} {employee.first_name} {employee.name}
                                                                </MenuItem>
                                                            ))
                                                        ) : (
                                                            <MenuItem value={0}>Keine</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl variant='filled' color='success' fullWidth
                                                             style={{ marginTop: '15px' }}>
                                                    <InputLabel
                                                    >Mandatsverantwortlicher</InputLabel>
                                                    <Select
                                                        value={formData.type === 'Gesellschaft' ? formData?.entity?.mandant_lead : formData.mandatLeader}
                                                        label='mandatLeader'
                                                        name={formData.type === 'Gesellschaft' ? 'entity.mandant_lead' : 'mandatLeader'}
                                                        onChange={handleInputChange}
                                                    >
                                                        {employees ? (
                                                            employees.map((employee) => (
                                                                <MenuItem key={employee.id} value={employee.id}>
                                                                    {employee.title} {employee.first_name} {employee.name}
                                                                </MenuItem>
                                                            ))
                                                        ) : (
                                                            <MenuItem value={0}>Keine</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>


                                <div style={{
                                    position: 'fixed',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    backgroundColor: '#fff',
                                    padding: '10px',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                    <Button variant='text' color='success' onClick={handleClose} size='small'
                                            style={{ marginRight: '16px' }}>
                                        Abbrechen
                                    </Button>
                                    <Button type='submit' variant='contained' color='success'>
                                        Speichern
                                    </Button>
                                    {isLoading && (
                                        <div style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            zIndex: 100000,
                                            background: 'rgba(255, 255, 255, 0.9)',
                                        }}>
                                            <CircularProgress color='success' size={60} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>
                                {
                                    customerType && customerType === 'Person' ? (
                                        <Person formData={formData} handleInputChange={handleInputChange} />
                                    ) : customerType == 'Gesellschaft' ? (
                                        <Gesellschaft formData={formData} handleInputChange={handleInputChange}
                                                      setFormData={setFormData} />
                                    ) : customerType === 'Stiftung' ? (
                                        <Stiftung formData={formData} handleInputChange={handleInputChange} />
                                    ) : customerType === 'Einzelunternehmen' ? (
                                        <Einzelunternehmen formData={formData} handleInputChange={handleInputChange} />
                                    ) : null
                                }
                                {/*{customerDetails[customerType] || <p></p>}*/}
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>

        </>
    );
};

export default NewCustomer;
