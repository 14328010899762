import { Grid } from '@mui/material';
import React from 'react';
import { CmxDivider } from '../newCustomer/formComponents/utils.js';
import Personendaten from '../newCustomer/formComponents/personendaten.js';
import Anschrift from '../newCustomer/formComponents/anschrift.js';
import Contact from '../newCustomer/formComponents/contact.js';
import Bank from '../newCustomer/formComponents/bank.js';
import Finanzamt from '../newCustomer/formComponents/finanzamt.js';
import Relation from '../newCustomer/formComponents/relations.js';
import Assets from '../newCustomer/formComponents/assets.js';
import TaxCompliance from '../newCustomer/formComponents/taxCompliance.js';
import Fokus from '../newCustomer/formComponents/fokus.js';
import Lead from '../newCustomer/formComponents/lead.js';
import Internals from '../newCustomer/formComponents/internals.js';
import MandantManagement from '../newCustomer/formComponents/mandantManagement.js';

const LoadPerson = (props) => {
    return (
        <>
            <div style={{ padding: '20px', width: '1024px' }} id='box'>
                <Grid container spacing={2} justify='center' style={{ marginBottom: '25px' }}>
                    {/******************************************Category Divider****************************************/}
                    {/***********************************************+++++++++++****************************************/}

                    {/***********************************************Mandant/Managemement********************************/}
                    <Grid item xs={12}>
                        <CmxDivider title='Status' />
                    </Grid>
                    <MandantManagement formData={props.customerData} handleInputChange={props.handleInputChange} />

                    {/***********************************************Personendaten****************************************/}

                    <Grid item xs={12}>
                        <CmxDivider title='Personendaten' />
                    </Grid>
                    <Personendaten formData={props.customerData} load='true'
                                   handleInputChange={props.handleInputChange} />

                    {/**********************************************Kontaktdaten***************************************/}
                    <Grid item xs={12}>
                        <CmxDivider title='Kontaktdaten' />
                    </Grid>
                    <Anschrift formData={props.customerData} handleInputChange={props.handleInputChange} />
                    <Contact formData={props.customerData} handleInputChange={props.handleInputChange} />

                    {/**********************************************Bankkonto***************************************/}
                    <Grid item xs={12}>
                        <CmxDivider title='Bankverbindung' />
                    </Grid>
                    <Bank formData={props.customerData} handleInputChange={props.handleInputChange} />
                    {/******************************************Category Divider***************************************
                     **********************************************+++++++++++***************************************/}
                    <CmxDivider size='large' margin={50} />


                    {/***********************************************Steuerdaten***************************************/}
                    <CmxDivider title='Steuerdaten' />
                    <Finanzamt formData={props.customerData} handleInputChange={props.handleInputChange}
                               loading={true} />

                    {/****************************************Bezugspersonen***************************************/}
                    <CmxDivider title='Beziehungen' />
                    <Relation formData={props.customerData} handleInputChange={props.handleInputChange} />

                    {/**********************************************Assets***************************************/}
                    <CmxDivider title='Assets' />
                    <Assets formData={props.customerData} handleInputChange={props.handleInputChange} />


                    {/****************************************Tax Compliance***************************************/}
                    <CmxDivider title='Tax Compliance' />
                    <TaxCompliance formData={props.customerData} handleInputChange={props.handleInputChange} />

                    {/*****************************************Category Divider***************************************
                     **********************************************+++++++++++***************************************/}
                    <CmxDivider size='large' margin={50} />

                    {/**********************************************Themenschwerpunkte***************************************/}
                    <CmxDivider title='Themenschwerpunkte' />
                    <Fokus formData={props.customerData} handleInputChange={props.handleInputChange} />

                    {/**********************************************Lead***************************************/}
                    <CmxDivider title='Lead' />
                    <Lead formData={props.customerData} handleInputChange={props.handleInputChange} />

                    {/********************************************** Kanzleiinterne Informationen
                     ***************************************/}
                    <CmxDivider title='Kanzleiinterne Informationen' />
                    <Internals formData={props.customerData} handleInputChange={props.handleInputChange}
                               setCustomerData={props.setCustomerData} />
                </Grid>
            </div>
        </>
    );
};
export default LoadPerson;

