import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ApiCaller from '../../../../misc/APICaller.js';
import { companyType } from '../../../../misc/auto_data/companyTypes.js';
import { formatEntityString } from '../../helper.js';

const AddClient = (props) => {
        const [knownClient, setKnownClient] = useState(true);
        const [clientList, setClientList] = useState([]);
        const [gender, setGender] = useState('');
        const [title, setTitle] = useState('');
        const [firstName, setFirstName] = useState('');
        const [lastName, setLastName] = useState('');
        const [birthdate, setBirthdate] = useState('');
        const apiCaller = new ApiCaller();
        const [selectedOption, setSelectedOption] = useState('');
        const [shares, setShares] = useState('');
        const [value_eur, setValue_eur] = useState('');
        const [newType, setNewType] = useState('');
        const [authorityToAct, setAuthorityToAct] = useState('');
        const [associates, setAssociates] = useState([]);
        const [nameFull, setNameFull] = useState('');
        const [name, setName] = useState('');
        const [tempID, setTempID] = useState(0);
        const [clean, setClean] = useState(false);

        const clearNewContactForm = (includingRole = true) => {
            setFirstName('');
            setLastName('');
            setGender('');
            setTitle('');
            setBirthdate('');
            setNewType('');
            setNameFull('');
            setName('');
            setValue_eur('');
            setShares('');
            setAuthorityToAct('');
            if (includingRole) {
                props.setRole('');
            }
        };

        useEffect(() => {
                props.handleInputChange({
                    target: {
                        name: 'associates',
                        value: associates ? associates : [],
                    },
                });
                if (clean) {
                    clearNewContactForm();
                    setSelectedOption(null);
                    setClean(false);
                }


            }, [associates],
        );

        const handleAddShareholder = () => {
            console.log(companyType);
            const entityType = newType === 'person' ? newType : (companyType.find(item => item.name == newType)?.label || '');
            //const entityType = newType;
            const NewAssociates = {
                ...(knownClient
                    ? {
                        ...selectedOption,
                    }
                    : {
                        tempID: tempID,
                        first_name: firstName,
                        name: lastName,
                        gender: gender,
                        title: title,
                        birth_date: birthdate,
                        name_full: nameFull,
                        type: entityType,

                    }),
                asset: {
                    shares: shares,
                    value_eur: value_eur,
                    authorityToAct: authorityToAct,
                    ...(newType ? { type: newType } : {}),
                    role: props.role,
                },
            };
            setAssociates((prevAssociates) => {
                // Create an array with the NewAssociates object
                const updatedAssociates = [...prevAssociates, NewAssociates];
                setClean(true);
                return updatedAssociates;
            });
            setTempID((prevKey) => prevKey + 1);

        };

        useEffect(() => {
            if (props.removeIndex !== null) {
                setAssociates(prevAssociates => [
                    ...prevAssociates.slice(0, props.removeIndex),
                    ...prevAssociates.slice(props.removeIndex + 1),
                ]);
            }
            props.setRemoveIndex(null);
        }, [props.removeIndex]);

        useEffect(() => {
            const fetchData = async () => {
                try {
                    const clients = await apiCaller.callApi('getClientsByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
                    console.log(clients);
                    clients.data.persons = clients.data.persons.map((client) => ({
                        ...client,
                        type: client.hasOwnProperty('type') ? 'person' : 'person',
                    }));
                    const allClients = [...clients.data.persons, ...clients.data.corporations];
                    const sortedClients = allClients.sort((a, b) => {
                        const sortingAttributeA = a.type !== 'person' ? a.name_full || a.name : a.name;
                        const sortingAttributeB = b.type !== 'person' ? b.name_full || b.name : b.name;
                        return sortingAttributeA.localeCompare(sortingAttributeB);
                    });
                    setClientList(sortedClients);
                    console.log('SORTED CLIENTS');
                    console.log(sortedClients);
                } catch (error) {
                    console.error(error);
                }
            };

            fetchData();
            setAssociates(props.data.associates ? props.data.associates : []);
        }, []);


        return (
            <>
                {props.role &&
                    <Grid container style={{ marginTop: '30px' }}>
                        {knownClient && clientList.length > 0 ? (
                            <div style={{ width: '100%', marginTop: '20px' }}>
                                <Grid item xs={12} style={{ marginLeft: '10px', marginBottom: '-20px' }}>
                                    <Typography variant='h6'>Bestandssuche</Typography>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <Autocomplete
                                            defaultValue=''
                                            options={clientList}
                                            getOptionLabel={(option) => {
                                                //return `${option.title ? option.title + ' ' : ''}${option.first_name} ${option.name} ${option.birth_date ? ', ' + format(new Date(option.birth_date), 'dd.MM.yyyy') : ''}`;
                                                return formatEntityString(option);
                                            }}
                                            value={selectedOption}
                                            onChange={(event, newValue) => setSelectedOption(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label='Bestandssuche'
                                                    name='mandantSearch'
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Typography>
                                    <Button variant='text' color='success'
                                            onClick={() => {
                                                setKnownClient(false);
                                                setSelectedOption(null);
                                            }}>Neuen Beteiligten anlegen</Button>
                                </Typography>
                            </div>
                        ) : (
                            <div style={{ width: '100%', marginTop: '10px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ marginLeft: '10px', marginBottom: '-20px' }}>
                                        <Typography variant='h6'>Neuer Beteiligter</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant='filled' color='success' fullWidth
                                                     style={{ marginTop: '15px' }}>
                                            <InputLabel>Art</InputLabel>
                                            <Select
                                                value={newType}
                                                label='Art'
                                                name='type'
                                                onChange={(event) => setNewType(event.target.value)}
                                            >
                                                <MenuItem index={-2} value='person'>Natürliche Person</MenuItem>
                                                {
                                                    companyType.map((item, index) => {
                                                        return (
                                                            <MenuItem key={index} value={item.name}>{item.name}</MenuItem>);
                                                    })
                                                }
                                                <MenuItem index={-1} value=''>Keine</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {newType && newType === 'person' ? (
                                        < >
                                            < Grid item xs={12} sm={6}>
                                                <FormControl variant='filled' color='success' fullWidth
                                                             style={{ marginTop: '15px' }}>
                                                    <InputLabel>Anrede</InputLabel>
                                                    <Select
                                                        value={gender}
                                                        label='gender'
                                                        name='gender'
                                                        onChange={(event) => setGender(event.target.value)}
                                                    >
                                                        <MenuItem value={'male'}>Herr</MenuItem>
                                                        <MenuItem value={'female'}>Frau</MenuItem>
                                                        <MenuItem value={'diverse'}>-</MenuItem>
                                                        <MenuItem value=''>Keine</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl variant='filled' color='success' fullWidth
                                                             style={{ marginTop: '15px' }}>
                                                    <InputLabel>Titel</InputLabel>
                                                    <Select
                                                        value={title}
                                                        label='title'
                                                        name='title'
                                                        onChange={(event) => setTitle(event.target.value)}
                                                    >
                                                        <MenuItem value={'Dr.'}>Dr.</MenuItem>
                                                        <MenuItem value={'Prof.'}>Prof.</MenuItem>
                                                        <MenuItem value={'Prof. Dr.'}>Prof. Dr.</MenuItem>
                                                        <MenuItem value=''>Keinen</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label='Vorname'
                                                    name='first_name'
                                                    value={firstName}
                                                    onChange={(event) => setFirstName(event.target.value)}
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label='Nachname'
                                                    name='last_name'
                                                    value={lastName}
                                                    onChange={(event) => setLastName(event.target.value)}
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label='Geburtsdatum'
                                                    name='birth_date'
                                                    value={birthdate}
                                                    onChange={(event) => setBirthdate(event.target.value)}
                                                    type='date'
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                />
                                            </Grid>

                                        </>) : newType && (
                                        <>
                                            <Grid item xs={9}>
                                                <TextField
                                                    label='Firmenname'
                                                    name='nameFull'
                                                    value={nameFull}
                                                    onChange={(event) => setNameFull(event.target.value)}
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    label='Namenskürzel'
                                                    helperText={'Kürzel wie IHK oder ähnliches'}
                                                    name='name'
                                                    value={name}
                                                    onChange={(event) => setName(event.target.value)}
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <Typography>
                                            <Button variant='text' color='success'
                                                    onClick={() => {
                                                        setKnownClient(true);
                                                        clearNewContactForm(false);
                                                    }}>Aus Bestand wählen
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        {
                            props.role !== 'managing_director' &&
                            <>
                                <Grid container spacing={2}>
                                    {props.role !== 'sole_proprietor' &&
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label='Anteile in %'
                                                name='shares'
                                                value={shares}
                                                onChange={(event) => setShares(event.target.value)}
                                                fullWidth
                                                type='number'
                                                margin='normal'
                                                color='success'
                                                variant='filled'
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={props.role === 'sole_proprietor' ? 12 : 6}>
                                        <TextField
                                            label='Schätzwert der Gesellschaftsanteile in EUR '
                                            name='value_eur'
                                            value={value_eur}
                                            onChange={(event) => setValue_eur(event.target.value)}
                                            fullWidth
                                            margin='normal'
                                            color='success'
                                            variant='filled'
                                            type='number'
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {props.role !== 'shareholder' && props.role !== 'limited_partner' && props.role !== 'stockholder' &&
                            <Grid item xs={12}>
                                <FormControl variant='filled' color='success' fullWidth
                                             style={{ marginTop: '15px' }}>
                                    <InputLabel>Vertretungsbefugnis</InputLabel>
                                    <Select
                                        value={authorityToAct}
                                        label='Vertretungsbefugnis'
                                        name='authorityToAct'
                                        onChange={(event) => setAuthorityToAct(event.target.value)}
                                    >
                                        <MenuItem index={0}
                                                  value='Einzelvertretungsbefugnis mit Befreiung von den Beschränkungen des § 181 BGB'>Einzelvertretungsbefugnis
                                            mit Befreiung von den Beschränkungen des § 181 BGB</MenuItem>
                                        <MenuItem index={1}
                                                  value='Einzelvertretungsbefugnis ohne Befreiung von den Beschränkungen des § 181 BGB'>Einzelvertretungsbefugnis
                                            ohne Befreiung von den Beschränkungen des § 181 BGB</MenuItem>
                                        <MenuItem index={2}
                                                  value='Gemeinschaftsvertretungsbefugnis'>Gemeinschaftsvertretungsbefugnis</MenuItem>
                                        <MenuItem index={2}
                                                  value=''>Keine</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        <Grid container justify='center' align='center' spacing={2} style={{ marginTop: '15px' }}>
                            <Grid item xs={12}>
                                <Button variant='contained' color='success' onClick={handleAddShareholder} size='large'>
                                    Hinzufügen
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>}
            </>
        );
    }
;


export default AddClient;