import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AGBs from './agbs.js';
import Impressum from './impressum.js';
import Login from '../frontend/login.js';
import Register from '../frontend/register.js';
import Dashboard from '../frontend/dashboard.js';
import Home from '../frontend/userArea/home.js';
import About from './about.js';
import Tasks from '../frontend/userArea/tasks.js';
import Projects from '../frontend/userArea/projects.js';
import Documents from '../frontend/userArea/documents.js';
import Customer from '../frontend/userArea/customer.js';
import Mailbox from '../frontend/userArea/mailbox.js';
import Forms from '../frontend/userArea/forms.js';
import Settings from '../frontend/userArea/settings.js';
import OnboardingForm from '../forms/Onboarding.js';
import Assistant from '../frontend/userArea/assistant.js';
import Drafts from '../frontend/userArea/drafts.js';
import Appointments from '../frontend/userArea/appointments.js';

const LinkRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to='/dashboard/home' />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='register' element={<Register />} />
            <Route path='admin' element={<Login />} />
            <Route path='login' element={<Login />} />
            <Route path='about' element={<About />} />
            <Route path='AGBs' element={<AGBs />} />
            <Route path='Impressum' element={<Impressum />} />
            <Route path='onboarding' element={<OnboardingForm />} />
            {/*<Route path="onboarding/:eid*" element={<OnboardingForm/>}/>*/}
            <Route path='dashboard' element={<Dashboard />}>
                <Route index element={<Home />} />
                <Route path='home' element={<Home />} />
                <Route path='tasks' element={<Tasks />} />
                <Route path='projects' element={<Projects />} />
                <Route path='documents' element={<Documents />} />
                <Route path='customer' element={<Customer />} />
                <Route path='mailbox' element={<Mailbox />} />
                <Route path='forms' element={<Forms />} />
                <Route path='settings' element={<Settings />} />
                <Route path='assistant' element={<Assistant />} />
                <Route path='drafts' element={<Drafts />} />
                <Route path='appointments' element={<Appointments />} />
            </Route>
        </Routes>
    );
};
export default LinkRouter;