import { Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

const Anschrift = (props) => {
    const location = props.formData.location || {};
    const [options, setOptions] = useState([]);
    const provider = new OpenStreetMapProvider({
        params: {
            'accept-language': 'de',
            countrycodes: 'de',
            addressdetails: 1,

        },
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        const updatedLocation = {
            ...location,
            [props.prefix ? `${props.prefix}${name}` : name]: value,
        };

        props.handleInputChange({ target: { name: 'location', value: updatedLocation } });
    };

    const handleAutocompleteChange = (event, value) => {
        if (value) {
            const selectedOption = options.find((option) => option === value);

            provider.search({ query: selectedOption }).then((results) => {
                if (results.length > 0) {
                    const { postcode, city, road } = results[0].raw.location;

                    props.handleInputChange({
                        target: { name: 'zipCode', value: postcode },
                    });
                    props.handleInputChange({
                        target: { name: 'city', value: city },
                    });

                    props.handleInputChange({
                        target: { name: 'street', value: road },
                    });
                }
            });
        }
    };

    const handleInputField = (name) => (event) => {
        const { value } = event.target;
        const updatedLocation = {
            ...location,
            [name]: value,
        };
        props.handleInputChange({
            target: { name: 'location', value: updatedLocation },
        });
    };

    return (
        <>

            {/*<Autocomplete
                    options={options}
                    loading={true} // Set the loading prop based on the loading state
                    onInputChange={handleInputChange}
                    onChange={handleAutocompleteChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Straße'
                            name={'street'}
                            type='text'
                            value={props.formData.street}
                            onChange={props.handleInputChange}
                            fullWidth
                            margin='normal'
                            color='success'
                            variant='filled'
                        />
                    )}
                />*/}
            <Grid item xs={12} sm={6}>
                <TextField
                    label='PLZ'
                    name='zipcode'
                    value={props.formData.location?.zipcode}
                    onChange={handleInputField('zipcode')}
                    fullWidth
                    type='number'
                    inputProps={{ maxLength: 5 }}
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label='Ort'
                    name='city'
                    value={props.formData.location?.city}
                    onChange={handleInputField('city')}
                    fullWidth
                    type='text'
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
            <Grid item xs={10}>
                <TextField
                    label='Straße'
                    name='street'
                    value={props.formData.location?.street}
                    onChange={handleInputField('street')}
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    label='Nr'
                    name='street_number'
                    value={props.formData.location?.street_number}
                    onChange={handleInputField('street_number')}
                    fullWidth
                    type='text'
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
        </>
    );
};


export default Anschrift;
