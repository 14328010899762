import {
    Button,
    Dialog,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddCircleOutline } from '@mui/icons-material';
import ApiCaller from '../../../../misc/APICaller.js';
import { useNotification } from '../../../../misc/notification.js';

const NewContact = (props) => {
    const [openDialog, setOpenDialog] = useState(false); // State for controlling the dialog
    const [entity, setEntity] = useState({});
    const apiCaller = new ApiCaller();
    const { showNotification } = useNotification();
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEntity({ ...entity, [name]: value });

    };
    useEffect(() => {
        console.log('entity');
        console.log(entity);
    }, [entity]);

    const genderOptions = [
        { value: 'male', label: 'Herr' },
        { value: 'female', label: 'Frau' },
        { value: 'diverse', label: '-' },
        { value: '', label: 'Keine' },
    ];

    const titleOptions = [
        { value: 'Dr.', label: 'Dr.' },
        { value: 'Prof.', label: 'Prof.' },
        { value: 'Prof. Dr.', label: 'Prof. Dr.' },
        { value: '', label: 'Keinen' },
    ];


    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };


    async function saveContact() {
        const response = await apiCaller.callApi('addEntity', 'POST', {
            client_data: entity,
            type: 'client',
            user_id: localStorage.getItem('id'),
        });
        console.log('get response data');
        console.log(response.data);

        if (response.data) {
            props.setDraftData({ ...props.draftData, [props.question.setValues[0]]: response.data });
            const res = await apiCaller.callApi('getClientsByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
            console.log(res);
            const values = res.data.persons.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            }).map((person) => {
                const label = `${person.title ? person.title + ' ' : ''}${person.first_name} ${person.name} `;
                const value = person.id;
                return {
                    label,
                    value,
                };
            });
            props.setTextFieldOptions(values);
            setEntity({});
        }
        showNotification('success', 'Kontakt erfolgreich gespeichert.');
        setOpenDialog(false);
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <IconButton onClick={handleDialogOpen}>
                    <AddCircleOutline color='success' sx={{ fontSize: 30 }} style={{ marginRight: '5px' }} />
                    <Typography variant='body'>Neuer
                        Kontakt</Typography>
                </IconButton>
            </Grid>

            {/* Dialog */}
            <Dialog open={openDialog} onClose={handleDialogClose} maxWidth='sm' fullWidth>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>Neuer Kontakt</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                                <InputLabel id='salutation-id'>Anrede</InputLabel>
                                <Select
                                    defaultValue=''
                                    label='Anrede'
                                    name='gender'
                                    value={genderOptions.find(option => option.value === entity['gender'])?.value || ''}
                                    onChange={handleInputChange}
                                >
                                    {genderOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                                <InputLabel>Titel</InputLabel>
                                <Select
                                    defaultValue=''
                                    value={titleOptions.find(option => option.value === entity['title'])?.value || ''}
                                    label='Titel'
                                    name='title'
                                    onChange={handleInputChange}
                                >
                                    {titleOptions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                label='Vorname'
                                name='first_name'
                                //helperText={!clientData.first_name && 'Diese Information muss gegeben sein.'} // Display the helper text when the field is empty
                                value={entity['first_name'] || ''}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                margin='normal'
                                color='success'
                                variant='filled'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label='Nachname'
                                name='name'
                                value={entity['name'] || ''}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                margin='normal'
                                color='success'
                                variant='filled'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={() => {
                                    setEntity({});
                                    setOpenDialog(false);
                                }}
                                        variant='outlined'
                                        color='success'>
                                    zurück
                                </Button>
                                <Button
                                    onClick={() => saveContact()}
                                    variant='contained'
                                    color='success'>
                                    Speichern
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export default NewContact;