import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField/index.js';
import Autocomplete from '@mui/material/Autocomplete/index.js';
import ApiCaller from '../../../../misc/APICaller.js';
import { Typography } from '@mui/material';

function AutocompleteWithTextField(props) {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const apiCaller = new ApiCaller();
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [isFocused, setIsFocused] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null); // Set initial value to null
    const [message, setMessage] = useState('');
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    useEffect(() => {
        console.log('selectedOption');
        console.log(selectedOption);
    }, [setSelectedOption]);


    const loadEntitytTaxAuthority = async () => {
        const taxAuthority = await apiCaller.callApi('getTaxAuthorityByEntity', 'GET', {}, { entity_id: props.draftData[props.question.children[props.index].preLoadVariable] });
        const selectedTaxAuthority = `${taxAuthority.data.name_full}\n${taxAuthority.data.location[0].street} ${taxAuthority.data.location[0].street_number}\n${taxAuthority.data.location[0].zipcode} ${taxAuthority.data.location[0].city}`;
        if (selectedTaxAuthority) {
            setSelectedOption(taxAuthority.data);
            props.setDraftData((prevData) => ({
                ...prevData,
                [props.question.label]: selectedTaxAuthority,
                [props.question.label + '_id']: taxAuthority.data.id,
            }));
            setMessage('Hinterlegtes Finanzamt des Kontakts');
        }
    };

    useEffect(() => {
            const fetchData = async () => {
                try {
                    setMessage('');
                    if (props.draftData[props.question.label + '_id'] && props.draftData[props.question.label + '_id'] != '') {
                        const authority = await apiCaller.callApi('getTaxAuthority', 'GET', {}, { entity_id: props.draftData[props.question.label + '_id'] });
                        console.log(authority);
                        if (authority.data !== null) {
                            const selectedTaxAuthority = `${authority.data[0].name_full}\n${authority.data[0].location[0].street} ${authority.data[0].location[0].street_number}\n${authority.data[0].location[0].zipcode} ${authority.data[0].location[0].city}`;
                            setSelectedOption(authority.data[0]);
                            props.setDraftData((prevData) => ({
                                ...prevData,
                                [props.question.label]: selectedTaxAuthority,
                            }));
                        } else {
                            if (props.question.children[props.index].preLoadVariable) {
                                if (props.draftData[props.question.children[props.index].preLoadVariable]) {
                                    await loadEntitytTaxAuthority();
                                }
                            }
                        }
                    } else {
                        if (props.question.children[props.index].preLoadVariable) {
                            if (props.draftData[props.question.children[props.index].preLoadVariable]) {
                                await loadEntitytTaxAuthority();
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData();
        }, [],
    );


    const handleAutocompleteChange = (event, value) => {
        setMessage('');
        if (value) {
            const address = value.location[0];
            const selectedTaxAuthority = `${value.name_full}\n${address.street} ${address.street_number}\n${address.zipcode} ${address.city}`;
            console.log(selectedTaxAuthority);
            props.setDraftData((prevData) => ({
                ...prevData,
                [props.question.label]: selectedTaxAuthority,
                [props.question.label + '_id']: value.id,
            }));
            setSelectedOption(value); // Update selectedOption with the selected value
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiCaller.callApi('taxAuthorityAutocomplete', 'GET', {}, { query: query });
                console.log(response.data);
                setResults(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        const newDebounceTimer = setTimeout(fetchData, 300);
        setDebounceTimer(newDebounceTimer);
        return () => {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
        };
        //fetchData();
    }, [query]);

    return (
        <div>
            <Autocomplete
                options={results} // Autocomplete options (suggestions)
                noOptionsText={'Finanzamtname eingeben'}
                getOptionLabel={(option) => option.name_full || ''}
                value={selectedOption || null}
                renderOption={(props, option) => (
                    <li {...props}>
                                <span>{option.name_full},{' '}
                                    {option.location[0]?.street}{' '}
                                    {option.location[0]?.street_number},{' '}
                                    {option.location[0]?.zipcode}{' '}
                                    {option.location[0]?.zipcode.city}
                                </span>
                    </li>
                )}
                onChange={(event, newValue) => {
                    setSelectedOption(newValue);
                    handleAutocompleteChange(event, newValue);
                }}

                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={'Name des Finanzamts'}
                        variant='filled'
                        onFocus={handleFocus}
                        value={selectedOption ? selectedOption.name_full : ''}
                        color='success'
                        onBlur={handleBlur}
                        onChange={(e) => setQuery(e.target.value)} // Update query as user types
                        defaultValue={isFocused ? 'Finanzamt eingeben' : ''}
                    />
                )}
            />
            <Typography variant='body2'>{message}</Typography>
        </div>
    );
}

export default AutocompleteWithTextField;
