import React, { createContext, useContext, useEffect, useState } from 'react';
import { Alert, CircularProgress, IconButton } from '@mui/material';
import { CloseSharp } from '@mui/icons-material';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        let timer;
        if (notification) {
            timer = setTimeout(() => {
                hideNotification();
            }, notification.time);
        }
        return () => clearTimeout(timer);
    }, [notification]);

    const showNotification = (severity, text, time = 6000, isLoading = false) => {
        setNotification({ severity, text, time, isLoading });
    };

    const hideNotification = () => {
        setNotification(null);
    };

    return (
        <NotificationContext.Provider value={{ showNotification, hideNotification }}>
            {children}
            {notification && (
                <div className='box'>
                    <Alert

                        style={{
                            position: 'fixed',
                            bottom: '10%',
                            left: '-100%',
                            animation: 'slide-in-notification 1s forwards',
                            zIndex: 9999,
                        }}
                        action={
                            <IconButton
                                aria-label='close'
                                color='inherit'
                                size='big'
                                onClick={() => {
                                    hideNotification();
                                }}
                            >
                                <CloseSharp fontSize='inherit' />
                            </IconButton>
                        }
                        variant='filled'
                        severity={notification.severity}
                    >
                        {notification.isLoading ? <CircularProgress color='success' /> : ''} {notification.text}
                    </Alert>
                </div>
            )}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);
