import * as React from 'react';
import { useState } from 'react';
import ApiCaller from '../../../misc/APICaller.js';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
} from '@mui/material';
import { Modal } from '@mui/material';
import { handleLogout } from '../../../misc/helper.js';
import UploadDropzone from './upload.js';
import leads from './leads.js';
import focusData from './focus.js';
import CircularProgress from '@mui/material/CircularProgress/index.js';

const steps = ['Anrede', 'Rechnungsstellung', 'Schwerpunkt', 'Lead', 'Upload'];


function StepperForm() {
    const [activeStep, setActiveStep] = useState(0);
    const [salutation, setSalutation] = useState('');
    const [title, setTitle] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [postcode, setPostcode] = useState('');
    const [city, setCity] = useState('');
    const [focus, setFocus] = useState('');
    const [lead, setLead] = useState('');
    const [file, setFile] = useState(null);
    const [textInfo, setTextInfo] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [modalState, setModalState] = useState(true);
    const apiCaller = new ApiCaller();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setSalutation(null);
        setTitle(null);
        setStreet(null);
        setNumber(null);
        setPostcode(null);
        setCity(null);
        setFocus(null);
        setLead(null);
        setFile(null);
    };

    const handleSalutationChange = (event) => {
        setSalutation(event.target.value);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleStreetChange = (event) => {
        setStreet(event.target.value);
    };

    const handleNumberChange = (event) => {
        setNumber(event.target.value);
    };

    const handlePostcodeChange = (event) => {
        setPostcode(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleFocus = (newFocus) => {
        setFocus(newFocus);
        handleNext();
    };

    const handleLead = (newLead) => {
        setLead(newLead);
        handleNext();
    };

    const handleTextChange = (event) => {
        if (event.target.value.length > 1000) {
            // Display an error message or disable submission, etc.
        } else {
            setTextInfo(event.target.value);
        }
    };


    const uploadData = () => {
        setIsLoading(true);
        handleNext();

        setTimeout(async () => {
            const result = await apiCaller.callApi('submit_onboarding_data', 'POST', {
                title: title,
                salutation: salutation,
                street: street,
                number: number,
                postcode: postcode,
                city: city,
                focus: focus,
                lead: lead,
                textInfo: textInfo,
                id: localStorage.getItem('id'),
            }, {});
            console.log(result.msg);
            setModalState(false);
            setIsLoading(false);

        }, 1000);
    };

    return (
        <>
            <Modal
                open={modalState}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
                disableAutoFocus={true}
            >
                {modalState &&
                    <Slide direction='down' in={true} timeout={1000}>
                        <div style={{
                            backgroundColor: 'white',
                            width: '1024px',
                            minHeight: '650px',
                            position: 'relative',
                        }} className='box'>
                            <Box sx={{ width: '100%', marginTop: '50px' }}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label} sx={{
                                            '& .MuiStepLabel-root .Mui-completed': {
                                                color: '#66bb6a', // circle color (COMPLETED)
                                            },
                                            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                                {
                                                    color: 'grey', // Just text label (COMPLETED)
                                                },
                                            '& .MuiStepLabel-root .Mui-active': {
                                                color: '#388e3c',
                                                fontSize: '1.2em',// circle color (ACTIVE)
                                                transform: 'scale(1.2)',
                                                fontWeight: 'bold',
                                            },
                                            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                                {
                                                    color: 'black',
                                                    fontWeight: 'bold',
                                                    fontSize: '1.2em',
                                                },

                                        }} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <StepLabel style={{ textAlign: 'center' }}>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    {activeStep === steps.length ? (
                                        <Box sx={{ marginTop: '50px' }}>
                                            {isLoading && (
                                                <CircularProgress color='success' size={60} />

                                            )}
                                        </Box>
                                    ) : (
                                        <Box sx={{ marginTop: '50px' }}>
                                            {activeStep === 0 && (
                                                <>
                                                    <Typography sx={{
                                                        mt: 2,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }} variant='h5'>Wie dürfen wir Sie ansprechen?</Typography>
                                                    <Typography variant='body1' sx={{
                                                        color: 'text.secondary',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>Bitte auswählen</Typography>
                                                    <Box sx={{ mt: 4 }}>
                                                        <FormControl sx={{ mr: 2 }} color='success'>
                                                            <InputLabel id='gender-label'>Anrede</InputLabel>
                                                            <Select
                                                                labelId='gender-label'
                                                                id='gender'
                                                                value={salutation}
                                                                label='Geschlecht'
                                                                defaultValue='none'
                                                                onChange={handleSalutationChange}
                                                            >
                                                                <MenuItem value='m'>Herr</MenuItem>
                                                                <MenuItem value='f'>Frau</MenuItem>
                                                                <MenuItem value='d'>-</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Bitte wählen Sie eine Anrede aus.
                                                            </FormHelperText>
                                                        </FormControl>

                                                        <FormControl
                                                            color='success'
                                                        >
                                                            <InputLabel id='title-label'>Titel</InputLabel>
                                                            <Select
                                                                labelId='title-label'
                                                                id='title'
                                                                value={title}
                                                                defaultValue='none'
                                                                label='Titel'
                                                                onChange={handleTitleChange}
                                                            >

                                                                <MenuItem value='none'>-</MenuItem>
                                                                <MenuItem value='dr'>Dr.</MenuItem>
                                                                <MenuItem value='prof'>Prof. Dr.</MenuItem>
                                                            </Select>
                                                            <FormHelperText>Titel falls gegeben.</FormHelperText>
                                                        </FormControl>
                                                    </Box>
                                                </>
                                            )}
                                            {activeStep === 1 && (
                                                <>
                                                    <Typography sx={{
                                                        mt: 2,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }} variant='h5'>Wie lautet Ihre Rechnungsadresse?</Typography>
                                                    <Typography variant='body1' sx={{
                                                        color: 'text.secondary',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>Bitte vollständig angeben</Typography>
                                                    <Box sx={{ mt: 4 }}>
                                                        <TextField
                                                            id='street'
                                                            name='street'
                                                            label='Straße'
                                                            color='success'
                                                            value={street}
                                                            onChange={handleStreetChange}
                                                            sx={{ width: 'calc(2/3*95%)' }}

                                                        />
                                                        <TextField
                                                            type='number'
                                                            id='number'
                                                            name='number'
                                                            color='success'
                                                            label='Nr.'
                                                            value={number}
                                                            onChange={handleNumberChange}
                                                            sx={{ width: 'calc(1/3*95%)', ml: 2 }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <TextField
                                                            type='tel'
                                                            inputProps={{ maxLength: 5 }}
                                                            id='postcode'
                                                            name='postcode'
                                                            color='success'
                                                            label='Postleitzahl'
                                                            value={postcode}
                                                            onChange={handlePostcodeChange}
                                                            sx={{ mt: 2 }}
                                                        />
                                                        <TextField
                                                            id='city'
                                                            name='city'
                                                            color='success'
                                                            label='Stadt'
                                                            value={city}
                                                            onChange={handleCityChange}
                                                            sx={{ ml: 2, mt: 2 }}
                                                        />
                                                    </Box>
                                                </>
                                            )}
                                            {activeStep === 2 && (
                                                <>
                                                    <Typography sx={{
                                                        mt: 2,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }} variant='h5'>Welcher unserer Schwerpunkte ist für Sie primär
                                                        relevant?</Typography>
                                                    <Typography variant='body1' sx={{
                                                        color: 'text.secondary',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>Bitte anklicken</Typography>
                                                    <Box sx={{
                                                        mt: 4,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        {focusData.map((item, index) => (
                                                            <Button
                                                                key={index}
                                                                className='focus_button'
                                                                variant='outlined'
                                                                color='success'
                                                                style={{
                                                                    margin: '0.5em',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                }}
                                                                onClick={(event) => handleFocus(event.currentTarget.querySelector('h3').innerText)}>
                                                                <div style={{
                                                                    height: '80%',
                                                                    width: '80%',
                                                                    marginBottom: '0.5em',
                                                                }}>
                                                                    <img
                                                                        src={`${process.env.PUBLIC_URL}/img/${item.image}`}
                                                                        style={{ maxHeight: '100%' }} />
                                                                </div>
                                                                <div>
                                                                    <h3 style={{ margin: 0 }}>{item.name}</h3>
                                                                </div>
                                                            </Button>
                                                        ))}
                                                    </Box>
                                                </>
                                            )}

                                            {activeStep === 3 && (
                                                <>
                                                    <Typography sx={{
                                                        mt: 2,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }} variant='h5'> Wie sind Sie auf uns aufmerksam
                                                        geworden?</Typography>
                                                    <Typography variant='body1' sx={{
                                                        color: 'text.secondary',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>Bitte anklicken</Typography>
                                                    <Box sx={{
                                                        mt: 4,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        {leads.map((item, index) => (
                                                            <Button
                                                                key={index}
                                                                className='focus_button'
                                                                variant='outlined'
                                                                color='success'
                                                                style={{
                                                                    margin: '0.5em',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                }}
                                                                onClick={(event) => handleLead(event.currentTarget.querySelector('h3').innerText)}>
                                                                <div style={{
                                                                    height: '80%',
                                                                    width: '80%',
                                                                    marginBottom: '0.5em',
                                                                }}>
                                                                    <img
                                                                        src={`${process.env.PUBLIC_URL}/img/${item.image}`}
                                                                        style={{ maxHeight: '100%' }} />
                                                                </div>
                                                                <div>
                                                                    <h3 style={{ margin: 0 }}>{item.name}</h3>
                                                                </div>
                                                            </Button>

                                                        ))}
                                                    </Box>
                                                </>
                                            )}

                                            {activeStep === 4 && (
                                                <>
                                                    <Typography sx={{
                                                        mt: 2,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }} variant='h5'>Informationen & Unterlagen</Typography>
                                                    <Typography variant='body1' sx={{
                                                        color: 'text.secondary',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                    }}>Wenn Sie uns im Vorfeld weitere Informationen für Ihren Termin
                                                        mitteilen möchten, geben Sie diese gerne hier ein. Sie können
                                                        ergänzend oder alternativ direkt hier Unterlagen hochladen.
                                                        Selbstverständlich ist beides auch später noch
                                                        möglich.</Typography>
                                                    <Box sx={{
                                                        marginTop: '20px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        <Typography sx={{
                                                            mt: 2,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }} variant='h6'>Unterlagen hochladen</Typography>
                                                        <Box sx={{ marginBottom: '20px' }}>
                                                            <UploadDropzone />
                                                            <Typography sx={{
                                                                mt: 2,
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                marginTop: '30px',
                                                            }} variant='h6'>Zusätzliche Informationen</Typography>
                                                            <TextField
                                                                label='Notizen (max 1000 Zeichen)'
                                                                color='success'
                                                                multiline
                                                                minRows={5}
                                                                maxRows={12}
                                                                value={textInfo}
                                                                onChange={handleTextChange}
                                                                fullWidth
                                                            />
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginTop: '20px',
                                            }}>
                                                <Button
                                                    color='success'
                                                    onClick={handleBack}
                                                    disabled={activeStep === 0}
                                                >
                                                    Back
                                                </Button>
                                                {activeStep !== 2 && activeStep !== 3 && (
                                                    <Button
                                                        variant='contained'
                                                        color='success'
                                                        onClick={activeStep === steps.length - 1 ? uploadData : handleNext}>
                                                        {activeStep === steps.length - 1 ? 'Abschließen' : 'Weiter'}
                                                    </Button>)}
                                            </Box>
                                        </Box>)}
                                </Box>
                            </Box>
                            <Button variant='secondary' onClick={handleLogout}
                                    style={{ position: 'absolute', top: 0, right: 0, margin: '10px' }}>Logout</Button>
                        </div>
                    </Slide>}

            </Modal>
        </>);

}

export default StepperForm;



