import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogContent, Grid, Slide, Typography } from '@mui/material';
import LoadPerson from './LoadPerson.js';
import { useNotification } from '../../../misc/notification.js';
import ApiCaller from '../../../misc/APICaller.js';
import Gesellschaft from '../newCustomer/gesellschaft.js';
import { CmxDivider } from '../newCustomer/formComponents/utils.js';
import MandantManagement from '../newCustomer/formComponents/mandantManagement.js';
import { loadCustomer } from '../helper.js';
import Stiftung from '../newCustomer/stiftung.js';
import Einzelunternehmen from '../newCustomer/einzelunternehmen.js';

const FormLoader = (props) => {
    const apiCaller = new ApiCaller();
    const [isLoading, setIsLoading] = useState(false);
    const { showNotification } = useNotification();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        props.customerData.consultantUserID = localStorage.getItem('id');
        if (props.customerData.hasOwnProperty('consultantUserID') && props.customerData.consultantUserID !== null) {
            const result = await apiCaller.callApi('updateFormData', 'POST', { customerData: props.customerData }, {});
            const clients = await loadCustomer(localStorage.getItem('id'));
            props.setCustomerList(clients);
            setIsLoading(false);
            showNotification(result.severity, result.msg);
        } else {
            setIsLoading(false);
            showNotification('error', 'Ihnen konnte keine Kanzlei zugeordnet werden. Bitte melden Sie sich neu an.');
        }

        props.setOpenFormLoader(false);
    };

    const handleClose = () => {
        props.setCustomerData({});
        props.setOpenFormLoader(false);
    };

    useEffect(() => {
        console.log('customerData');
        console.log(props.customerData);

    }, [props.customerData]);

    return (
        <>
            <Dialog
                open={props.openFormloader}
                onClose={handleClose}
                TransitionComponent={Slide}
                TransitionProps={{ direction: 'down' }}
                fullScreen={true}
                PaperProps={{
                    style: {
                        backgroundColor: '#fff',
                    },
                }}
                style={{ marginBottom: '25px' }}
            >
                {isLoading ? (
                        <Grid container justifyContent='center' alignItems='center'>
                            <Dialog open={isLoading}
                                    maxWidth='xs' // Adjust the maxWidth as needed
                                    fullWidth
                                    sx={{ padding: '5px' }}>
                                <DialogContent sx={{ textAlign: 'center' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CircularProgress />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography vartiant='body2'>Daten werden übertragen</Typography>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    ) :
                    (
                        <form onSubmit={handleSubmit}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {Object.keys(props.customerData).length > 0 && props.customerData.type === 'person' ? (
                                    <LoadPerson customerData={props.customerData}
                                                handleInputChange={props.handleInputChange}
                                                setCustomerData={props.setCustomerData} />
                                ) : (
                                    <div style={{ padding: '20px', width: '1024px' }} id='box'>
                                        <div style={{ padding: '20px' }}>
                                            <Grid item xs={12}>
                                                <CmxDivider title='Status' />
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <MandantManagement formData={props.customerData}
                                                                   handleInputChange={props.handleInputChange} />
                                            </Grid>
                                        </div>
                                        {props.customerData.type === 'Gesellschaft' ? (
                                                <Gesellschaft formData={props.customerData}
                                                              handleInputChange={props.handleInputChange}
                                                              setFormData={props.setCustomerData} />) :
                                            props.customerData.type === 'Stiftung' ?
                                                (<Stiftung formData={props.customerData}
                                                           handleInputChange={props.handleInputChange}
                                                           setFormData={props.setCustomerData} />) : props.customerData.type === 'Einzelunternehmen' ? (
                                                    <Einzelunternehmen formData={props.customerData}
                                                                       handleInputChange={props.handleInputChange}
                                                                       setFormData={props.setCustomerData} />) : null
                                        }
                                    </div>
                                )}
                            < /div>
                            <div style={{
                                position: 'fixed',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: '#fff',
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Button variant='text' color='success' onClick={handleClose} size='small'
                                        style={{ marginRight: '16px' }}>
                                    Schließen
                                </Button>
                                {/*<Button type='submit' variant='contained' color='success' onClick={() => handleSubmit()}>*/}
                                <Button type='submit' variant='contained' color='success'>
                                    Speichern
                                </Button>
                            </div>
                        </form>
                    )}
            < /Dialog>
        </>
    )
        ;
};

export default FormLoader;