import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { InfoOutlined } from '@mui/icons-material';

const Internals = (props) => {

    const CurrencyFormat = (props) => {
        return (
            <NumericFormat
                {...props}
                customInput={TextField}
                thousandSeparator='.'
                decimalSeparator=','
                prefix='€ '
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
            />
        );
    };

    return (
        <>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox color='success' />}
                    label='Wichtiges Mandat'
                    name={'clientData.important_mandat'}
                    checked={props.formData.clientData?.important_mandat}
                    color='success'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 35 }, fontWeight: 'bold' }}
                    onChange={(event) => {
                        const value = event.target.checked ? 1 : 0;
                        props.handleInputChange({
                            target: {
                                name: 'clientData.important_mandat',
                                value: value,
                            },
                        });
                    }}
                />
                <Tooltip title='Umsatz > EUR 5.000 p.a., sympathisch, strategisch interessant und Weiterempfehlungen'>
                    <InfoOutlined />
                </Tooltip>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label='Vermögen (Schätzung)'
                    placeholder='300.000,00'
                    helperText='Die vorgeschlagene Schätzung beruht auf der Summe der angegebenen Vermögensgegenständen.'
                    name='clientData.estimated_wealth'
                    value={props.formData.clientData?.estimated_wealth ? props.formData.clientData.estimated_wealth : ''}
                    onChange={props.handleInputChange}
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                    type='number'
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label='Zu versteuerndes Einkommen Vorjahr'
                    name='clientData.tax_relevant_income'
                    value={props.formData.clientData?.tax_relevant_income ? props.formData.clientData?.tax_relevant_income : ''}
                    onChange={props.handleInputChange}
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                    type='number'
                />
            </Grid>

            {/********************************************** Wegzugsgedanken in 10 Jahren
             ***************************************/}

            <Grid item xs={12}>
                <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                    <InputLabel>Wegzugsgedanken in den nächsten 10 Jahren</InputLabel>
                    <Select
                        value={props.formData.clientData?.emigrate_thoughts ? props.formData.clientData.emigrate_thoughts : ''}
                        name='clientData.emigrate_thoughts'
                        onChange={props.handleInputChange}
                    >
                        <MenuItem value={'yes'}>Ja</MenuItem>
                        <MenuItem value={'no'}>Nein</MenuItem>
                        <MenuItem value={'maybe'}>Vielleicht</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

        </>
    );
};


export default Internals;
