import React, { useEffect, useState } from 'react';
import { Button, Checkbox, CircularProgress, FormControlLabel, Link, TextField, Typography } from '@mui/material';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiCaller from '../misc/APICaller.js';
import { setLocalStorage, shake } from '../misc/helper.js';

function OnboardingForm() {
    const location = useLocation();
    const eid = new URLSearchParams(location.search).get('eid');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [knownID, setKnownID] = useState(true);
    const apiCaller = new ApiCaller();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);
            if (password !== confirmPassword) {
                setPasswordError('Passwörter sind nicht identisch.');
                shake('form');
            } else {
                if (password.length < 6 || password.length > 30) {
                    setPasswordError('Das Passwort sollte aus mindestens 6 und höchstens 30 Zeichen bestehen.');
                    shake('form');
                } else {
                    const res = await apiCaller.callApi('onboarding', 'POST', { eid: eid, psw: password }, {});
                    setMessage(res.msg);
                    if (res.hasOwnProperty('reg')) {
                        if (res.reg === true) {
                            const result = await apiCaller.callApi('login', 'POST', {
                                email: email,
                                password: password,
                            }, {});
                            if (result.token !== null) {
                                setLocalStorage(result.auth, result.id, result.role, result.token);
                                navigate('/dashboard/home');
                            }
                        } else {
                            setKnownID(false);
                        }
                    } else {
                        setKnownID(false);
                    }
                    //setMessage(resp.msg)
                }
            }
        } catch (err) {
            setMessage(err);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (password !== event.target.value) {
            setPasswordError('Passwörter sind nicht identisch.');
        } else {
            setPasswordError('');
        }
    };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const login = (event) => {
        navigate('/dashboard/home');
    };

    useEffect(() => {
        async function checkID() {
            try {
                console.log('hallo');
                console.log(eid);
                const isValidID = await apiCaller.callApi('checkID', 'GET', {}, { eid: eid });
                const isRegistered = await apiCaller.callApi('checkRegistration', 'GET', {}, { eid: eid });
                if (isValidID.valid === true && isRegistered.valid === false) {
                    const userData = await apiCaller.callApi('userData', 'GET', {}, { eid: eid });
                    if (userData.valid === true) {
                        setEmail(userData.data[0].username);
                        setKnownID(true);
                    } else {
                        setKnownID(false);
                    }
                } else {
                    setKnownID(false);
                }
            } catch (e) {
                setKnownID(false);
            }
        }

        checkID();
    }, [eid]);

    if (knownID) {
        return (
            <div className='d-flex justify-content-center align-items-center vh-100'
                 style={{ marginTop: '-60px', minWidth: '400px' }}>
                <div className='col-sm-5'>
                    <Container className='mx-auto w-100 align-items-center justify-content-center'>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <form onSubmit={handleSubmit} className='box' id='form'>
                                <h2 className='h2 align-center'>Anmelden</h2>
                                <p className='align-center'>Bitte setzen Sie ein Passwort für Ihren Account</p>

                                <TextField
                                    type='text'
                                    label='Email'
                                    color='success'
                                    value={email}
                                    margin='normal'
                                    fullWidth
                                    disabled
                                />

                                <TextField
                                    type='password'
                                    label='Passwort'
                                    color='success'
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    required
                                    margin='normal'
                                    fullWidth
                                />
                                <TextField
                                    type='password'
                                    label='Passwort bestätigen'
                                    value={confirmPassword}
                                    color='success'
                                    onChange={handleConfirmPasswordChange}
                                    required
                                    margin='normal'
                                    fullWidth
                                    error={Boolean(passwordError)}
                                    helperText={passwordError}
                                />
                                <FormControlLabel
                                    margin='normal'
                                    control={<Checkbox checked={isChecked} color='success'
                                                       onChange={handleCheckboxChange} />}
                                    label={<Typography style={{ fontSize: '14px' }}>
                                        Ich stimme den {' '}
                                        <Link href='/agbs' target='_blank' rel='noopener'>AGBs</Link> {' '}
                                        und {' '}
                                        <Link href='/agbs' target='_blank'>Datenschutzrichtlinien</Link> {' '} zu.
                                    </Typography>}
                                />
                                <Button variant='contained' color='success' disabled={!isChecked} fullWidth
                                        type='submit'>
                                    Anmelden
                                </Button>
                                <br /><br />
                                <span style={{ color: 'red' }}>{message}</span>
                            </form>
                        )}
                    </Container>
                </div>
            </div>
        );
    } else {
        return (
            <>
                {navigate('/dashboard')}
            </>
        );
    }
}

export default OnboardingForm;