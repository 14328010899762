import { Container } from 'react-bootstrap';
import React from 'react';
import { Grid } from '@mui/material';

const Impressum = () => {
    return (
        <>
            <Grid>
                <Container fluid className='p-5'>
                    <>
                        <h2 className='h2'>I. Impressum</h2>

                        <ol>
                            <li>
                                <strong>Angaben gem. § 5 TMG & § 55 Abs. 2 RStV</strong><br />
                                Cryptolight GmbH<br />
                                Pfrontener Str. 6<br />
                                90455 Nürnberg<br />
                                Amtsgericht Nürnberg HRB 40736<br />
                                Vertreten durch:<br />
                                Dr. Stefan Gradl, Markus Wirth & Dr. Maximilian Freyenfeld<br />
                                Kontakt:<br />
                                E-Mail: webmaster[at]cryptolight.io
                            </li>

                            <li>
                                <strong>Urheberrecht</strong><br />
                                <ol>
                                    <li>
                                        Zitate fremder Inhalte<br />
                                        Soweit zur Erläuterung von Inhalten dieser Seite fremde Texte (z.B. Bücher oder
                                        Blogbeiträge) zitiert oder Screenshots fremden Werken (z.B. Apps und Programmen)
                                        verwendet werden, erfolgt dies ausschließlich im Rahmen von Zitaten i.S.d. § 51
                                        UrhG.
                                    </li>
                                    <li>
                                        Links auf Seiten Dritter<br />
                                        Diese Seite enthält Links zu externen Seiten Dritter, auf deren Inhalte kein
                                        Einfluss besteht und für die keine Gewähr übernommen wird. Verantwortlich sind
                                        die Betreiber der jeweiligen Seite. Die ausgewählten Links wurden sorgfältig
                                        überprüft. Eine laufende inhaltliche Überwachung der externen Seiten ist nicht
                                        zumutbar. Wird bekannt, dass eine der externen Seiten gegen Rechte verstößt,
                                        wird der jeweilige Link entfernt.
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <strong>Haftung</strong><br />
                                Trotz sorgfältiger Prüfung kann eine Haftung für die Richtigkeit, Vollständigkeit bzw.
                                praktische Umsetzbarkeit der auf diesen Seiten veröffentlichten Informationen nicht
                                übernommen werden. Diese Informationen dienen nicht der Beratung, sondern ausschließlich
                                der allgemeinen Information und sind daher auch ausdrücklich nicht dazu bestimmt,
                                insbesondere nicht ohne eine entsprechende Prüfung im Einzelfall von Dritten übernommen
                                oder genutzt zu werden. Es wird daher keine Haftung für Schäden, die in Zusammenhang mit
                                einer unabgestimmten Verwendung derartiger Inhalte stehen, übernommen. Auch für fremde
                                Informationen kann keine Haftung übernommen werden. Bei Bekanntwerden von
                                Rechtsverletzungen durch fremde Inhalte werden diese umgehend entfernt.
                            </li>

                            <li>
                                <strong>Hinweis auf Online-Streitbeilegung</strong><br />
                                Gem. Art. 14 Abs. 1 ORD-VO werden Verbraucher hiermit über die Möglichkeit der
                                Online-Streitbeilegung informiert (<a
                                href='https://ec.europa.eu/consumers/odr/'>https://ec.europa.eu/consumers/odr/</a>).
                            </li>

                            <li>
                                <strong>Online-Profile</strong><br />
                                Soweit vom Seitenbetreiber weitere Online-Profile unterhalten werden, gelten die
                                Regelungen der jeweiligen Online-Plattform.
                            </li>
                        </ol>

                        <h2>II. Datenschutz</h2>

                        <ol>
                            <li>
                                <strong>Datenschutz für nicht eingeloggte Nutzern der Website</strong><br />
                                Aus dem folgenden Abschnitt ergibt sich, was mit personenbezogenen Daten von Nutzern
                                dieser Website passiert, die nicht eingeloggt sind. Personenbezogene Daten meint alle
                                Daten, die den Nutzer persönlich identifizieren können.<br />
                                <strong>Cookies:</strong> Darunter versteht man kleine Dateien, die i.d.R. temporär auf
                                den Rechnern der Nutzer gespeichert werden.
                                Diese Website verwendet ausschließlich temporäre Cookies. Temporäre Cookies sind solche,
                                die mit Sitzungsende automatisch ablaufen.
                                Eine Nachverfolgung findet bewusst nicht statt. Die temporären Cookies dienen
                                ausschließlich dazu, die Zugriffe auf Inhalte statistisch zu erfassen und dies z.B. bei
                                der VG Wort angeben zu können.
                                Welche temporären Cookies erfasst werden, können Nutzer zum Beispiel über den eigenen
                                Webbrowser selbst ermitteln (<a
                                href='https://www.lykeup.de/hub/verwendet-meine-website-cookies#cookies-im-firefox-browser-anzeigen'
                                target='_blank' rel='noreferrer'>Link zu einer Anleitung</a>).
                                Nutzer können die Verwendung von Cookies in ihrem Bowser selbst regeln bzw.
                                einschränken.
                                Zum Beispiel kann der Bowser Firefox unter Einstellungen - Datenschutz & Sicherheit so
                                eingestellt werden, dass Cookies grundsätzlich blockiert und/oder jedenfalls mit Ende
                                der Sitzung gelöscht werden.
                                Vor diesem Hintergrund nutzt diese Website auch kein Cookie-Popup, da ohnehin keine
                                Nachverfolgung der Nutzer angestrebt wird.
                                Nach der aktuellen Rechtslage gilt neben der DSGVO – die hierzu keine ausdrückliche
                                Regelung enthält – in Deutschland nur § 15 Abs. 3 TMG, wonach Nutzer über die Verwendung
                                von Cookies zu unterrichten sind.
                                Diese Unterrichtung wird durch diese Datenschutzerklärung gewährleistet. Darüber hinaus
                                legt diese Internetseite bewusst im Sinne der Datenvermeidung keine Nutzungsprofile
                                i.S.d. § 15 Abs. 3 TMG an.
                                <br />
                                <strong>Inhaltlich Verantwortlicher (Art. 13 Abs. 1 a) DSGVO)</strong>: Die
                                Datenverarbeitung erfolgt durch den Seitenbetreiber (s. Impressum). Daten werden
                                erfasst, indem Besucher diese ausdrücklich angeben (z.B. Kontaktformular) oder diese
                                automatisch (durch das IT-System, s. oben Cookies) gespeichert werden. Betroffen sind
                                insbesondere Daten über den konkreten Seitenaufruf (Metadaten und Nutzungsdaten wie
                                Browser, Betriebssystem, Datum, IP etc.).
                                <br />
                                <strong>Zweck (Art. 13 Abs. 1 c) DSGVO)</strong>: Die Daten werden für den Betrieb
                                dieser Seite sowie zur Analyse des Nutzerverhaltens verwendet. Dies erfolgt vertraulich
                                und entsprechend der gesetzlichen Vorgaben. Nutzer können jederzeit unentgeltlich
                                Auskunft zur Verarbeitung ihrer Daten erhalten sowie ggf. eine Berichtigung, Sperrung
                                oder Löschung verlangen. Fragen hierzu sind an den Betreiber zu stellen. Es besteht
                                zudem ein Beschwerderecht bei der jeweiligen Datenschutz-Aufsichtsbehörde.
                                <br />
                                <strong>Rechtsgrundlage (Art. 13 Abs. 1 c) DSGVO)</strong>: Die Rechtsgrundlage für die
                                Datenverarbeitung ist Art. 6 Abs. 1 a), b) und c) DSGVO.
                                <br />
                                <strong>Nutzerkonten:</strong> Soweit Nutzer eigene Nutzerkonten anlegen können,
                                erteilen Sie damit die Einwilligung zur entsprechenden Verwendung der angegebenen Daten
                                nach Art. 6 Abs. 1 a) bzw. b) DSGVO (s. unten).
                                <br />
                                <strong>Kommentare:</strong> Soweit Besucher Kommentare verfassen können, werden die in
                                diesem Zusammenhang übermittelten Daten ebenfalls gespeichert. Dies erfolgt nur soweit
                                es zur Wahrung unserer eigenen Interessen i.S.d. Art. 6 Abs. 1 f) DSGVO erforderlich
                                ist.
                                <br />
                                <strong>Newsletter:</strong> Soweit Newsletter angeboten werden, wird die hierbei
                                angegebene E-Mail-Adresse auf Basis von Art. 6 Abs. 1 b) DSGVO gespeichert. Newsletter
                                können jederzeit abbestellt werden. Die Einwilligung in den Empfang wird dadurch
                                widerrufen.
                                <br />
                                <strong>Analysetools:</strong> Diese Website verwendet Analysetools (wie z.B. Jetpack)
                                um das Nutzungsverhalten der Besucher auswerten zu können. Mehr Informationen zu den
                                Cookies von Jetpack finden sich hier: [Jetpack
                                Cookies](https://jetpack.com/support/cookies/)
                                <br />
                                <strong>Zuständige Datenschutzaufsichtsbehörde:</strong> Bayerischer Landesbeauftragter
                                für Datenschutz, Wagmüllerstraße 18 in 80538 München, Mail:
                                poststelle@datenschutz-bayern.de
                            </li>

                            <li>
                                <strong>Datenschutz für eingeloggte Besucher der Website</strong><br />
                                Unsere Website dient als Plattform zur Zusammenarbeit zwischen Beratern (Rechtsanwälten,
                                Steuerberatern etc.) und deren Mandanten. Um diese Zusammenarbeit effizient zu
                                gestalten, erfolgt der Austausch von erforderlichen Daten über unsere Plattform.<br />
                                Alle Daten, die über unsere Plattform ausgetauscht werden, unterliegen den gesetzlichen
                                Verschwiegenheitspflichten wie etwa der Verschwiegenheit nach der Berufsordnung der
                                Rechtsanwälte, auch Anwaltsgeheimnis genannt (§ 2 BORA; § 43a Abs. 2 BRAO).
                                <br />
                                Wir als Plattform haben weder ein Interesse noch eine faktische Zugriffsmöglichkeit auf
                                diese Daten. Wir stellen vielmehr im Rahmen der technischen Möglichkeiten sicher, dass
                                alle Daten auf deutschen Servern gespeichert werden und insbesondere alle
                                personenbezogenen Daten wie Name, Anschrift, Geburtsdatum oder Steuernummern nach dem
                                neuesten Stand der Technik verschlüsselt werden. Diese Daten können nur die jeweiligen
                                Mandanten und die Berater, denen die Mandanten diese Daten aktiv zur Verfügung stellen,
                                entschlüsseln und lesen.
                                <br />
                                Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 a), b) und c) DSGVO. Mit
                                der Registrierung auf der Plattform stimmen Sie der Verarbeitung der übermittelten Daten
                                gemäß dieser Datenschutzerklärung zu.
                            </li>

                            <li>
                                <strong>Externe Dienste / Datenempfänger</strong><br />
                                Wir setzen externe Dienstleister ein, um unseren Internetauftritt für Sie zu optimieren
                                und um Ihnen die Verwendung bestimmter Funktionen anzubieten. Diese Dienstleister
                                handeln dabei in unserem Auftrag und sind vertraglich an unsere Weisungen gebunden.
                                <br />
                                <strong>Cloud-Dienste:</strong> Wir setzen externe Cloud-Dienste (z.B. Amazon Web
                                Services) zur Speicherung und Verarbeitung von Daten ein. Die Verarbeitung der Daten
                                erfolgt ausschließlich auf Servern in Deutschland.
                                <br />
                                <strong>Newsletter-Versand:</strong> Für den Versand unseres Newsletters nutzen wir
                                einen externen Dienstleister. Die Datenverarbeitung erfolgt dabei auf Servern in
                                Deutschland.
                                <br />
                                <strong>Analysetools:</strong> Wir setzen Analysetools (wie z.B. Jetpack) ein, um das
                                Nutzungsverhalten unserer Besucher zu analysieren. Diese Tools verwenden Cookies, um
                                Informationen über die Nutzung der Website zu sammeln. Die Informationen werden in der
                                Regel an einen Server des jeweiligen Dienstleisters übertragen und dort gespeichert.
                                Nähere Informationen zu den Analysetools finden Sie in den Datenschutzerklärungen der
                                entsprechenden Dienstleister.
                                <br />
                                <strong>Plugins und Tools:</strong> Diese Website verwendet Plugins und Tools (z.B.
                                Google Maps), die von externen Anbietern bereitgestellt werden. Bei der Nutzung dieser
                                Plugins und Tools werden Daten an die Server des jeweiligen Anbieters übertragen und
                                dort gespeichert. Nähere Informationen finden Sie in den Datenschutzerklärungen der
                                jeweiligen Anbieter.
                            </li>
                        </ol>
                    </>
                </Container>
            </Grid>

        </>
    );
};

export default Impressum;