import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';

const Personendaten = (props) => {
    const clientData = props.formData.clientData || {};
    const handleInputChange = (event) => {
        const { name, value } = event.target;

        const updatedClientData = {
            ...clientData,
            [props.prefix ? `${props.prefix}${name}` : name]: value,
        };

        props.handleInputChange({ target: { name: 'clientData', value: updatedClientData } });
    };

    const genderOptions = [
        { value: 'male', label: 'Herr' },
        { value: 'female', label: 'Frau' },
        { value: 'diverse', label: '-' },
        { value: '', label: 'Keine' },
        { value: null, label: '' },
    ];

    const titleOptions = [
        { value: 'Dr.', label: 'Dr.' },
        { value: 'Prof.', label: 'Prof.' },
        { value: 'Prof. Dr.', label: 'Prof. Dr.' },
        { value: '', label: 'Keinen' },
        { value: null, label: '' },
    ];

    const maritalStatusOptions = [
        { value: 'single', label: 'Ledig' },
        { value: 'married', label: 'Verheiratet - Zugewinngemeinschaft' },
        { value: 'married-prenup', label: 'Verheiratet - Gütertrennung' },
        { value: 'other', label: 'Sonstiges' },
        { value: '', label: 'Keinen' },
        { value: null, label: '' },
    ];

    const handleKeyPress = (event, fieldName, options) => {
        const key = event.key.toLowerCase();
        const matchingOption = options.find(option => option.label.toLowerCase().startsWith(key));

        if (matchingOption) {
            clientData((prevData) => ({ ...prevData, [fieldName]: matchingOption.value }));
        }
    };

    return (
        <>
            <Grid item xs={12} sm={6}>
                <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                    <InputLabel id='salutation-id'>Anrede</InputLabel>
                    <Select
                        defaultValue=''
                        value={clientData.gender}
                        label='Anrede'
                        name='gender'
                        onChange={handleInputChange}
                        onKeyDown={(event) => handleKeyPress(event, 'gender', genderOptions)}
                    >
                        {genderOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                    <InputLabel>Titel</InputLabel>
                    <Select
                        defaultValue=''
                        value={clientData.title || ''}
                        label='Titel'
                        name='title'
                        onChange={handleInputChange}
                        onKeyDown={(event) => handleKeyPress(event, 'title', titleOptions)}
                    >
                        {titleOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    label='Vorname'
                    name='first_name'
                    helperText={!clientData.first_name && 'Diese Information muss gegeben sein.'} // Display the helper text when the field is empty
                    value={clientData.first_name || ''}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label='Nachname'
                    name='name'
                    helperText={!clientData.name && 'Diese Information muss gegeben sein.'}
                    value={clientData.name || ''}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label='Geburtsdatum'
                    name='birth_date'
                    value={clientData.birth_date || ''}
                    onChange={handleInputChange}
                    type='date'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                    <InputLabel>Familienstand</InputLabel>
                    <Select
                        defaultValue=''
                        value={clientData.marital_status}
                        label='familienstand'
                        name='marital_status'
                        onChange={handleInputChange}
                        onKeyDown={(event) => handleKeyPress(event, 'marital_status', maritalStatusOptions)}

                    >
                        {maritalStatusOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label='Beruf'
                    name='profession'
                    value={clientData.profession}
                    onChange={handleInputChange}
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>

        </>
    );
};

export default Personendaten;