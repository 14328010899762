import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { setLocalStorage, shake } from '../misc/helper.js';
import ApiCaller from '../misc/APICaller.js';

const Login = ({ setIsLoggedIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();
    const apiCaller = new ApiCaller();

    const Auth = async (e) => {
        e.preventDefault();
        try {
            const result = await apiCaller.callApi('login', 'POST', { email: email, password: password }, {});
            if (result.token !== null) {
                setLocalStorage(result.auth, result.id, result.role, result.token);
                setIsLoggedIn(result.auth);
                navigate('/dashboard/home');
            } else {
                shake('form');
                setMsg('Benutzername oder Passwort nicht bekannt.');
            }

        } catch (err) {
            shake('form');
            console.log('catch');
            console.log(err);
            setMsg('Es konnte keine Verbidnung zum Server hergestellt werden. Bitte prüfen Sie ihre Internetverbindung');
        }

    };

    return (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
                <form onSubmit={Auth} className='box' id='form' style={{ maxWidth: '400px' }}>
                    <Grid item>
                        <h2 className='h2 align-center'>Login</h2>
                        <p style={{ textAlign: 'center' }}>Bitte melden sie sich an</p>
                    </Grid>
                    <TextField
                        id='email'
                        label='Email'
                        type='email'
                        variant='outlined'
                        margin='normal'
                        color='success'
                        fullWidth
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        id='password'
                        label='Password'
                        type='password'
                        variant='outlined'
                        margin='normal'
                        color='success'
                        fullWidth
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button variant='contained' color='success' fullWidth type='submit'>
                        Login
                    </Button>
                    {msg && (
                        <Typography variant='body2' color='error' sx={{ mt: 2 }}>
                            {msg}
                        </Typography>
                    )}
                    <Typography variant='body2' align='center' sx={{ mt: 2 }}>
                        Sie haben noch keinen Account?{' '}
                        <RouterLink to='/register'>
                            <Button color='success'>Registrieren</Button>
                        </RouterLink>
                    </Typography>
                </form>
            </Box>
        </div>
    );
};

export default Login;