import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';

const RootContainer = styled('div')({
    flexGrow: 1,
    margin: '16px',
});

const CardContainer = styled(Card)({
    margin: '16px',
});

const ListItemContainer = styled(ListItem)({
    paddingLeft: '16px',
});

function TaskBoard() {
    const [view, setView] = useState('list');
    const [isList, setIsList] = useState('list');
    const [tasks, setTasks] = useState([
        { id: 1, name: 'Task 1', checked: false },
        { id: 2, name: 'Task 2', checked: false },
        { id: 3, name: 'Task 3', checked: false },
    ]);

    const handleToggleList = (event, newValue) => {
        if (newValue) {
            setIsList(newValue);
        }
    };

    const handleToggleCheck = (taskId) => {
        setTasks(
            tasks.map((task) =>
                task.id === taskId ? { ...task, checked: !task.checked } : task,
            ),
        );
    };

    const uncheckedTasks = tasks.filter((task) => !task.checked);

    const tasksComponent =
        isList === 'list' ? (
            <List>
                {uncheckedTasks.map((task) => (
                    <ListItemContainer
                        key={task.id}
                        role={undefined}
                        dense
                        button
                        onClick={() => handleToggleCheck(task.id)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                edge='start'
                                checked={task.checked}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText primary={task.name} />
                    </ListItemContainer>
                ))}
            </List>
        ) : (
            <Box display='flex' flexWrap='wrap'>
                {uncheckedTasks.map((task) => (
                    <CardContainer key={task.id}>
                        <CardContent>
                            <Typography variant='h5' component='h2'>
                                {task.name}
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={task.checked}
                                        onChange={() => handleToggleCheck(task.id)}
                                        name={task.name}
                                    />
                                }
                                label='Solved'
                            />
                        </CardContent>
                    </CardContainer>
                ))}
            </Box>
        );

    return (
        <RootContainer>
            <ToggleButtonGroup
                value={isList}
                exclusive
                onChange={handleToggleList}
                style={{ marginBottom: '20px' }}
            >
                <ToggleButton value='list' aria-label='list'>
                    Task
                </ToggleButton>
                <ToggleButton value='module' aria-label='module'>
                    Task
                </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant='h4' gutterBottom>
                Aufgaben
            </Typography>
            {tasksComponent}
        </RootContainer>
    );
}

export default TaskBoard;
