import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { ArrowBackIosNew } from '@mui/icons-material';

const CustomerOverview = (props) => {

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <IconButton
                        color='success'
                        aria-label='zurück'
                        component='a'
                        ahref={'/home'}
                    >
                        <ArrowBackIosNew color='success' fontSize='medium' />
                    </IconButton>
                </Grid>

                <Grid item xs={4} md={4}>
                    <Box className='box' sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 2 }}>
                        Chat Container
                        <p>Chat</p>
                        <p>Chat</p>
                        <p>Chat</p>
                        <p>Chat</p>
                        <p>Chat</p>
                        <p>Chat</p>
                        <p>Chat</p>
                        <p>Chat</p>

                    </Box>
                </Grid>

                <Grid item xs={4}>
                    <Box className='box' sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 2 }}>
                        <p>Another one</p>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box className='box' sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 2 }}>
                        <p>A third one</p>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
export default CustomerOverview;