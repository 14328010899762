import React from 'react';

const Forms = () => {
    return (
        <>
            <h2 className='h2'>forms</h2>

        </>
    );
};

export default Forms;