import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { JustificationTypes } from '../../../../misc/auto_data/justifications.js';

export default function JustificationForm(props) {
    const [focus, setHandleFocus] = useState(props?.draftData?.[props?.label] ?? '');

    function handleFocus(string, index) {
        setHandleFocus(string);
        props.setDraftData({ ...props.draftData, [props.label]: string });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {
                        JustificationTypes.map((obj, index) => (
                            <Grid item key={index}>
                                <Button

                                    variant={focus === obj.name ? 'contained' : 'outlined'}
                                    color='success'
                                    style={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        maxWidth: 'auto',
                                        height: '40px',
                                    }}
                                    onClick={() => handleFocus(obj.name, index)}
                                >
                                    <div>
                                        {
                                            <h2 style={{ margin: 0 }}>{obj.name}</h2>
                                        }
                                    </div>
                                </Button>
                            </Grid>
                        ))
                    }
                < /Grid>
            </Grid>
        </Grid>
    )
        ;
}