import React from 'react';

export const focusTypes = [

    {
        name: 'Immobilien',
    },
    {
        name: 'Kryptoassets',

    },
    {
        name: 'Digitales',
    },
    {
        name: 'Umstrukturierung',

    },
    {
        name: 'Verfahren',
    },
];