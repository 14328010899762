import { Grid, TextField } from '@mui/material';
import React from 'react';

const TaxNumber = (props) => {
    return (
        <Grid item xs={12} sm={6}>
            <TextField
                label='Steuernummer'
                name={props.formData.type !== 'person' ? 'identification_number.TaxNumber' : 'taxNumber'}
                value={props.formData.type !== 'person' ? props.formData?.identification_number?.TaxNumber : (props.formData?.taxNumber !== null ? props.formData?.taxNumber : '')}
                onChange={props.handleInputChange}
                fullWidth
                margin='normal'
                color='success'
                variant='filled'
            />
        </Grid>
    );
};
export default TaxNumber;