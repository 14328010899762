import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { CheckCircle, PersonAdd } from '@mui/icons-material';
import PreloadButtonGroup from './PreLoadButtonGroup.js';

const ButtonMultiSelect = (props) => {
    const [textFieldValue, setTextFieldValue] = useState('');
    const [selectedObjects, setSelectedObjects] = useState([]);
    const handleSetSelectedObjects = (obj) => {
        setSelectedObjects((prevSelected) => {
            const duplicateObject = prevSelected.find((existingObj) => {
                if (existingObj.id && obj.id) {
                    return existingObj.id === obj.id;
                } else if (!existingObj.id && !obj.id) {
                    return existingObj.name === obj.name;
                }
                return false;
            });

            if (!duplicateObject) {
                const updatedSelected = [...prevSelected, obj];
                console.log('updatedSelected');
                console.log(updatedSelected);
                updateFromData(updatedSelected, obj);
                return updatedSelected;
            } else {
                return prevSelected;
            }
        });
    };
    const updateFromData = (updatedSelected) => {
        const selectedObjectStrings = [];
        for (const sel of updatedSelected) {
            if (sel.select) {
                if (sel.type === 'formField') {
                    if (props?.draftData?.[sel.name]) {
                        console.log(props.draftData[sel.name]);
                        selectedObjectStrings.push(props.draftData[sel.name]);
                    }
                } else {
                    selectedObjectStrings.push(`${sel.title ? sel.title + ' ' : ''}${sel.first_name ? sel.first_name + ' ' : ''}${sel.name}`);
                }
            }
        }
        console.log('updatedFormData');
        console.log(selectedObjectStrings);
        props.setFieldValue(props.question, selectedObjectStrings);
    };

    const handleButtonPress = (obj) => {
        const updatedObjects = selectedObjects.map((item) => {
            if ((item.id && item.id === obj.id) || (!item.id && item.name && item.name === obj.name)) {
                return { ...item, select: !item.select };
            } else {
                return item;
            }
        });
        setSelectedObjects(updatedObjects);
        updateFromData(updatedObjects);
    };


    useEffect(() => {
        console.log('selectedObjects', selectedObjects);
    }, [selectedObjects]);

    const handleAddString = () => {
        if (textFieldValue.trim() !== '') {
            const field = { name: textFieldValue.trim(), select: true };
            console.log('Neuer Kunde');
            console.log(field);
            handleSetSelectedObjects(field);
        }
        setTextFieldValue('');
    };
    return (
        <Grid container spacing={2} style={{ justifyContent: 'flex-end' }}>
            <Grid item xs={6}
                  style={{
                      marginRight: '10px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                  }}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <PreloadButtonGroup draftData={props.draftData}
                                        question={props.question}
                                        preLoad={props.question.preLoad}
                                        handleSetSelectedObjects={handleSetSelectedObjects}
                                        handleButtonPress={handleButtonPress}
                                        selectedObjects={selectedObjects}
                                        setSelectedObjects={setSelectedObjects}
                                        mandateID={props.mandateID} />
                    {selectedObjects.map((obj, index) => (
                        !obj.id &&
                        <Button
                            endIcon={obj.select ? <CheckCircle /> : null}
                            startIcon={<PersonAdd />}
                            key={index}
                            variant={obj.select ? 'contained' : 'outlined'}
                            color='success'
                            style={{ margin: '0.5em' }}
                            onClick={() => handleButtonPress(obj)}
                        >
                            {obj.name}
                        </Button>
                    ))}
                </div>
            </Grid>

            <Grid item xs={12}
                  style={{
                      marginRight: '10px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                  }}>
                <Grid container spacing={2}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item xs={4}>
                        <TextField
                            label={props.question.addLabel || 'Weiterer Gesprächsteilnehmer'}
                            value={textFieldValue}
                            variant='filled'
                            color='success'
                            size='small'
                            fullWidth
                            onChange={(e) => setTextFieldValue(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='contained' color='success' onClick={handleAddString}>
                            Hinzufügen
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ButtonMultiSelect;
