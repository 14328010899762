import React from 'react';

const Documents = () => {

    return (
        <>
            <h2 className='h2'> documents</h2>
        </>
    );
};

export default Documents;