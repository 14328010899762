import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import NavigationBar from '../navigation.js';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ApiCaller from '../misc/APICaller.js';

function Register() {
    const [vorname, setVorname] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [strasse, setStrasse] = useState('');
    const [hausnummer, setHausnummer] = useState('');
    const [plz, setPlz] = useState('');
    const [stadt, setStadt] = useState('');
    const [kanzleiname, setKanzleiname] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const apiCaller = new ApiCaller();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setPasswordError('Passwörter stimmen nicht überein.');
        } else {
            const result = apiCaller.callApi('register', 'POST', {
                email: email,
                password: password,
                surname: name,
                name: vorname,
            });
            if (result !== null) {
                alert('Success');
                navigate('/dashboard');
            } else {
                alert('Failed to register');
            }
        }
    };

    useEffect(() => {

        return () => {
            // cleanup code here
        };
    }, [confirmPassword]);

    return (
        <>
            <div>
                <NavigationBar />
            </div>
            <div style={{ position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
                    <form onSubmit={handleSubmit} className='box' id='form'
                          style={{ maxWidth: '800px', width: '100%' }}>
                        <Grid item xs={12} sm={12} className='align-center'>
                            <h2 className='h2'>Registrieren</h2>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label='Vorname'
                                    variant='outlined'
                                    color='success'
                                    fullWidth
                                    value={vorname}
                                    onChange={(event) => setVorname(event.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label='Name'
                                    variant='outlined'
                                    color='success'
                                    fullWidth
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                    required
                                />
                            </Grid>
                            {/*<Grid item xs={12}>
                                <TextField
                                    label='Kanzleiname'
                                    variant='outlined'
                                    color='success'
                                    fullWidth
                                    value={kanzleiname}
                                    onChange={(event) => setKanzleiname(event.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    label='Straße'
                                    variant='outlined'
                                    color='success'
                                    fullWidth
                                    value={strasse}
                                    onChange={(event) => setStrasse(event.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label='Nr.'
                                    variant='outlined'
                                    color='success'
                                    fullWidth
                                    value={hausnummer}
                                    onChange={(event) => setHausnummer(event.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label='Postleitzahl'
                                    variant='outlined'
                                    color='success'
                                    fullWidth
                                    value={plz}
                                    onChange={(event) => setPlz(event.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label='Stadt'
                                    variant='outlined'
                                    color='success'
                                    fullWidth
                                    value={stadt}
                                    onChange={(event) => setStadt(event.target.value)}
                                    required
                                />
                            </Grid>*/}
                            <Grid item xs={12}>
                                <TextField
                                    label='Email'
                                    variant='outlined'
                                    color='success'
                                    fullWidth
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id='outlined-basic'
                                    variant='outlined'
                                    margin='normal'
                                    autoFocus={true}
                                    color='success'
                                    type='password'
                                    label='Passwort'
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type='password'
                                    color='success'
                                    label='Passwort bestätigen'
                                    value={confirmPassword}
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                    required
                                    margin='normal'
                                    fullWidth
                                    error={Boolean(passwordError)}
                                    helperText={passwordError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant='contained' color='success' fullWidth type='submit'>
                                    Anmelden
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Typography variant='body2' align='center' sx={{ mt: 2 }}>
                                Sie sind bereits registriert?{' '}
                                <RouterLink to='/dashboard'>
                                    <Button color='success'>Login</Button>
                                </RouterLink>
                            </Typography>
                        </Grid>
                    </form>
                </Box>
            </div>
        </>
    );
}

export default Register;