import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles/index.js';
import MuiDrawer from '@mui/material/Drawer/index.js';
import Box from '@mui/material/Box/index.js';
import MuiAppBar from '@mui/material/AppBar/index.js';
import Toolbar from '@mui/material/Toolbar/index.js';
import List from '@mui/material/List/index.js';
import Divider from '@mui/material/Divider/index.js';
import IconButton from '@mui/material/IconButton/index.js';
import { ArrowLeft, Menu } from '@mui/icons-material';
import logo from './img/CM_symbol.png';
import { Badge, Button, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { SidebarData } from './frontend/sidebarData.js';
import { clearLocalStorageExcept } from './misc/helper.js';
import permanentUserSettings from './misc/auto_data/permanentUserSettings.js';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#E8E8E8',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);


const NavigationBar = React.memo(() => {
    const [open, setOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState('');
    const navigator = useNavigate();
    const [userRole, setUserRole] = useState('');
    const [menuItems, setMenuItems] = useState([]);
    const [amountMessages, setAmountMessage] = useState(1);
    const toggleDrawer = () => {
        setOpen(!open);
    };


    const handleLogout = () => {
        clearLocalStorageExcept(permanentUserSettings);
        window.location.reload(false);
        setOpen(false);
        navigator('/dashboard');
    };

    const handleLogin = () => {
        navigator('/dashboard');
    };

    const authButton = () => {
        if (isLoggedIn === true) {
            return <Button variant='outlined' color='success' onClick={handleLogout}>Abmelden</Button>;

        } else {
            return <Button variant='outlined' color='success' onClick={handleLogin}>Login</Button>;
        }
    };

    useEffect(() => {
        let loggedIn = localStorage.getItem('auth');
        if (loggedIn === 'true') {
            setIsLoggedIn(true);
            setOpen(true);
        } else {
            setIsLoggedIn(false);
        }
    }, [localStorage.getItem('auth')]);

    useEffect(() => {
        let role = localStorage.getItem('role');
        let data = SidebarData.filter((item) => {
            if (item.auth && Array.isArray(item.auth)) {
                return item.auth.includes(role) || item.auth.includes('all');
            } else {
                return item.auth === userRole;
            }
        });
        setMenuItems(data);
    }, [localStorage.getItem('role')]);

    return (
        <>
            <AppBar position='absolute' open={open}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isLoggedIn && (
                            <IconButton
                                edge='start'
                                color='inherit'
                                aria-label='open drawer'
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <Menu className={'sec-color'} />
                            </IconButton>)}

                        <img
                            src={logo}
                            width='50'
                            height='50'
                            className='d-inline-block'
                            alt='CMX'
                        />
                    </Box>
                    <Box>{authButton()}</Box>
                </Toolbar>
            </AppBar>
            {isLoggedIn && (
                <Drawer variant='permanent' open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}>
                        <IconButton onClick={toggleDrawer}>
                            <ArrowLeft />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component='nav'>
                        <React.Fragment>
                            {menuItems.map((item, index) => {
                                return (
                                    <ListItemButton
                                        component={NavLink}
                                        to={item.path}
                                        key={index}
                                        activeClassName='active'
                                        sx={{
                                            '&.active': {
                                                backgroundColor: '#bdbdbd',
                                                color: '#fff',
                                                '& .MuiListItemIcon-root': {
                                                    color: '#fff',
                                                },
                                            },
                                        }}
                                    >

                                        {item.badge ? (
                                                <>
                                                    <ListItemIcon style={{ marginLeft: '-16px', marginRight: '16px' }}>
                                                        <Badge color='success' badgeContent={amountMessages}
                                                               overlap='circular'>
                                                            {item.icon}
                                                        </Badge>
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.title} />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    <ListItemIcon>
                                                        {item.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.title} />
                                                </>
                                            )}
                                    </ListItemButton>
                                );
                            })}
                        </React.Fragment>
                    </List>
                </Drawer>
            )}
        </>
    );
});

NavigationBar.displayName = 'Navigation';
export default NavigationBar;