import { Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const FreeAddress = (props) => {
    const [street, setStreet] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [street_number, setStreet_number] = useState('');
    const [city, setCity] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        const address = name + '\n' + street + ' ' + street_number + '\n' + zipcode + ' ' + city;
        props.setDraftData((prevData) => ({
            ...prevData,
            [props.question.label]: address,
        }));
    }, [city, street_number, zipcode, street, name]);

    useEffect(() => {
        if (props.draftData[props.question.label]) {
            const components = props.draftData[props.question.label].split('\n');
            if (components.length >= 3) {
                setName(components[0]);
                const streetAndNumber = components[1].split(' ');
                if (streetAndNumber.length > 1) {
                    setStreet(streetAndNumber[0]);
                    setStreet_number(streetAndNumber[1]);
                } else {
                    setStreet(streetAndNumber[0]);
                }

                const zipcodeAndCity = components[2].split(' ');
                if (zipcodeAndCity.length > 1) {
                    setZipcode(zipcodeAndCity[0]);
                    setCity(zipcodeAndCity[1]);
                } else {
                    setCity(components[2]);
                }
            }
            if (props.draftData[props.question.label + '_id']) {
                delete props.draftData[props.question.label + '_id'];
            }
        }


    }, []);

    return (
        <>
            <Grid item xs={12} sm={12}>
                < TextField
                    label='Name'
                    name='name'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <TextField
                        label='Straße'
                        name='street'
                        value={street}
                        onChange={(event) => setStreet(event.target.value)}
                        fullWidth
                        margin='normal'
                        color='success'
                        variant='filled'
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        label='Nr'
                        name='street_number'
                        value={street_number}
                        onChange={(event) => setStreet_number(event.target.value)}
                        fullWidth
                        type='text'
                        margin='normal'
                        color='success'
                        variant='filled'
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <TextField
                        label='PLZ'
                        name='zipcode'
                        value={zipcode}
                        onChange={(event) => setZipcode(event.target.value)}
                        fullWidth
                        type='number'
                        inputProps={{ maxLength: 5 }}
                        margin='normal'
                        color='success'
                        variant='filled'
                    />

                </Grid>
                <Grid item xs={12} sm={7}>
                    <TextField
                        label='Ort'
                        name='city'
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                        fullWidth
                        type='text'
                        margin='normal'
                        color='success'
                        variant='filled'
                    />
                </Grid>
            </Grid>
        </>
    );
};


export default FreeAddress;
