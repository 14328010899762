import React, { useEffect, useState } from 'react';
import OnboardingProcess from './onboarding/onboard_process.js';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, CardHeader, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles/index.js';
import { Contacts, Drafts } from '@mui/icons-material';
import ApiCaller from '../../misc/APICaller.js';

const Home = () => {
    const navigate = useNavigate();
    const [onboarding, setOnboarding] = useState(true);
    const [role, setRole] = useState('');
    const [userData, setUserData] = useState('');
    const primary = process.env.PRIMARY_COLOR;
    const apiCaller = new ApiCaller();
    useEffect(() => {

        async function fetchData() {
            try {
                setRole(localStorage.getItem('role'));
                const result = await apiCaller.callApi('getUserDataByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
                setUserData(result.data[0]);
            } catch (e) {
                setRole('unknown');
                navigate('dashboard');
            }
        }

        fetchData();
    }, [navigate]);


    const DashboardCard = styled(Card)({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        color: '#3e697c',
        padding: '10px',
        backgroundColor: 'rgba(256, 256, 256, 0.9)',
    });

    const DashboardCardHeader = styled(CardHeader)({
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        '& .MuiAvatar-root': {
            marginRight: '8px',
            backgroundColor: '#3e697c',
        },
    });

    const DashboardCardContent = styled(CardContent)({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
    });

    return (
        <>
            {onboarding && role === 'client' ? (
                <OnboardingProcess />
            ) : (

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <DashboardCard>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 2 }}>
                                {userData.first_name ? (
                                    <p>
                                        Consultomatix heißt dich herzlich
                                        willkommen <b>{userData?.title} {userData.first_name} {userData.name}</b>  &#127881;
                                    </p>

                                ) : (
                                    <p>Herzlich willkommen auf Consultomatix &#127881;</p>
                                )
                                }

                            </Box>
                        </DashboardCard>
                    </Grid>
                    <Grid item={12}></Grid>
                    <Grid item xs={4}>
                        <Link href='/dashboard/customer' style={{ textDecoration: 'none' }}>
                            <DashboardCard>
                                <DashboardCardHeader
                                    title={
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <Avatar color='success'>
                                                    <Contacts />
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                Kontakte
                                            </Grid>

                                        </Grid>
                                    }
                                />
                                <DashboardCardContent>
                                    <Typography variant='body'>Lege neue Kontakte an, verwalte und editiere
                                        diese.</Typography>
                                </DashboardCardContent>
                            </DashboardCard>
                        </Link>
                    </Grid>
                    <Grid item xs={4}>
                        <Link href='/dashboard/drafts' style={{ textDecoration: 'none' }}>
                            <DashboardCard>
                                <DashboardCardHeader
                                    title={
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <Avatar color='success'>
                                                    <Drafts />
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                Entwürfe
                                            </Grid>

                                        </Grid>
                                    }
                                />
                                <DashboardCardContent>
                                    <Typography variant='body'>Entwerfen Sie schnell und einfach
                                        Dokumente/Präsentationen.</Typography>
                                </DashboardCardContent>
                            </DashboardCard>
                        </Link>
                    </Grid>
                    {/*<Grid item xs={4}>
                        <Link href='/dashboard/drafts' style={{ textDecoration: 'none' }}>
                            <DashboardCard>
                                <DashboardCardHeader
                                    title={
                                        <Grid container spacing={1} alignItems='center'>
                                            <Grid item>
                                                <Avatar color='success'>
                                                    <DocumentScannerRounded />
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                Entwürfe
                                            </Grid>
                                        </Grid>
                                    }
                                />
                                <DashboardCardContent>
                                    <Typography variant='body'>Verwalte Dokumente und generiere diese in
                                        der</Typography>
                                </DashboardCardContent>
                            </DashboardCard>
                        </Link>
                    </Grid>*/}
                </Grid>
                /*<Grid container spacing={3} sx={{ marginBottom: 5 }}>
            <Grid item xs={12} sm={12} md={12}>
                <DashboardCard>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 2 }}>
                        <TextField
                            variant='outlined'
                            size='medium'
                            color='success'
                            value={dashboardSearchTerm}
                            onChange={handleDashboardSearchChange}

                            label='Suchen Sie Termine, Aufgaben, Kontakte'
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search fontSize='medium' color='success' />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </DashboardCard>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
                <DashboardCard>
                    <DashboardStepper />
                </DashboardCard>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DashboardCard>
                    <DashboardCardHeader
                        title={
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <Avatar color='success'>T</Avatar>
                                </Grid>
                                <Grid item>
                                    Offene Aufgaben
                                </Grid>
                                <Grid item>
                                    <Badge badgeContent={openTasks.length} color='success' />
                                </Grid>
                            </Grid>
                        }
                    />
                    <DashboardCardContent>
                        {isLoading ? (
                            <CircularProgress color='success' />
                        ) : (
                            openTasks.length > 0 ? (
                                <>
                                    <List>
                                        {openTasks.map((task, index) => (
                                            <Slide key={task.id}>
                                                <ListItem disablePadding>
                                                    <ListItemButton>
                                                        <ListItemAvatar>
                                                            <Avatar>T</Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={task.title}
                                                                      style={{ color: 'black' }} />
                                                        <Checkbox
                                                            edge='end'
                                                            color='success'
                                                            onChange={handleTask(openTasks.indexOf(task))}
                                                            inputProps={{ 'aria-labelledby': task.id }}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Slide>
                                        ))}
                                    </List>
                                </>
                            ) : (
                                <DashboardCardTypography>keine weiteren Aufgaben</DashboardCardTypography>
                            )
                        )}
                    </DashboardCardContent>
                </DashboardCard>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DashboardCard>
                    <DashboardCardHeader title={
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <Avatar>LE</Avatar>
                            </Grid>
                            <Grid>
                                Letzte Ereignisse
                            </Grid>
                        </Grid>} />
                    <DashboardCardContent>
                        {isLoading ? (
                            <CircularProgress color='success' />
                        ) : (
                            <>
                                <List>
                                    {finishedTasks.map((task, index) => (

                                        <ListItemButton key={index}>
                                            <ListItemAvatar>
                                                <Avatar>LE</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText id={index}
                                                          primary={task.title}
                                                          primaryTypographyProps={{ style: { color: 'black' } }} />
                                        </ListItemButton>

                                    ))}
                                </List>
                            </>
                        )}
                    </DashboardCardContent>
                </DashboardCard>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DashboardCard>
                    <DashboardCardHeader title={
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <Avatar>EA</Avatar>
                            </Grid>
                            <Grid>
                                Erledigte Aufgaben
                            </Grid>
                        </Grid>} />
                    <DashboardCardContent>
                        {isLoading ? (
                            <CircularProgress color='success' />
                        ) : (
                            <>
                                <List>
                                    {finishedTasks.map((task, index) => (
                                        <ListItemButton key={index}>
                                            <ListItemAvatar>
                                                <Avatar>E</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText id={index}
                                                          primary={task.title}
                                                          primaryTypographyProps={{ style: { color: 'black' } }} />
                                        </ListItemButton>

                                    ))}
                                </List>
                            </>
                        )}
                    </DashboardCardContent>
                </DashboardCard>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DashboardCard>
                    <DashboardCardHeader title={
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item>
                                <Avatar>D</Avatar>
                            </Grid>
                            <Grid>
                                Dokumente
                            </Grid>
                        </Grid>} />
                    <DashboardCardContent>
                        {isLoading ? (
                            <CircularProgress color='success' />
                        ) : (
                            <>
                                <List>
                                    {finishedTasks.map((task, index) => (
                                        <ListItemButton key={index}>
                                            <ListItemAvatar>
                                                <Avatar>D</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText id={index}
                                                          primary={task.title}
                                                          primaryTypographyProps={{ style: { color: 'black' } }} />
                                        </ListItemButton>

                                    ))}
                                </List>
                            </>
                        )}
                    </DashboardCardContent>
                </DashboardCard>
            </Grid>
            </Grid>
    */
            )}
        </>)
        ;
};
export default Home;


