export const meetingTypes = [
    {
        name: 'Telefonat',
    },
    {
        name: 'Videocall',
    },

    {
        name: 'Vor Ort',
    },
];
