import { Grid, TextField } from '@mui/material';
import React, { useState } from 'react';

const Contact = (props) => {
    const contact = props.formData.contact || {};
    const [isEmailValid, setIsEmailValid] = useState(true);
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        // Validate email field
        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = value === '' || emailRegex.test(value);
            //setIsEmailValid(isValidEmail);

            if (!isValidEmail) {
                const updatedContact = {
                    ...contact,
                    email: value,
                    //inviteEmail: false,
                };
                props.handleInputChange({ target: { name: 'contact', value: updatedContact } });
                return;
            }
        }

        const updatedContact = {
            ...contact,
            [props.prefix ? `${props.prefix}${name}` : name]: type === 'checkbox' ? checked : value,
        };

        props.handleInputChange({ target: { name: 'contact', value: updatedContact } });
    };
    return (
        <>
            <Grid item xs={12} sm={6}>
                <TextField
                    label='E-Mail'
                    name='email'
                    value={props.formData?.contact?.email}
                    onChange={handleInputChange}
                    type='email'
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                    error={!isEmailValid}
                    helperText={!isEmailValid && 'Bitte eine gültige Email eintragen'}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label='Telefonnummer'
                    name='phoneNumber'
                    value={props.formData?.contact?.phoneNumber}
                    onChange={handleInputChange}
                    fullWidth
                    margin='normal'
                    color='success'
                    variant='filled'
                />
            </Grid>
            {/*isEmailValid && contact.email !== '' && contact.email && (
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name='inviteEmail'
                                checked={contact.inviteEmail}
                                onChange={handleInputChange}
                                color='success'
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 35 }, fontWeight: 'bold' }}
                            />
                        }
                        label='Mandanten zu Consultomatix einladen'
                    />
                </Grid>
            )*/}
        </>
    );
};
export default Contact;
