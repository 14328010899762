import { Autocomplete, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchTaxAuthorities, formatAddressString } from '../../helper.js';


export default function ReceiverAddressField(props) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addReceiver, setAddReceiver] = useState(props?.draftData?.add_receiver ?? '');
    const [city, setCity] = useState(props?.draftData?.city ?? '');
    const [street, setStreet] = useState(props?.draftData?.street ?? '');
    const [streetNumber, setStreetNumber] = useState(props?.draftData?.street_number ?? '');
    const [zipcode, setZipcode] = useState(props?.draftData?.zipcode ?? '');
    const [rec, setRec] = useState(props?.draftData?.rec ?? '');

    const stateUpdateFunctions = {
        rec: setRec,
        add_receiver: setAddReceiver,
        city: setCity,
        street: setStreet,
        street_number: setStreetNumber,
        zipcode: setZipcode,
    };

    const clearAddress = () => {
        setAddReceiver('');
        setRec('');
        setCity('');
        setZipcode('');
        setStreet('');
        setStreetNumber('');
        setAddReceiver('');
    };

    useEffect(() => {
        const addressString = formatAddressString(rec, addReceiver, street, streetNumber, zipcode, city);
        props.setDraftData({ ...props.draftData, [props.question.label]: addressString });
    }, [rec, addReceiver, street, streetNumber, zipcode, city]);

    useEffect(() => {
        if (props.buttonIndex >= 0) {
            clearAddress();
            const authority = props.presets?.[props.buttonIndex];
            handleOptionChange(null, authority);
            setRec(authority?.entity?.name_full);
            setStreet(authority?.location?.[0]?.street);
            setStreetNumber(authority?.location?.[0]?.street_number);
            setZipcode(authority?.location?.[0]?.zipcode);
            setCity(authority?.location?.[0]?.city);
            const addressString = formatAddressString(
                authority?.entity?.name_full,
                addReceiver,
                authority?.location?.[0]?.street,
                authority?.location?.[0]?.street_number,
                authority?.location?.[0]?.zipcode,
                authority?.location?.[0]?.city,
            );
            props.setDraftData({
                ...props.draftData,
                [props.question.label]: addressString,
                ['rec']: authority?.entity?.name_full,
                ['addReceiver']: addReceiver,
                ['street']: authority?.location?.[0]?.street,
                ['street_number']: authority?.location?.[0]?.street_number,
                ['zipcode']: authority?.location?.[0]?.zipcode,
                ['city']: authority?.location?.[0]?.city,

            });
        } else if (props.buttonIndex !== -2) {
            console.log('WE ARE CLEANING');
            console.log(props.buttonIndex);
            clearAddress();
        }
    }, [props.buttonIndex]);
    const handleOptionChange = (event, selectedOption) => {
        if (selectedOption) {
            setRec(selectedOption);

        } else {
            clearAddress();
        }
    };

    const handleTextInputChange = (event) => {
        const text = event.target.value;
        const name = event.target.name;
        if (stateUpdateFunctions[name]) {
            stateUpdateFunctions[name](text);
        }
        props.setDraftData({ ...props.draftData, [name]: text });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        freeSolo
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        options={options}
                        loading={loading}
                        getOptionLabel={(option) => option?.entity?.name_full || ''}
                        multiple
                        name='rec'
                        inputValue={rec}
                        onChange={(event, newValue) => {
                            setRec(newValue);
                        }}
                        onInputChange={async (event, newInputValue) => {
                            handleTextInputChange(event);
                            props.setButtonIndex(-2);
                            props.setDraftData({ ...props.draftData, ['buttonIndex']: -2 });
                            props.setDraftData({ ...props.draftData, [event?.target?.name]: event?.target?.value });
                            setLoading(true);
                            const options = await fetchTaxAuthorities(newInputValue);
                            setOptions(options);
                            setLoading(false);
                        }}
                        renderInput={(params) => <TextField {...params} label='Empfänger' variant='filled'
                                                            color='success' name='rec' />}
                    />


                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label='Addresszusatz/Ansprechpartner (optional)'
                        name='add_receiver'
                        variant='filled'
                        color='success'
                        value={addReceiver}
                        onChange={handleTextInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                label='Straße'
                                name='street'
                                variant='filled'
                                color='success'
                                value={street}
                                onChange={handleTextInputChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label='Nr.'
                                name='street_number'
                                variant='filled'
                                color='success'
                                value={streetNumber}
                                onChange={handleTextInputChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label='Postleitzahl'
                                name='zipcode'
                                variant='filled'
                                color='success'
                                type='number'
                                value={zipcode}
                                onChange={handleTextInputChange}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                name='city'
                                label='Stadt'
                                variant='filled'
                                color='success'
                                value={city}
                                onChange={handleTextInputChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}