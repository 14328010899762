import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const UploadDropzone = () => {

    const onFileUpload = (file) => {
        console.log(`File ${file.name} has been uploaded`);
        // Do something with the uploaded file, such as sending it to a server
    };

    const onDrop = useCallback((acceptedFiles) => {
        onFileUpload(acceptedFiles[0]);
    }, [onFileUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleClick = (event) => {
        event.preventDefault();
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '10vh',
                border: isDragActive ? '2px dashed green' : '2px dashed gray',
                borderRadius: 1,
                p: 2,
                cursor: 'pointer',
                zIndex: 1001,
            }}
            {...getRootProps({ onClick: handleClick })}
        >
            <input {...getInputProps()} />
            <Typography variant='h6' sx={{ mb: 1 }}>
                {isDragActive ? 'Fügen Sie hier eine Datei ein' : 'Ziehen Sie eine Datei auf oder klicken Sie die Fläche.'}
            </Typography>
            <Typography variant='body1' sx={{ color: 'text.secondary' }}>
                Das Hochladen von relevanten Dateien ist freiwillig und nicht zwingend erforderlich.
            </Typography>
        </Box>
    );
};

export default UploadDropzone;
