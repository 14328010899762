export const communicationTypes = [

    {
        name: 'beA',
    },
    {
        name: 'beSt',
    },
    {
        name: 'E-Mail',
    },
    {
        name: 'Fax',
    },
    {
        name: 'Post',
    },
];
