import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { companyType } from '../../../../misc/auto_data/companyTypes.js';
import { formatEntityString, loadCompanies, mapPercentageWidthToInt } from '../../helper.js';
import companyRolesTypes from '../../../../misc/auto_data/companyRolesTypes.js';


const AddCompany = (props) => {
    const [knownClient, setKnownClient] = useState(true);
    const [companyList, setCompanyList] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [shares, setShares] = useState('');
    const [value_eur, setValue_eur] = useState('');
    const [newType, setNewType] = useState('');
    const [authorityToAct, setAuthorityToAct] = useState('');
    const [role, setRole] = useState('');

    const clearNewContactForm = (includingRole = true) => {
        setNewType('');
        setValue_eur('');
        setShares('');
        setAuthorityToAct('');
        if (includingRole) {
            setRole('');
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const id = localStorage.getItem('id');
                const companies = await loadCompanies(id);
                setCompanyList(companies);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        console.log('New Type');
        console.log(newType);
    }, [newType]);

    function onChangeType(event, item) {
        props.onChange(event, item);
        setNewType(event.target.value);
    }

    function onChangeRole(event, item) {
        props.onChange(event, item);
        setRole(event.target.value);
    }

    return (
        <>
            {
                <Grid container style={{ marginTop: '30px' }}>
                    {knownClient && companyList.length > 0 ? (
                        <div style={{ width: '100%', marginTop: '20px' }}>
                            <Grid item xs={12} style={{ marginLeft: '10px', marginBottom: '-20px' }}>
                                <Typography variant='h6'>Bestandssuche</Typography>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Autocomplete
                                        defaultValue=''
                                        options={companyList}
                                        getOptionLabel={(option) => {
                                            //return `${option.title ? option.title + ' ' : ''}${option.first_name} ${option.name} ${option.birth_date ? ', ' + format(new Date(option.birth_date), 'dd.MM.yyyy') : ''}`;
                                            return formatEntityString(option);
                                        }}
                                        value={selectedOption}
                                        onChange={(event, newValue) => setSelectedOption(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='Bestandssuche'
                                                name='mandantSearch'
                                                fullWidth
                                                margin='normal'
                                                color='success'
                                                variant='filled'
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Typography>
                                <Button variant='text' color='success'
                                        onClick={() => {
                                            setKnownClient(false);
                                            setSelectedOption(null);
                                        }}>Neue Gesellschaft anlegen</Button>
                            </Typography>
                        </div>
                    ) : (
                        <div style={{ width: '100%', marginTop: '10px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{ marginLeft: '10px', marginBottom: '-20px' }}>
                                    <Typography variant='h6'>Neue Gesellschaft</Typography>
                                </Grid>
                                <React.Fragment>
                                    {props.orderedData
                                        .filter(item => item.attr_name === 'type')
                                        .map((item, index) => (
                                            <Grid item key={index}
                                                  xs={12}>
                                                <FormControl variant='filled' color='success' fullWidth
                                                             style={{ marginTop: '15px' }}>
                                                    <InputLabel>Art</InputLabel>
                                                    <Select
                                                        value={item.value}
                                                        label={item.attr_label}
                                                        name={item.attr_name}
                                                        onChange={(event) => onChangeType(event, item)}
                                                        required
                                                    >
                                                        {companyType.map((company, companyIndex) => (
                                                            <MenuItem key={companyIndex} value={company.label}>
                                                                {company.name}
                                                            </MenuItem>
                                                        ))}
                                                        <MenuItem index={-1} value=''>Keine</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        ))}

                                    <Grid item xs={12}>
                                        <Typography>
                                            <Button variant='text' color='success'
                                                    onClick={() => {
                                                        setKnownClient(true);
                                                        clearNewContactForm(false);
                                                    }}>Aus Bestand wählen
                                            </Button>
                                        </Typography>
                                    </Grid>
                                    {newType && newType !== '' &&
                                        <>
                                            {props.orderedData.filter(item => item.attr_name === 'name_full')
                                                .map((item, index) => (
                                                    <Grid item
                                                          xs={mapPercentageWidthToInt(item.dimension ? item.dimension : 50)}
                                                          key={index}>
                                                        <TextField
                                                            label={item.attr_label}
                                                            name={item.attr_name}
                                                            value={item.value}
                                                            onChange={(event) => props.onChange(event, item)}
                                                            fullWidth
                                                            margin='normal'
                                                            color='success'
                                                            variant='filled'
                                                        />
                                                    </Grid>
                                                ))}
                                            {props.orderedData
                                                .filter(item => item.attr_name === 'name')
                                                .map((item, index) => (
                                                    <Grid item
                                                          xs={mapPercentageWidthToInt(item.dimension ? item.dimension : 50)}
                                                          key={index}>
                                                        <TextField
                                                            label={item.attr_label}
                                                            name={item.attr_name}
                                                            value={item.value}
                                                            onChange={(event) => props.onChange(event, item)}
                                                            fullWidth
                                                            margin='normal'
                                                            color='success'
                                                            variant='filled'
                                                        />
                                                    </Grid>
                                                ))}

                                            {props.orderedData
                                                .filter(item => item.attr_name === 'role')
                                                .map((item, index) => (
                                                    <Grid item xs={12} key={index}>
                                                        <FormControl variant='filled' color='success' fullWidth
                                                                     style={{ marginTop: '15px' }}>
                                                            <InputLabel>Rolle</InputLabel>
                                                            <Select
                                                                label={item.attr_label}
                                                                name={item.attr_name}
                                                                value={item.value}
                                                                onChange={(event) => onChangeRole(event, item)}
                                                            >
                                                                {companyRolesTypes.map((company, companyIndex) => (
                                                                    company.user.includes(newType) ? (
                                                                        <MenuItem key={companyIndex}
                                                                                  value={company.label}>
                                                                            {company.name}
                                                                        </MenuItem>) : null
                                                                ))}
                                                                <MenuItem index={-1} value=''>Keine</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                ))}
                                        </>
                                    }
                                </React.Fragment>

                            </Grid>
                        </div>)}
                    {role && role !== '' && (
                        <>
                            <Grid container spacing={2}>
                                {role !== 'sole_proprietor' && (
                                    <>
                                        {
                                            props.orderedData
                                                .filter(item => item.attr_name === 'shares')
                                                .map((item, index) => (
                                                    <Grid item xs={12} sm={6} key={index}>
                                                        <TextField
                                                            label={item.attr_label}
                                                            name={item.attr_name}
                                                            value={item.value}
                                                            onChange={(event) => onChangeRole(event, item)}
                                                            fullWidth
                                                            type='number'
                                                            margin='normal'
                                                            color='success'
                                                            variant='filled'
                                                        />
                                                    </Grid>))
                                        }
                                    </>
                                )}

                                <Grid item xs={12} sm={role === 'sole_proprietor' ? 12 : 6}>
                                    {
                                        props.orderedData
                                            .filter(item => item.attr_name === 'value_eur')
                                            .map((item, index) => (
                                                <TextField
                                                    key={index}
                                                    label={item.attr_label}
                                                    name={item.attr_name}
                                                    value={item.value}
                                                    onChange={(event) => onChangeRole(event, item)}
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                    type='number'
                                                />
                                            ))}
                                </Grid>
                            </Grid>
                            {role !== 'shareholder' && role !== 'limited_partner' && role !== 'stockholder' && (
                                <Grid item xs={12}>
                                    {props.orderedData
                                        .filter(item => item.attr_name === 'authorityToAct')
                                        .map((item, index) => (
                                            <FormControl variant='filled' color='success' fullWidth
                                                         style={{ marginTop: '15px' }} key={index}>
                                                <InputLabel>Vertretungsbefugnis</InputLabel>
                                                <Select
                                                    label={item.attr_label}
                                                    name={item.attr_name}
                                                    value={item.value}
                                                    onChange={(event) => props.onChange(event, item)}
                                                >
                                                    <MenuItem index={0}
                                                              value='Einzelvertretungsbefugnis mit Befreiung von den Beschränkungen des § 181 BGB'>
                                                        Einzelvertretungsbefugnis mit Befreiung von den Beschränkungen
                                                        des § 181
                                                        BGB
                                                    </MenuItem>
                                                    <MenuItem index={1}
                                                              value='Einzelvertretungsbefugnis ohne Befreiung von den Beschränkungen des § 181 BGB'>
                                                        Einzelvertretungsbefugnis ohne Befreiung von den Beschränkungen
                                                        des §
                                                        181 BGB
                                                    </MenuItem>
                                                    <MenuItem index={2} value='Gemeinschaftsvertretungsbefugnis'>
                                                        Gemeinschaftsvertretungsbefugnis
                                                    </MenuItem>
                                                    <MenuItem index={3} value=''>Keine</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ))}
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            }
        </>
    );
};


export default AddCompany;