import ApiCaller from '../misc/APICaller.js';
import CL_APICaller from './CL_APICaller.js';

export function shake(elementID) {
    document.getElementById(elementID).classList.add('error');
    setTimeout(() => {
        document.getElementById(elementID).classList.remove('error');
    }, 1000);
}

export function login(email, password) {
    try {
        const result = ApiCaller.callApi('login', { email: email, password: password });
        return result;

    } catch (error) {
        if (error.response) {

            return { valid: false, IsLoggedIn: false, msg: error.response.data.msg, token: null };
        }
    }
}

export function mapPercentageWidthToInt(intValue) {
    if (intValue < 1 || intValue > 100) {
        throw new Error('Input value must be between 1 and 100');
    }
    return Math.ceil((intValue / 100) * 12);
}


export function setLocalStorage(auth, id, role, token) {
    localStorage.setItem('auth', auth);
    localStorage.setItem('id', id);
    localStorage.setItem('role', role);
    localStorage.setItem('token', token);
}


export function handleLogout() {
    localStorage.clear();
    window.location.reload(false);
}


export function setFieldType(type) {
    if (type === 'float') {
        return 'number';
    } else if (type === 'int') {
        return 'number';
    } else if (type === 'date') {
        return 'date';
    } else if (type === 'currency') {
        return 'number';
    } else {
        return 'text';
    }
}


export function defineInputProps(type) {
    const inputProps = {};
    if (type === 'currency' || type === 'float') {
        inputProps.inputMode = 'numeric';
        inputProps.pattern = '[0-9]';
    }
    if (type === 'int') {
        inputProps.inputMode = 'numeric';
        inputProps.pattern = '[0-9]';
    }
    return inputProps;
}

export function reformatValue(item, value) {
    if (item.attr_type === 'currency' || item.attr_type === 'float') {
        const test = parseFloat(value);
        console.log(test);
        return parseFloat(value);
    }
    return value;
}

export function clearLocalStorageExcept(keyItems) {
    for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (!keyItems.includes(key)) {
            localStorage.removeItem(key);
        }
    }
}


export function sortArrayOfObjects(arr, attribute, ascending = true) {
    return arr.slice().sort((a, b) => {
        const valueA = a[attribute];
        const valueB = b[attribute];

        if (ascending) {
            if (valueA < valueB) return -1;
            if (valueA > valueB) return 1;
        } else {
            if (valueA > valueB) return -1;
            if (valueA < valueB) return 1;
        }

        return [];
    });
};


export async function downloadDraft(id) {
    try {
        const clApiCaller = new CL_APICaller();
        const response = await clApiCaller.callApi('download_document', 'GET', {}, {
            draft_id: id,
        }, false, true);
        const name = await clApiCaller.callApi('download_document', 'GET', {}, {
            draft_id: id,
            name_only: 1,
        }, false, false);
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        let fileName = 'downloaded_file.docx';  // Default file name
        const test = name.data.name;
        if (name?.data?.name) {
            fileName = name?.data?.name;
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);  // You can set any file name you want
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
        showNotification('success', 'Download wurde gestartet.');
    } catch (error) {
        console.log(error);
        showNotification('error', 'Download konnte nicht gestartet werden.');
    }
}