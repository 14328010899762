import APICaller from '../../misc/APICaller.js';
import ApiCaller from '../../misc/APICaller.js';
import roleTypes from '../../misc/auto_data/roleTypes.js';

const apiCaller = new ApiCaller();

export function mapPercentageWidthToInt(intValue) {
    if (intValue < 1 || intValue > 100) {
        throw new Error('Input value must be between 1 and 100');
    }
    return Math.ceil((intValue / 100) * 12);
}

/*
export async function loadCustomer(id) {
    try {

        const apiCaller = new APICaller();
        const clients = await apiCaller.callApi('getClientsByUserID', 'GET', {}, { user_id: id });
        clients.data.persons = clients.data.persons.map(async (client) => ({
            ...client,
            role: client.type,
            mandant_lead_name: client.mandant_lead ? await loadEntity(client.mandant_lead) : '',

        }));
        clients.data.persons = clients.data.persons.map((client) => ({
            ...client,
            type: client.hasOwnProperty('type') ? 'person' : 'person',
        }));
        const allClients = [...clients.data.persons, ...clients.data.corporations];
        const sortedClients = allClients.sort((a, b) => {
            const sortingAttributeA = a.type !== 'person' ? a.name_full || a.name : a.name;
            const sortingAttributeB = b.type !== 'person' ? b.name_full || b.name : b.name;
            return sortingAttributeA.localeCompare(sortingAttributeB);
        });
        return sortedClients;
    } catch (error) {
        return [];
        console.error(error);
    }
}
*/

export async function loadCustomer(id) {
    try {
        const apiCaller = new APICaller();
        const clients = await apiCaller.callApi('getClientsByUserID', 'GET', {}, { user_id: id });
        console.log(clients);
        console.log(clients.data);
        console.log(clients.data.length);
        if (clients.data && Object.keys(clients.data).length > 0) {
            const personPromises = clients.data.persons && clients.data.persons.map(async (client) => ({
                ...client,
                role: client.type,
                mandant_lead_entity: client.mandant_lead ? await loadEntity(client.mandant_lead) : '',
                mandant_manager_entity: client.mandant_manager ? await loadEntity(client.mandant_manager) : '',
                type: client.hasOwnProperty('type') ? 'person' : 'person',
            }));

            const corporationPromises = clients.data.corporations && clients.data.corporations.map(async (company) => ({
                ...company,
                role: company.type,
                mandant_lead_entity: company.mandant_lead ? await loadEntity(company.mandant_lead) : '',
                mandant_manager_entity: company.mandant_manager ? await loadEntity(company.mandant_manager) : '',
            }));
            const clientsWithMandantLead = await Promise.all([...Array.isArray(personPromises) ? personPromises : [], ...Array.isArray(corporationPromises) ? corporationPromises : []]);
            const sortedClients = clientsWithMandantLead.sort((a, b) => {
                const sortingAttributeA = a.entity_type !== 'person' ? a.name_full || a.name : a.name;
                const sortingAttributeB = b.entity_type !== 'person' ? b.name_full || b.name : b.name;
                const attributeBForComparison = sortingAttributeB ?? '';
                return sortingAttributeA.localeCompare(attributeBForComparison);
            });
            return sortedClients;
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function loadEntity(entity_id) {
    //const apiCaller = new ApiCaller();
    if (entity_id) {
        const entity = await apiCaller.callApi('getEntityByEntityID', 'GET', {}, { entity_id: entity_id });
        if (entity.data[0]) {
            return entity.data[0];
        } else {
            return null;
        }
    }
}


export async function loadCompanies(user_id) {
    if (user_id) {
        const clients = await loadCustomer(user_id);
        const companies = clients.filter(obj => obj.type !== 'person');
        return companies;
    }
}

export function formatEntityString(entity) {
    if (entity.type === 'person')
        return `${entity.title ? entity.title + ' ' : ''}${entity.first_name ? entity.first_name : ''}${entity.name ? ' ' + entity.name : ''}`;
    else {
        return `${entity.name_full ? entity.name_full + ' ' : ''}${entity.entity_type ? entity.entity_type : ''}${entity.name ? ' (' + entity.name + ')' : ''}`;
    }
}


export function loadRole(db_role) {
    let role = 'undefined';
    const foundObject = roleTypes.find(obj => obj.label === db_role);
    if (foundObject) {
        role = foundObject.name;
    }
    return role;
}

export function getInitials(name, firstName) {
    // Split the name and first_name into words
    const words = `${name} ${firstName}`.trim().split(' ');

    // Get the first character of each word and join them
    return words.map(word => word[0]).join('').toUpperCase();
}

export function shortenTaxAuhtorityString(string) {
    return string.replace('Finanzamt', 'FA.');
}


export function getRandomColor() {
    // Generate random RGB values
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    const color = `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;
    return color;
}

export function formatAddressString(receiver, addReceiver, street, streetNumber, zipcode, city) {
    return `${receiver ? receiver + '\n' : ''}${addReceiver ? addReceiver + '\n' : ''}${street ? street : ''}${streetNumber ? ' ' + streetNumber + '\n' : ''}${zipcode ? zipcode : ''}${street ? ' ' + city : ''}`;
}

export const fetchTaxAuthorities = async (inputValue) => {
    // Set loading state to true while fetching options
    try {
        /* const response = await fetch(`your_api_endpoint?query=${inputValue}`);
         if (!response.ok) {
             throw new Error('Failed to fetch options');
         }*/
        //const data = await response.json();
        return [];// Assuming the API response contains an array of results
    } catch (error) {
        console.error('Error fetching options:', error);
    }
};

/*
export function formatIndividualEntityString(entity) {
    if (entity.type === 'person')
        return `${entity.title ? entity.title + ' ' : ''}${entity.first_name ? entity.first_name + ' ' : ''}${entity.name ? entity.name + ' ' : ''}`;
    else {
        return `${entity.name_full ? entity.name_full + ' ' : ''}${entity.entity_type ? entity.entity_type + ' ' : ''}${entity.name ? '(' + entity.name + ')' : ''}`;
    }
}
*/

