import { Component } from 'react';
import axios from 'axios';

class ApiCaller extends Component {
    constructor(props) {
        super(props);
        this.baseURL = `${process.env.REACT_APP_EXPRESS_BASE_URL}:${process.env.REACT_APP_PORT}`;
    }

    async callApi(endpoint, method = 'GET', data = {}, params = {}, onlyData = true) {
        const token = localStorage.getItem('token');
        const config = {
            method,
            url: `${this.baseURL}/${endpoint}`,
            params,
            headers: { 'x-access-token': token },
        };
        if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
            config.data = data;
        } else {
            config.params = params;
        }
        try {

            const response = await axios(config);
            if (onlyData) {
                return response.data;
            } else {
                return response;
            }
        } catch (error) {
            throw error;
        }
    }

    render() {
        return null;
    }
}

export default ApiCaller;
