import { Component } from 'react';
import axios from 'axios';

class CLApiCaller extends Component {
    constructor(props) {
        super(props);
        this.baseURL = process.env.REACT_APP_CL_BASE_URL;
    }

    async callApi(endpoint, method = 'GET', data = {}, params = {}, onlyData = true, arrayBuffer = false) {
        const token = process.env.REACT_APP_CL_API_TOKEN;
        let config = {};
        if (arrayBuffer) {
            config = {
                method,
                url: `${this.baseURL}/${endpoint}`,
                params,
                responseType: 'arraybuffer',
                headers: { 'x-access-token': token },
            };
        } else {
            config = {
                method,
                url: `${this.baseURL}/${endpoint}`,
                params,
                headers: { 'x-access-token': token },
            };
        }
        if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
            config.data = data;
        } else {
            config.params = params;
        }
        try {
            const response = await axios(config);
            if (onlyData) {
                return response.data;
            } else {
                console.log('only response returned');
                return response;
            }
        } catch (error) {
            throw error;
        }
    }

    render() {
        return null;
    }
}

export default CLApiCaller;
