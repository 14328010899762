import './App.css';
import './css/frontend.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Container, CssBaseline, Grid, Toolbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles/index.js';
import LinkRouter from './misc/LinkRouter.js';
import NavigationBar from './navigation.js';
import React from 'react';
import theme from './theme.js';
import { NotificationProvider } from './/misc/notification.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js'; // Import the UTC plugin
import timezone from 'dayjs/plugin/timezone.js'; // Import the timezone plugin
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('auto');

function App() {
    dayjs.tz.setDefault(dayjs.tz.guess());
    return (
        <>
            <NotificationProvider>
                <ThemeProvider theme={theme}>
                    {/*<div className="background"></div>*/}
                    <Box sx={{ display: 'flex' }}>
                        <CssBaseline />
                        <NavigationBar />
                        <Box
                            component='main'
                            sx={{
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[900],
                                flexGrow: 1,
                                height: '100vh',
                                overflow: 'auto',
                            }}
                        >
                            <Toolbar />
                            <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    dateLibAdapter={dayjs}
                                >
                                    <Grid container spacing={3} justify='center' alignItems='center'>
                                        <LinkRouter />
                                    </Grid>
                                </LocalizationProvider>
                            </Container>
                        </Box>
                    </Box>
                </ThemeProvider>
            </NotificationProvider>
        </>
    );
}

export default App;
