import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Close, ExpandCircleDownSharp } from '@mui/icons-material';
import { styled } from '@mui/system';
import assetTypes from '../../../../misc/auto_data/assetTypes.js';
import ApiCaller from '../../../../misc/APICaller.js';
import { defineInputProps, mapPercentageWidthToInt, setFieldType } from '../../../../misc/helper.js';

import AssetRenderer from './asset_renderer.js';
import { loadRole } from '../../helper.js';


const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));


const Assets = (props) => {

    const [assets, setAssets] = useState(props.formData.assets ? props.formData.assets : []);

    let filteredAssetTypes = assetTypes;
    if (props.formData.type === 'Einzelunternehmen') {
        filteredAssetTypes = assetTypes.filter((item) => item.label !== 'Einzelunternehmen');
    }

    const [types, setTypes] = useState(filteredAssetTypes);
    const [selectedType, setSelectedType] = useState('');
    const [fields, setFields] = useState({});
    const [assetFormFields, setAssetFormFields] = useState([]);
    const apiCaller = new ApiCaller();
    const [rendererTrigger, setRendererTrigger] = useState(0);
    const [ownerList, setOwnerList] = useState([]);
    const [addedPushed, setAddedPushed] = useState(0);
    const handleTypeChange = async (event) => {
        const selectedType = event.target.value;
        setSelectedType(selectedType);

        if (selectedType !== '') {
            const fields = await apiCaller.callApi('get_asset_templates', 'GET', {}, { template_type: selectedType });
            console.log(fields);
            const fieldNames = fields.data.map((field) => field.attr_name);

            const initialFields = fieldNames.reduce((acc, fieldName) => {
                const field = fields.data.find((field) => field.attr_name === fieldName);
                return { ...acc, [fieldName]: { value: '', item: field } };
            }, {});

            setFields(initialFields);
        }
    };

    const sortFieldsByOrder = (fields) => {
        const sortedData = fields.sort((a, b) => a[1].item.order - b[1].item.order);
        return sortedData;
    };


    const handleAddAsset = () => {
        setAddedPushed(1);
        const newAsset = {
            type: selectedType,
            fields: { ...fields },
            ownerList: ownerList,
        };
        setAssets((prevAssets) => [...prevAssets, newAsset]);
        setSelectedType('');
        setFields({});
        setAssetFormFields([]);
        console.log(addedPushed);
        setAddedPushed(0);
    };


    const requestFormFields = async () => {
        if (selectedType !== '') {
            const fields = await apiCaller.callApi('get_asset_templates', 'GET', {}, { template_type: selectedType });
            setAssetFormFields(fields);
        }
    };

    useEffect(() => {
        async function fetchData() {
            await requestFormFields();
        }

        fetchData();
    }, [selectedType]);

    useEffect(() => {
        console.log('Assets:');
        console.log(assets);
        if (!props.formData.assets) {
            props.formData.assets = [];
        }

        props.handleInputChange({
            target: {
                name: 'assets',
                value: assets,
            },
        });
    }, [assets]);

    useEffect(() => {
        setRendererTrigger((prevKey) => prevKey + 1);
    }, [props.formData]);

    const handleRemoveAsset = (index) => {
        setAssets(prevAssets => [
            ...prevAssets.slice(0, index),
            ...prevAssets.slice(index + 1),
        ]);
    };

    const handleFieldChange = (event, item) => {
        const { name, value } = event.target;

        setFields((prevFields) => ({
            ...prevFields,
            [name]: { value, item },
        }));
    };

    const handleSetDataFieldChange = (fieldName, fieldValue, item, index) => {
        setAssets((prevAssets) => {
            const updatedAssets = [...prevAssets];
            console.log('updatedAssets');
            console.log(updatedAssets[index]);
            console.log('index');
            console.log(index);
            const updatedFields = { ...updatedAssets[index].fields };
            updatedFields[fieldName] = {
                value: fieldValue,
                item: item,
            };
            updatedAssets[index].fields = updatedFields;
            return updatedAssets;
        });
    };

    function calculateTotalShares(asset) {
        const own = parseFloat(asset.fields.owner_percentage.value) || 0;
        const totalShares = asset.ownerList.reduce((sum, owner) => {
            const percent = parseFloat(owner.ownership_percent);
            return sum + (isNaN(percent) ? 0 : percent);
        }, 0) + own;
        return totalShares;
        /*const sum = array.reduce((total, obj) => total + (obj.test || 0), 0);
        return 100;*/
    }

    return (
        <>
            <Grid item xs={12} sm={12}>
                {assets.map((asset, index) => (
                    <Accordion
                        style={{ backgroundColor: 'whitesmoke' }}
                        key={index}
                        color='success'
                        variant='filled'
                    >
                        <StyledAccordionSummary
                            aria-controls={`asset-${index}-content`}
                            id={`asset-${index}-header`}
                            expandIcon={<ExpandCircleDownSharp />}
                        >
                            <Typography>
                                {asset.type} {asset.fields.name.value && `- ${asset.fields.name.value} `}
                                {asset?.type === 'Gesellschaft' && `${asset?.fields?.type?.value} - ${loadRole(asset?.fields?.role?.value)}`}
                            </Typography>

                            <IconButton
                                color='success'
                                aria-label='Remove'
                                onClick={() => handleRemoveAsset(index)}
                            >
                                <Close />
                            </IconButton>
                        </StyledAccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {Object.entries && sortFieldsByOrder(Object.entries(asset.fields)).map(([fieldName, fieldData]) => (

                                    <React.Fragment key={fieldName}>
                                        {fieldData.item.form_type === 'text_field' && (
                                            <Grid item
                                                  xs={12}
                                                  sm={mapPercentageWidthToInt(fieldData.item.dimension ? fieldData.item.dimension : 50)}>
                                                <TextField
                                                    label={fieldData.item.attr_label}
                                                    name={fieldName}
                                                    value={fieldData.value}
                                                    onChange={(event) => handleSetDataFieldChange(fieldName, event.target.value, fieldData.item, index)}
                                                    fullWidth
                                                    margin='normal'
                                                    color='success'
                                                    variant='filled'
                                                    type={setFieldType(fieldData.item.attr_type)}
                                                    inputProps={defineInputProps(fieldData.item.attr_type)}
                                                />
                                            </Grid>
                                        )}
                                        {fieldData.item.form_type === 'select' && (
                                            < Grid item
                                                   xs={12}
                                                   sm={mapPercentageWidthToInt(fieldData.item.dimension ? fieldData.item.dimension : 50)}>
                                                < FormControl variant='filled' color='success' fullWidth
                                                              style={{ marginTop: '15px' }}>
                                                    <InputLabel>{fieldData.item.attr_label}</InputLabel>
                                                    <Select
                                                        defaultValue=''
                                                        label={fieldData.item.attr_label}
                                                        name={fieldData.name}
                                                        value={fieldData.value}
                                                        onChange={(event) => handleSetDataFieldChange(fieldName, event.target.value, fieldData.item, index)}
                                                    >
                                                        {fieldData.item.attr_preset.split(',').map((menuItem, index) => (
                                                            <MenuItem key={index}
                                                                      value={menuItem.trim()}>  {menuItem.trim()}</MenuItem>
                                                        ))}
                                                        <MenuItem key={-1} value={null}></MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )}
                                    </React.Fragment>
                                ))}
                            </Grid>
                            <Grid xs={12} style={{ marginLeft: '5px' }}>
                                {asset.ownerList.length > 0 && (
                                    <>
                                        <br />
                                        <Typography variant='h6'><b>Weitere Eigentümer</b></Typography>
                                        {asset.ownerList.map((owner, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Accordion
                                                    style={{
                                                        backgroundColor: 'whitesmoke',
                                                    }}
                                                    color='success'
                                                    variant='filled'
                                                >
                                                    <StyledAccordionSummary>
                                                        <Typography variant='body1'>
                                                            {owner.title ? owner.title : ''} {owner.first_name} {owner.name}: <b>{owner.ownership_percent} %</b>{' '}
                                                        </Typography>
                                                    </StyledAccordionSummary>
                                                </Accordion>
                                            </Grid>
                                        ))}

                                    </>
                                )}
                            </Grid>
                            {asset.ownerList && asset.ownerList.length > 0 && (
                                <Grid xs={12} style={{ marginLeft: '5px' }}>
                                    <br />
                                    <Typography variant='h6'><b>Anteile
                                        Gesamt:
                                        <b style={{ color: calculateTotalShares(asset) === 100 ? 'green' : 'black' }}> {calculateTotalShares(asset)} % </b>
                                    </b></Typography>
                                </Grid>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
            <Grid style={{ marginTop: '120px' }}></Grid>
            <Grid container className='box' spacing={2} xs={12} style={{ marginLeft: '20px' }}>
                <Grid item xs={12} sm={9}>
                    <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                        <InputLabel>Vermögensgegenstand auswählen</InputLabel>
                        <Select
                            defaultValue=''
                            value={selectedType}
                            label='Vermögensgegenstand'
                            name='asset'
                            onChange={handleTypeChange}
                        >
                            {types.map(type => (
                                <MenuItem key={type.name} value={type.label}>{type.label}</MenuItem>
                            ))}
                            <MenuItem key={-1} value=''>Keinen</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <AssetRenderer assetFormFields={assetFormFields} setAssetFormFields={setAssetFormFields}
                               onChange={handleFieldChange} selectedType={selectedType} formData={props.formData}
                               rendererTrigger={rendererTrigger} setOwnerList={setOwnerList} fields={fields}
                               addedPushed={addedPushed} handleInputChange={props.handleInputChange} />

                <Grid item xs={12} sm={3}>
                    <Button variant='contained' color='success' onClick={handleAddAsset} size='large'
                            style={{ marginTop: '20px' }}>
                        Hinzufügen
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
export default Assets;
