import React from 'react';
import { Box } from '@mui/material';

const Appointments = () => {
    return (
        <>
            <Box className='box'>
                Please find appointments here
            </Box>
        </>
    );
};

export default Appointments;

