import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Avatar,
    Button,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { CmxDivider } from './newCustomer/formComponents/utils.js';
import ApiCaller from '../../misc/APICaller.js';
import { BadgeOutlined, BusinessOutlined, ExpandMoreRounded, InfoOutlined, PersonOutlined } from '@mui/icons-material';
import { formatEntityString, getInitials } from './helper.js';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { communicationTypes } from '../../misc/auto_data/communicationTypes.js';
import { meetingTypes } from '../../misc/auto_data/meetingTypes.js';

const Settings = () => {
    const [userData, setUserData] = useState({});
    const apiCaller = new ApiCaller();
    const [message, setMessage] = useState({});
    const [entity, setEntity] = useState({});
    const [location, setLocation] = useState({});
    const [contact, setContact] = useState({});
    const legal_forms = ['Einzelkanzlei', 'Personengesellschaft', 'Kapitalgesellschaft'];
    const [personalData, setPersonalData] = useState({});
    const [employeeData, setEmployeeData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const corporation = await apiCaller.callApi('getCorporationDetailsByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
                const personalData = await apiCaller.callApi('getUserDataByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
                const employeeData = await apiCaller.callApi('employeesByUserID', 'GET', {}, { user_id: localStorage.getItem('id') });
                employeeData?.data.sort((a, b) => {
                    // Convert both names to lowercase for case-insensitive sorting
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                setEmployeeData(employeeData?.data);
                personalData.data[0] && setPersonalData(personalData.data[0]);
                setEntity(corporation.data.entity);
                setLocation(corporation.data.location[1]);
                setContact(corporation.data.contact);
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, []);


    const handlePersonalInputChange = async (event) => {
        const { name, value } = event.target;
        const PD = {
            ...personalData,
            [name]: value,
        };
        setPersonalData(PD);
        await apiCaller.callApi('updateEntity', 'POST', { entity: PD }, {});
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;

        const updatedUserData = {
            ...userData,
            [name]: value,
        };

        // Validate email field
        if (name === 'email') {
            const isValidEmail = validateEmail(value);
            updatedUserData['emailError'] = isValidEmail ? '' : 'Invalid email address';
        }

        setUserData(updatedUserData);
    };
    const handleInputChangeEntity = async (event) => {
        const { name, value } = event.target;
        const updatedEntityData = {
            ...entity,
            [name]: value,
        };
        setEntity(updatedEntityData);
        await apiCaller.callApi('updateEntity', 'POST', { entity: updatedEntityData }, {});
    };

    const handleInputChangeContact = async (event) => {
        const { name, value } = event.target;

        const updatedContactData = {
            ...contact,
            [name]: {
                ...(contact[name] || {}),
                ['type']: name,
                ['text']: value,
            },
        };
        setContact(updatedContactData);
        await apiCaller.callApi('updateContact', 'POST', { contact: updatedContactData }, {});
    };


    const handleInputChangeLocation = async (event) => {
        const { name, value } = event.target;
        const loc = {
            ...location,
            [name]: value,
        };
        setLocation(loc);
        await apiCaller.callApi('updateLocation', 'POST', { location: loc }, {});
    };

    /*    useEffect(() => {
            console.log('entity');
            console.log(entity);
        }, [entity]);*/

    const validateEmail = (email) => {
        // Regular expression to validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    /*    useEffect(() => {
            console.log('userData');
            console.log(userData);
        }, [userData]);*/

    /*
        useEffect(() => {
            console.log('contact');
            console.log(contact);
        }, [contact]);
    */

    const addEmployee = async () => {
        console.log('HELLOOO');
        console.log(userData);
        try {
            userData.user_id = localStorage.getItem('id');
            console.log(userData);
            const result = await apiCaller.callApi('addEmployee', 'POST', { employee: userData });
            console.log(result);
            if (result.msg === 'success') {
                setUserData({
                    gender: null,
                    title: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                });
            }
            setMessage(result);
            setTimeout(() => {
                setMessage('');
            }, 2500);
        } catch (err) {
            console.log(err);
        }

    };

    return (
        <>
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 99999 }}>
                {message.msg && message.severity &&
                    <Alert severity={message.severity} variant='filled'
                           size='small'>{message.msg}</Alert>
                }
            </div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    aria-controls='panel1-content'
                    id='panel1-header'>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            marginRight: '10px',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Grid item xs={0.5}>
                            <Avatar sx={{ bgcolor: '#3e697c' }}>
                                <BusinessOutlined />
                            </Avatar>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'left' }}>
                            <Typography variant='h5'>Kanzleidaten</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2} xs={12} className='box'>
                        <Grid item xs={12}>
                            <CmxDivider title='Kanzleiname & Rechtsform' />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                color='success'
                                name='name_full'
                                value={entity['name_full'] || ''}
                                label='Kanzleiname'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeEntity}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant='filled' color='success' fullWidth>
                                <InputLabel>Rechtsform</InputLabel>
                                <Select
                                    label='Rechtsform'
                                    name='type'
                                    value={entity['type'] || ''}
                                    onChange={handleInputChangeEntity}
                                    //onKeyDown={(event) => handleKeyPress(event, 'gender', genderOptions)}
                                >
                                    {legal_forms.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={4}>
                        <TextField
                            color='success'
                            name='name'
                            value={entity['name'] || ''}
                            label='Kürzel'
                            fullWidth
                            variant='filled'
                            margin='normal'
                            onChange={handleInputChangeEntity}
                        />
                    </Grid>*/}
                        {/*<Grid container display='flex' style={{ marginTop: '10px' }}
                          justifyContent='flex-end'>
                        <Button variant='outlined' color='success' onClick={handleUpdateCorporationDetails}>
                            Aktualisieren
                        </Button>
                    </Grid>*/}
                    </Grid>
                    <Grid container spacing={2} xs={12} className='box'>
                        <Grid item xs={12}>
                            <CmxDivider title='Kanzleisitz' />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                color='success'
                                name='street'
                                value={location?.street ?? ''}
                                label='Straße'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeLocation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                color='success'
                                name='street_number'
                                label='Hausnummer'
                                value={location?.street_number ?? ''}
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeLocation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='zipcode'
                                label='Postleitzahl'
                                value={location?.zipcode ?? ''}
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeLocation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='city'
                                label='Stadt'
                                value={location?.city ?? ''}
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeLocation}
                            />
                        </Grid>
                        {/* <Grid container display='flex' style={{ marginTop: '10px' }}
                          justifyContent='flex-end'>  Add this container
                        <Button variant='outlined' color='success' type='submit'>
                            Aktualisieren
                        </Button>
                    </Grid>*/}
                    </Grid>

                    <Grid container spacing={2} xs={12} className='box'>
                        <Grid item xs={12}>
                            <CmxDivider title='Kontaktdaten' />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Telefon</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='Tel'
                                value={contact['Tel']?.text || ''}
                                label='Telefonnummer'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeContact}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Faxnummer</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='Fax'
                                value={contact['Fax']?.text || ''}
                                label='Faxnummer'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeContact}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6'>E-Mail</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='Mail'
                                value={contact['Mail']?.text || ''}
                                label='E-Mail'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeContact}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Web</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='URL'
                                value={contact['URL']?.text || ''}
                                label='Website'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeContact}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Standard-Gesprächsart</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant='filled' color='success' fullWidth>
                                <InputLabel>Standard-Gesprächsart</InputLabel>
                                <Select
                                    label='Standard-Gesprächsart'
                                    name='PREMEETING'
                                    value={contact['PREMEETING']?.text || ''}
                                    onChange={handleInputChangeContact}
                                    //onKeyDown={(event) => handleKeyPress(event, 'gender', genderOptions)}
                                >
                                    {meetingTypes.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Standard-Videokonferenztool</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='VIDEO'
                                value={contact['VIDEO']?.text || ''}
                                label='Standard-Videokonferenztool'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeContact}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Standard-Kommunikation</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant='filled' color='success' fullWidth>
                                <InputLabel>Standard-Kommunikation</InputLabel>
                                <Select
                                    label='Bevorzugter Kommunikationskanal'
                                    name='PRECOM'
                                    value={contact['PRECOM']?.text || ''}
                                    onChange={handleInputChangeContact}
                                    //onKeyDown={(event) => handleKeyPress(event, 'gender', genderOptions)}
                                >
                                    {communicationTypes.map((option, index) => (
                                        <MenuItem key={index} value={option.name}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Floskel</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='PHRASE'
                                value={contact['PHRASE']?.text || ''}
                                label='Floskel'
                                fullWidth
                                multiline
                                minRows={3}
                                variant='filled'
                                margin='normal'
                                onChange={handleInputChangeContact}
                            />
                        </Grid>
                        {/*<Grid container display='flex' style={{ marginTop: '10px' }}
                          justifyContent='flex-end'>
                        <Button variant='outlined' color='success' type='submit'>
                            Aktualisieren
                        </Button>
                    </Grid>*/}
                    </Grid>

                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    aria-controls='panel1-content'
                    id='panel1-header'>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            marginRight: '10px',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Grid item xs={0.5}>
                            <Avatar sx={{ bgcolor: '#3e697c' }}>
                                <PersonOutlined />
                            </Avatar>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'left' }}>
                            <Typography variant='h5'>Meine Daten</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2} xs={12} className='box'>
                        <Grid item xs={12}>
                            <CmxDivider title='Berufsbezeichnung & Qualifikation' />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item>
                                    <Typography variant='h6'>Titel</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='title'
                                value={personalData['title'] || ''}
                                label='Titel'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handlePersonalInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item>
                                    <Typography variant='h6'>Vorname</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='first_name'
                                value={personalData['first_name'] || ''}
                                label='Vorname'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handlePersonalInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item>
                                    <Typography variant='h6'>Nachname</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='name'
                                value={personalData['name'] || ''}
                                label='Nachname'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handlePersonalInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item>
                                    <Typography variant='h6'>Berufsbezeichnung</Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title='z.B. Rechtsanwalt, Fachanwalt für Steuerrecht'>
                                        <InfoOutlined sx={{ fontSize: 12 }} />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='qualification'
                                value={personalData['qualification'] || ''}
                                label='Berufsbezeichnung'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handlePersonalInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='h6'>Suffix</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                color='success'
                                name='suffix'
                                value={personalData['suffix'] || ''}
                                label='Suffix'
                                fullWidth
                                variant='filled'
                                margin='normal'
                                onChange={handlePersonalInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginRight: '10px', marginTop: '20px', textAlign: 'center' }}>
                            <Typography>Vorschau</Typography>
                        </Grid>
                        <Grid xs={9} style={{
                            border: '1px solid lightgray',
                            padding: '20px',
                            margin: '10px',
                            textAlign: 'center',

                        }}>
                            {personalData?.title ? personalData?.title + ' ' : ''}
                            {personalData?.first_name ? (personalData?.first_name + ' ') : ''}
                            {personalData?.name ? (personalData?.name) : ''}
                            {personalData?.suffix ? (', ' + personalData?.suffix + ' \n') : ''}
                            <Typography variant='body2'>
                                {personalData?.qualification ? ('\n' + personalData?.qualification + ' ') : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    aria-controls='panel1-content'
                    id='panel1-header'>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            marginRight: '10px',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Grid item xs={0.5}>
                            <Avatar sx={{ bgcolor: '#3e697c' }}>
                                <BadgeOutlined />
                            </Avatar>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'left' }}>
                            <Typography variant='h5'>Mitarbeiter</Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2} xs={12} className='box'>
                        <Grid item xs={12}>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <Grid container spacing={2}>
                                    {employeeData?.map((employee, index) => (
                                        <Grid item xs={6} key={index}>
                                            <ListItem alignItems='flex-start' key={index}
                                                      style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        style={{ backgroundColor: '#3e697c' }}>{getInitials(employee.name, employee.first_name)}</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={formatEntityString(employee) + (employee?.suffix ? `  ${employee?.suffix}` : '')}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography>
                                                                {employee?.qualification}
                                                            </Typography>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component='span'
                                                                variant='body2'
                                                            >
                                                                {(employee?.birth_date ? `\n Geburtsdatum:  ${format(new Date(employee.birth_date), 'do MMMM yyyy', { locale: de })}` : '')}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </Grid>
                                    ))}
                                </Grid>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                        <Grid container spacing={2} xs={12} className='box'>
                            <Grid xs={12}>
                                <CmxDivider title='Mitarbeiter hinzufügen' />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                                    <InputLabel id='salutation-id'>Anrede</InputLabel>
                                    <Select
                                        value={userData.gender}
                                        label='Anrede'
                                        name='gender'
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value={'male'}>Herr</MenuItem>
                                        <MenuItem value={'female'}>Frau</MenuItem>
                                        <MenuItem value={'diverse'}>-</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant='filled' color='success' fullWidth style={{ marginTop: '15px' }}>
                                    <InputLabel>Titel</InputLabel>
                                    <Select
                                        defaultValue=''
                                        value={userData.title || ''}
                                        label='Titel'
                                        name='title'
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem value={'Dr.'}>Dr.</MenuItem>
                                        <MenuItem value={'Prof.'}>Prof.</MenuItem>
                                        <MenuItem value={'Prof. Dr.'}>Prof. Dr.</MenuItem>
                                        <MenuItem value=''>Keinen</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label='Vorname'
                                    name='first_name'
                                    value={userData.first_name || ''}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label='Nachname'
                                    name='last_name'
                                    value={userData.last_name || ''}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label='E-Mail'
                                    name='email'
                                    value={userData.email || ''}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    type='email'
                                    margin='normal'
                                    color='success'
                                    variant='filled'
                                    error={!!userData.emailError}
                                    helperText={userData.emailError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container display='flex'
                                      justifyContent='flex-end'> {/* Add this container */}
                                    <Button variant='outlined' color='success' onClick={() => addEmployee()}>
                                        Mitarbeiter hinzufügen
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
        ;
};

export default Settings;